import React, { useState, useEffect, useRef } from "react";
import { IoMdClose, IoMdCheckmark } from "react-icons/io";
import "./DataTablePermissionRow.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import { getCssVariable } from "../../utils";
import { GetPermissionsQuery } from "../../graphql/graphqlTypes";

type Props = {
  user: any;
  division: string;
  allPermissions: GetPermissionsQuery["permissions"];
  allDivisions: { id: string; name: string }[];
  isSubRow?: boolean;
  divisionRoles?: any;
};

function DataTablePermissionRow(props: Props) {
  const { user, division, allPermissions, allDivisions, isSubRow, divisionRoles } = props;

  const [divisions, setDivisions] = useState<any>([]);
  const [expanded, setExpanded] = useState(false);
  const [permissions, setPermissions] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    setExpanded(false);
  }, [division]);

  const getDivisionName = () => {
    const found = allDivisions.find((d) => {
      return d.id === division;
    });
    if (found) {
      return found.name;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (!division) {
      /* OVERVIEW */
      /* FORMATTING EACH USER BY DIVISION->ROLE */
      let userDivisions = [];
      let userDivisionIds = [];
      const userRoles = user.roles;

      if (userRoles) {
        userRoles.forEach((userRole) => {
          if (!userRole.divisions) return; // Can be users from previous request (when switching from usersTable e.g.) which does not have divisions
          const divisions = [...userRole.divisions, ...userRole.warehouses];
          const role = {
            id: userRole.id,
            name: userRole.name,
            permissions: userRole.permissions,
          };
          if (divisions) {
            divisions.forEach((division) => {
              const index = userDivisionIds.indexOf(division.id);
              if (index !== -1) {
                // Already exists
                const prevRoles = userDivisions[index].roles;
                userDivisions.splice(index, 1, {
                  ...userDivisions[index],
                  roles: [...prevRoles, role],
                });
              } else {
                userDivisions.push({
                  id: division.id,
                  name: division.name,
                  roles: [role],
                });
                userDivisionIds.push(division.id);
              }
            });
          }
        });
      }
      setDivisions(userDivisions);
    }
    if (divisionRoles || user.roles) {
      const roles = divisionRoles ? divisionRoles : user.roles;
      let permissions = [];
      roles.forEach((role) => {
        permissions.push(...role.permissions);
      });
      setPermissions(permissions);
    } else {
      setPermissions(user.permissions);
    }
  }, [user]);

  return (
    <>
      <tr
        className={`${isSubRow ? "subrow" : ""} ${!division ? "expandable-row" : ""}`}
        style={expanded ? { borderBottom: "none" } : {}}
        onClick={() => {
          if (!division) {
            setExpanded(!expanded);
          }
        }}>
        {(!division || isSubRow) && (
          <td style={{ width: 20 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {!isSubRow && (
                <MdKeyboardArrowDown
                  style={{
                    transform: expanded ? "rotate(0deg)" : "rotate(-90deg)",
                  }}
                  className="expand-arrow"
                />
              )}
            </div>
          </td>
        )}
        {!isSubRow && (
          <td style={{ padding: 12 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {user.id && <div>{user.name && user.name}</div>}
            </div>
          </td>
        )}
        {isSubRow && (
          <td style={{ padding: 12 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {division && allDivisions && <div>{getDivisionName()}</div>}
            </div>
          </td>
        )}
        {permissions &&
          allPermissions &&
          allPermissions.map((permission, index) => {
            const found = permissions.find((userPermission) => {
              if (userPermission.name === permission.name) return true;
            });
            return (
              <td
                key={"permission_" + index}
                style={{
                  padding: 12,
                  border: "1px solid #ddd",
                  borderBottom: "none",
                  borderTop: "none",
                  minWidth: 16,
                }}>
                <div
                  className="flex-center"
                  style={{
                    color: getCssVariable("--color-blue0"),
                  }}>
                  {found ? <IoMdCheckmark /> : ""}
                </div>
              </td>
            );
          })}
      </tr>
      {expanded &&
        divisions.map((division, index) => {
          return (
            <DataTablePermissionRow
              {...props}
              key={"divsion_" + index}
              isSubRow={true}
              division={division.id}
              allDivisions={allDivisions}
              divisionRoles={division.roles}
            />
          );
        })}
    </>
  );
}

export default DataTablePermissionRow;
