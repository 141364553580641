import gql from "graphql-tag";

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

export const REGISTER = gql`
  mutation register(
    $name: String
    $email: String
    $disabled: Boolean
    $password: String
    $divisionId: String
    $registerToken: String
  ) {
    register(
      name: $name
      email: $email
      disabled: $disabled
      password: $password
      divisionId: $divisionId
      registerToken: $registerToken
    ) {
      id
      name
      email
      disabled
      success
      error {
        code
        msg
      }
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($accountId: String!, $name: String, $logoUrl: String, $disabled: Boolean) {
    updateAccount(accountId: $accountId, name: $name, logoUrl: $logoUrl, disabled: $disabled) {
      success
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($accountId: String!) {
    deleteAccount(accountId: $accountId) {
      success
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccount($name: String) {
    createAccount(name: $name) {
      id
      name
      logoUrl
      disabled
    }
  }
`;

export const RECOVER_PASSWORD = gql`
  mutation recoverPassword($email: String!) {
    recoverPassword(email: $email) {
      success
    }
  }
`;

export const UPDATE_DIVISION = gql`
  mutation updateDivision($divisionId: String!, $name: String, $parentDivisionId: String) {
    updateDivision(divisionId: $divisionId, name: $name, parentDivisionId: $parentDivisionId) {
      success
    }
  }
`;

export const UPDATE_TIME_WINDOW = gql`
  mutation updateTimeWindow(
    $warehouseId: String!
    $startWindow: String!
    $endWindow: String!
    $onlyWeekdays: Boolean!
    $excludedDates: [String!]
  ) {
    updateTimeWindow(
      warehouseId: $warehouseId
      startWindow: $startWindow
      endWindow: $endWindow
      onlyWeekdays: $onlyWeekdays
      excludedDates: $excludedDates
    ) {
      success
    }
  }
`;

export const UPDATE_WMS = gql`
  mutation updateWMS($warehouseId: String!, $wms: String!, $shelfFormat: String) {
    updateWMS(warehouseId: $warehouseId, wms: $wms, shelfFormat: $shelfFormat) {
      success
    }
  }
`;

export const UPDATE_DISTANCE_MATRIX = gql`
  mutation updateDistanceMatrix($warehouseId: String!, $distanceMatrix: String) {
    updateDistanceMatrix(warehouseId: $warehouseId, distanceMatrix: $distanceMatrix) {
      success
    }
  }
`;

export const CREATE_DIVISION = gql`
  mutation createDivision($name: String!, $parentDivisionId: String!, $isWarehouse: Boolean!) {
    createDivision(name: $name, parentDivisionId: $parentDivisionId, isWarehouse: $isWarehouse) {
      id
    }
  }
`;

export const CREATE_DATASET = gql`
  mutation createDataset($warehouseId: String!, $datasetName: String!) {
    createDataset(warehouseId: $warehouseId, datasetName: $datasetName) {
      id
      name
    }
  }
`;

export const DELETE_DATASET = gql`
  mutation deleteDataset($datasetId: String!) {
    deleteDataset(datasetId: $datasetId) {
      success
    }
  }
`;

export const CREATE_OPTIMIZATION = gql`
  mutation createOptimization(
    $warehouseId: String!
    $optimizationName: String!
    $datasetId: String!
    $types: [String]!
    $config: ConfigInput
  ) {
    createOptimization(
      warehouseId: $warehouseId
      optimizationName: $optimizationName
      datasetId: $datasetId
      types: $types
      config: $config
    ) {
      id
      name
    }
  }
`;

export const DELETE_OPTIMIZATION = gql`
  mutation deleteOptimization($optimizationId: String!) {
    deleteOptimization(optimizationId: $optimizationId) {
      success
    }
  }
`;

export const START_OPTIMIZATION = gql`
  mutation startOptimization($optimizationId: String!) {
    startOptimization(optimizationId: $optimizationId) {
      success
    }
  }
`;

export const STOP_OPTIMIZATION = gql`
  mutation stopOptimization($optimizationId: String!, $keepSolution: Boolean!) {
    stopOptimization(optimizationId: $optimizationId, keepSolution: $keepSolution) {
      success
    }
  }
`;

export const CALCULATE_DISTANCE_MATRIX = gql`
  mutation calculateDistanceMatrix($warehouseId: String!, $datasetId: String!) {
    calculateDistanceMatrix(warehouseId: $warehouseId, datasetId: $datasetId) {
      success
    }
  }
`;

export const DELETE_DIVISION = gql`
  mutation deleteDivision($divisionId: String!) {
    deleteDivision(divisionId: $divisionId) {
      success
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userId: String!, $name: String!, $divisionId: String!, $email: String!, $disabled: Boolean!) {
    updateUser(userId: $userId, name: $name, divisionId: $divisionId, email: $email, disabled: $disabled) {
      id
      name
      email
      disabled
      success
      error {
        code
        msg
      }
    }
  }
`;

export const SEND_REGISTRATION_MAIL = gql`
  mutation sendRegistrationMail($userId: String!) {
    sendRegistrationMail(userId: $userId) {
      success
    }
  }
`;

export const UPDATE_USER_ARCHIVED = gql`
  mutation updateUserArchived($userId: String!, $archived: Boolean!) {
    updateUserArchived(userId: $userId, archived: $archived) {
      success
    }
  }
`;

export const UPDATE_DIVISION_USER_ROLES = gql`
  mutation updateDivisionUserRoles(
    $userId: String!
    $divisionId: String!
    $addedRoleIds: [String]
    $removedRoleIds: [String]
  ) {
    updateDivisionUserRoles(
      userId: $userId
      divisionId: $divisionId
      addedRoleIds: $addedRoleIds
      removedRoleIds: $removedRoleIds
    ) {
      success
    }
  }
`;

export const START_SIMULATION = gql`
  mutation startSimulation($config: SimulationConfigInput!, $file: Upload) {
    startSimulation(config: $config, file: $file) {
      success
    }
  }
`;

export const DELETE_SIMULATION = gql`
  mutation deleteSimulation($simulationId: String!) {
    deleteSimulation(simulationId: $simulationId) {
      success
    }
  }
`;

export const STOP_SIMULATION = gql`
  mutation stopSimulation($simulationId: String!) {
    stopSimulation(simulationId: $simulationId) {
      success
    }
  }
`;
