import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./pages/App";
import * as serviceWorker from "./serviceWorker";
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from "@apollo/client";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Settings from "./pages/Settings/Settings";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { AnimatePresence } from "framer-motion";
import { createUploadLink } from "apollo-upload-client";

const hist = createBrowserHistory();

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("jwt-warehouse-optimization");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "Accept-Encoding": "gzip",
    },
  };
});

const httpLink = createUploadLink({
  uri: "/graphql",
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (extensions && extensions.code === "UNAUTHENTICATED") {
        console.log("UNAUTHENTICATED");
        hist.push("/login");
      } else if (extensions && extensions.code === "FORBIDDEN") {
        console.log("FORBIDDEN");
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpAuthLink = authLink.concat(httpLink);
const httpAuthErrorLink = errorLink.concat(httpAuthLink);

const client = new ApolloClient({
  link: httpAuthErrorLink,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router history={hist}>
        <RouteSwitch />
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

function RouteSwitch() {
  const location = useLocation();

  // Setting location key to animate transitions between pages
  // Transitions between /jobs, /optimizations, etc. should not be animated
  // Therefore key is only changed between App and Login
  let key = "1";
  if (location.pathname.includes("login")) {
    key = "1";
  } else {
    key = "2";
  }

  return (
    <AnimatePresence exitBeforeEnter={true}>
      <Switch location={location} key={key}>
        <Route path="/admin" component={App} />
        <Route path="/register" component={Register} />
        <Route path="/recover" component={Register} />
        <Route path="/login" component={Login} />
        <Route path="/layouts/:id" component={App} />
        <Route path="/resources/:id" component={App} />
        <Route path="/jobs/:id" component={App} />
        <Route path="/optimizations/:id" component={App} />
        <Route path="/simulation" component={App} />
        <Route path="/" component={App} />
        <Route path="" component={App} />
      </Switch>
    </AnimatePresence>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
