import React, { useEffect, useState } from "react";
import "./OptimizationConfig.css";
import { motion } from "framer-motion";
import {
  Checkbox,
  createMuiTheme,
  createStyles,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  makeStyles,
  MuiThemeProvider,
  TextField,
  Theme,
} from "@material-ui/core";

import { OptimizationConfig as OptimizationConfigType } from "../../graphql/graphqlTypes";

type Props = {
  onClose: Function;
  onSave?: Function;
  readOnly?: boolean;
  initialConfig?: OptimizationConfigType;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        marginTop: theme.spacing(4),
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "white",
      },
    },
    textField: {
      // marginRight: 40,
      // width: 150,
      marginTop: 0,
      marginBottom: 10,
    },
  }),
);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#102940",
    },
  },
});

const defaultValues: OptimizationConfigType = {
  // -- CUSTOMER PARAMETERS --
  pickingTime: 60,
  loadOnloadTime: 300,
  maxRouteTime: 7200,
  velocity: 1.0,
  verticalVelocity: 0.5,
  executionTime: -1,

  // -- ADMIN PARAMETERS --
  oneOrderMultipleBoxes: false,
  oneRouteOneOrder: false,
  preProcessing: false,
  postProcessing: false,
  volumeOverloadPriority: 1,
  weightOverloadPriority: 1,
  clusterOrderSize: 10000,
  clusterOrderrowSize: 10000,
};

function OptimizationConfig(props: Props) {
  const { onClose, onSave, readOnly, initialConfig } = props;
  const classes = useStyles();
  const [formValues, setFormValues] = useState(defaultValues);
  const [validForm, setValidForm] = useState(true);

  useEffect(() => {
    if (initialConfig) {
      const initFormValues = {
        ...initialConfig,
      };
      setFormValues(initFormValues);
    }
  }, [initialConfig]);

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;

    if (
      name === "oneOrderMultipleBoxes" ||
      name === "oneRouteOneOrder" ||
      name === "preProcessing" ||
      name === "postProcessing"
    ) {
      // Checkboxes - use "checked" instead of value
      setFormValues({
        ...formValues,
        [name]: checked,
      });
    } else {
      // Parse numbers
      // + handle when user types "-" (for negative value)
      if (!value) {
        setFormValues({
          ...formValues,
          [name]: value,
        });
      } else {
        setFormValues({
          ...formValues,
          [name]: parseFloat(value),
        });
      }
    }
  };

  useEffect(() => {
    let valid = true;
    for (const key in formValues) {
      if (formValues[key] === "") {
        valid = false;
      }
    }
    setValidForm(valid);
  }, [formValues]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex-center dialog-wrapper"
      onClick={() => onClose()}>
      <div
        className="flex-center dialog-container"
        style={{ height: "80vh", overflow: "auto", display: "block" }}
        onClick={(e) => e.stopPropagation()}>
        {/* <div className="text-xl uppercase font-bold tracking-wide mb-8">Optimization Configuration </div> */}
        <MuiThemeProvider theme={theme}>
          <div className="uppercase font-bold tracking-wide w-full text-center">Customer parameters </div>
          <form
            className={classes.root}
            style={{
              padding: 20,
              backgroundColor: "#fbfbfb",
              borderRadius: 10,
              border: "1px solid lightgray",
              marginTop: 30,
              width: 500,
            }}
            noValidate
            autoComplete="off">
            <TextField
              name="pickingTime"
              onChange={handleInputChange}
              disabled={readOnly}
              label="Picking time"
              type="number"
              value={formValues.pickingTime}
              className={classes.textField}
            />
            <TextField
              name="loadOnloadTime"
              onChange={handleInputChange}
              disabled={readOnly}
              label="Load/Onload time"
              type="number"
              value={formValues.loadOnloadTime}
              className={classes.textField}
            />
            <TextField
              name="maxRouteTime"
              onChange={handleInputChange}
              disabled={readOnly}
              label="Max route time"
              type="number"
              value={formValues.maxRouteTime}
              className={classes.textField}
            />
            <TextField
              name="velocity"
              onChange={handleInputChange}
              disabled={readOnly}
              label="Velocity"
              type="number"
              value={formValues.velocity}
              className={classes.textField}
            />
            <TextField
              name="verticalVelocity"
              onChange={handleInputChange}
              disabled={readOnly}
              label="Vertical velocity"
              type="number"
              value={formValues.verticalVelocity}
              className={classes.textField}
            />
            <TextField
              name="executionTime"
              onChange={handleInputChange}
              disabled={readOnly}
              label="Execution time"
              type="number"
              value={formValues.executionTime}
              className={classes.textField}
            />
          </form>
          <div className="uppercase font-bold tracking-wide w-full mt-12 text-center">Admin parameters </div>
          <form
            className={classes.root}
            style={{
              padding: 20,
              backgroundColor: "#fbfbfb",
              borderRadius: 10,
              border: "1px solid lightgray",
              marginTop: 30,
              width: 500,
            }}
            noValidate
            autoComplete="off">
            <FormControlLabel
              control={
                <Checkbox
                  name="oneOrderMultipleBoxes"
                  checked={formValues.oneOrderMultipleBoxes}
                  onChange={handleInputChange}
                  disabled={readOnly}
                  color="primary"
                />
              }
              label="One order multiple boxes"
              style={{ display: "block" }}
              className={classes.textField}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="oneRouteOneOrder"
                  checked={formValues.oneRouteOneOrder}
                  onChange={handleInputChange}
                  disabled={readOnly}
                  color="primary"
                />
              }
              label="One route one order"
              style={{ display: "block" }}
              className={classes.textField}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="preProcessing"
                  checked={formValues.preProcessing}
                  onChange={handleInputChange}
                  disabled={readOnly}
                  color="primary"
                />
              }
              label="Pre-Processing"
              style={{ display: "block" }}
              className={classes.textField}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="postProcessing"
                  checked={formValues.postProcessing}
                  onChange={handleInputChange}
                  disabled={readOnly}
                  color="primary"
                />
              }
              label="Post-Processing"
              style={{ display: "block" }}
              className={classes.textField}
            />
            <TextField
              name="volumeOverloadPriority"
              disabled={readOnly}
              label="Volume overload priority"
              type="number"
              value={formValues.volumeOverloadPriority}
              className={classes.textField}
              InputProps={{
                inputProps: { min: 0, max: 5 },
              }}
              onChange={(e) => {
                var value = parseInt(e.target.value);
                if (value > 5) value = 5;
                if (value < 0) value = 0;
                e.target.value = value.toString();
                handleInputChange(e);
              }}
            />
            <TextField
              name="weightOverloadPriority"
              disabled={readOnly}
              label="Weight overload priority"
              type="number"
              value={formValues.weightOverloadPriority}
              className={classes.textField}
              InputProps={{
                inputProps: { min: 0, max: 5 },
              }}
              onChange={(e) => {
                var value = parseInt(e.target.value);
                if (value > 5) value = 5;
                if (value < 0) value = 0;
                e.target.value = value.toString();
                handleInputChange(e);
              }}
            />
            <TextField
              name="clusterOrderSize"
              onChange={handleInputChange}
              disabled={readOnly}
              label="Cluster order size"
              type="number"
              value={formValues.clusterOrderSize}
              className={classes.textField}
            />
            <TextField
              name="clusterOrderrowSize"
              onChange={handleInputChange}
              disabled={readOnly}
              label="Cluster orderrow size"
              type="number"
              value={formValues.clusterOrderrowSize}
              className={classes.textField}
            />
          </form>
        </MuiThemeProvider>
        {!readOnly && (
          <div className="mt-12 flex items-center justify-center">
            <div
              className="wide-button"
              style={{
                width: 100,
                marginRight: 0,
                opacity: validForm ? 1 : 0.5,
                cursor: validForm ? "pointer" : "default",
              }}
              onClick={async () => {
                if (validForm) {
                  onSave(formValues);
                  onClose();
                }
              }}>
              Save
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default OptimizationConfig;
