import React, { useState, useEffect, useRef } from "react";
import "./WMSSettings.css";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_TIME_WINDOW, UPDATE_WMS } from "../../graphql/mutations";
import {
  GetWarehouseTimeWindowQuery,
  GetWarehouseTimeWindowQueryVariables,
  GetWarehouseWmsQuery,
  GetWarehouseWmsQueryVariables,
  UpdateTimeWindowMutation,
  UpdateTimeWindowMutationVariables,
  UpdateWmsMutation,
  UpdateWmsMutationVariables,
} from "../../graphql/graphqlTypes";
import { GET_WAREHOUSE_WMS } from "../../graphql/queries";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

type Props = {
  warehouseId: string;
};

function WMSSettings(props: Props) {
  const { warehouseId } = props;

  const WMStypes = [
    { name: "Astro", id: "ASTRO" },
    { name: "Navision", id: "NAVISION" },
    { name: "IMI", id: "IMI" },
  ];

  const shelfFormats = [{ name: "Fruit Distribution", id: "FRUIT_DISTRIBUTION" }];

  const [editing, setEditing] = useState(false);
  const [comboWMS, setComboWMS] = useState(null);
  const [comboShelfFormat, setComboShelfFormat] = useState(null);

  const [updateWMS] = useMutation<UpdateWmsMutation, UpdateWmsMutationVariables>(UPDATE_WMS);
  const { data: warehouseWMSdata } = useQuery<GetWarehouseWmsQuery, GetWarehouseWmsQueryVariables>(GET_WAREHOUSE_WMS, {
    variables: { warehouseId: warehouseId },
  });

  useEffect(() => {
    if (warehouseWMSdata) {
      const wms = WMStypes.find((wms) => {
        if (wms.id === warehouseWMSdata.warehouse.wms) {
          return true;
        }
      });
      setComboWMS(wms);

      const shelfFormat = shelfFormats.find((shelfFormat) => {
        if (shelfFormat.id === warehouseWMSdata.warehouse.shelfFormat) {
          return true;
        }
      });
      setComboShelfFormat(shelfFormat);
    }
  }, [warehouseWMSdata]);

  return (
    <>
      {
        <div style={{ padding: "0px 14px 14px 14px" }}>
          <div className="flex">
            <Autocomplete
              id="wms-combo"
              options={WMStypes}
              getOptionLabel={(option: any) => option.name}
              getOptionSelected={(option, value) => {
                if (option && value) return option.id === value.id;
              }}
              value={comboWMS}
              onChange={(event: any, newValue: any) => {
                if (newValue) {
                  setEditing(true);
                  setComboWMS(newValue);
                }
              }}
              style={{ padding: 5, width: 200, marginRight: 20 }}
              renderInput={(params) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField label="WMS" {...params} />
                </div>
              )}
            />
            <Autocomplete
              id="shelf-format-combo"
              options={shelfFormats}
              getOptionLabel={(option: any) => option.name}
              getOptionSelected={(option, value) => {
                if (option && value) return option.id === value.id;
              }}
              value={comboShelfFormat}
              onChange={(event: any, newValue: any) => {
                setEditing(true);
                setComboShelfFormat(newValue);
              }}
              style={{ padding: 5, width: 200 }}
              renderInput={(params) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField label="Shelf format" {...params} />
                </div>
              )}
            />
          </div>
          <div
            className={`wide-button ${!editing && "disabled"}`}
            style={{ width: 80, marginTop: 20 }}
            onClick={async () => {
              await updateWMS({
                variables: {
                  warehouseId: warehouseId,
                  wms: comboWMS.id,
                  shelfFormat: comboShelfFormat ? comboShelfFormat.id : null,
                },
                refetchQueries: [
                  {
                    query: GET_WAREHOUSE_WMS,
                    variables: {
                      warehouseId: warehouseId,
                    },
                  },
                ],
              });
              setEditing(false);
            }}>
            Save
          </div>
        </div>
      }
    </>
  );
}

export default WMSSettings;
