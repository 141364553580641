import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import axios from "axios";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { BiFile } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { IoMdCheckmark, IoMdClose, IoMdTrash } from "react-icons/io";
import DataInput from "../../components/DataInput/DataInput";
import Dropzone from "../../components/Dropzone/Dropzone";
import formatDate from "../../formatDate";
import { CREATE_DATASET, DELETE_DATASET } from "../../graphql/mutations";
import { GET_DATASETS, GET_FILES } from "../../graphql/queries";
import { FILE_TYPES, getCssVariable, getFileType, useStateRef } from "../../utils";
import { showConfirmDialogVar, showToastVar, warehouseVar } from "../../cache";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Checkbox, FormControlLabel, Tab, Tabs, TextField } from "@material-ui/core";
import "./DatasetUpload.css";
import { useHistory, useLocation } from "react-router-dom";
import {
  CreateDatasetMutation,
  CreateDatasetMutationVariables,
  DeleteDatasetMutation,
  DeleteDatasetMutationVariables,
  GetDatasetsQuery,
  GetDatasetsQueryVariables,
  GetFilesQuery,
  GetFilesQueryVariables,
} from "../../graphql/graphqlTypes";
import Spinner from "../../components/Spinner/Spinner";
import { ImFileEmpty, ImFilesEmpty } from "react-icons/im";

function DatasetUpload() {
  const [acceptedFiles, setAcceptedFiles, acceptedFilesRef] = useStateRef([]);
  const [addedPrevFile, setAddedPrevFile] = useState<GetFilesQuery["files"][0]>(null);
  const [datasetName, setDatasetName] = useState<string>(null);
  const [ready, setReady] = useState<boolean>(false);
  const [creating, setCreating] = useState<boolean>(false);
  const warehouse = useReactiveVar(warehouseVar);
  const [tab, setTab] = useState<number>(0);
  const [cadImport, setCadImport] = useState(true);

  const [fileType, setFileType] = useState<{ id: number; name: string }>(null);
  const [fileTypes, setFileTypes] = useState<{ id: number; name: string }[]>([]);
  const [filesStatus, setFilesStatus, filesStatusRef] = useStateRef<
    {
      name: string;
      type: FILE_TYPES;
      status: boolean;
      acceptedFileIndex: number;
      fileName: string;
      fileId: string;
      loading: boolean;
      done: boolean;
      createdAt: Date | string;
    }[]
  >([]);

  const { data: filesData } = useQuery<GetFilesQuery, GetFilesQueryVariables>(GET_FILES, {
    skip: !fileType || !warehouse,
    fetchPolicy: "network-only",
    variables: { warehouseId: warehouse && warehouse.id, type: fileType && fileType.name },
  });

  const { data: datasetsData, refetch: refetchDatasetsData } = useQuery<GetDatasetsQuery, GetDatasetsQueryVariables>(
    GET_DATASETS,
    {
      skip: !warehouse,
      variables: { warehouseId: warehouse && warehouse.id },
      fetchPolicy: "network-only",
    },
  );

  const [createDatasetMutation] = useMutation<CreateDatasetMutation, CreateDatasetMutationVariables>(CREATE_DATASET);
  const [deleteDatasetMutation] = useMutation<DeleteDatasetMutation, DeleteDatasetMutationVariables>(DELETE_DATASET);
  let history = useHistory();

  useEffect(() => {
    // These has to be in the same order the files should be uploaded (parsed) in
    let fileTypes = [];
    if (cadImport) {
      fileTypes = [
        { id: 0, name: "Cad", type: FILE_TYPES.CAD },
        { id: 1, name: "Picker", type: FILE_TYPES.PICKER },
        { id: 2, name: "Truck", type: FILE_TYPES.TRUCK },
        { id: 3, name: "Cart", type: FILE_TYPES.CART },
        { id: 4, name: "Box", type: FILE_TYPES.BOX },
        { id: 5, name: "Order", type: FILE_TYPES.ORDER },
        { id: 6, name: "Item", type: FILE_TYPES.ITEM },
        { id: 7, name: "Orderline", type: FILE_TYPES.ORDERLINE },
      ];
    } else {
      fileTypes = [
        { id: 0, name: "Zone", type: FILE_TYPES.ZONE },
        { id: 1, name: "Block", type: FILE_TYPES.BLOCK },
        { id: 2, name: "Rack", type: FILE_TYPES.RACK },
        { id: 3, name: "Aisle", type: FILE_TYPES.AISLE },
        { id: 4, name: "Shelf", type: FILE_TYPES.SHELF },
        { id: 5, name: "Crossaisle", type: FILE_TYPES.CROSSAISLE },
        { id: 6, name: "Depot", type: FILE_TYPES.DEPOT },
        { id: 7, name: "Picker", type: FILE_TYPES.PICKER },
        { id: 8, name: "Truck", type: FILE_TYPES.TRUCK },
        { id: 9, name: "Cart", type: FILE_TYPES.CART },
        { id: 10, name: "Box", type: FILE_TYPES.BOX },
        { id: 11, name: "Order", type: FILE_TYPES.ORDER },
        { id: 12, name: "Item", type: FILE_TYPES.ITEM },
        { id: 13, name: "Orderline", type: FILE_TYPES.ORDERLINE },
        { id: 14, name: "Node", type: FILE_TYPES.NODE },
      ];
    }

    let initialFilesStatus = [];
    fileTypes.forEach((fileType, index) => {
      initialFilesStatus.push({
        name: fileType.name,
        type: fileType.type,
        status: false,
        acceptedFileIndex: -1,
        fileName: "",
        fileId: "",
        loading: false,
        done: false,
        createdAt: "",
      });
    });
    setFileType(fileTypes[0]);
    setFileTypes(fileTypes);
    setFilesStatus(initialFilesStatus);
  }, [cadImport]);

  useEffect(() => {
    const checkFiles = async () => {
      let newStatus = filesStatusRef.current;
      for (let idx = 0; idx < acceptedFiles.length; idx++) {
        const file = acceptedFiles[idx];
        // !file => acceptedFile has been replaced
        if (file) {
          if (file.type !== "application/json") {
            await new Promise(async (resolve, reject) => {
              // First, find delimiter used in the file
              let delimiter = "";
              await new Promise((resolve, reject) => {
                Papa.parse(file, {
                  preview: 1,
                  delimiter: "NO_DELIMITER",
                  complete: (results, file) => {
                    const firstRow = results.data[0] as Array<string>;
                    if (firstRow[0].includes("\t")) {
                      delimiter = "\t";
                    } else if (firstRow[0].includes(";")) {
                      delimiter = ";";
                    } else if (firstRow[0].includes(",")) {
                      delimiter = ",";
                    } else if (firstRow[0].includes(" ")) {
                      delimiter = " ";
                    }
                    resolve("done");
                  },
                });
              });

              // Then, get the file type based on the headers using the delimiter
              Papa.parse(file, {
                preview: 1,
                delimiter: delimiter,
                complete: (results, file) => {
                  const fileType = getFileType(results.data[0]);
                  if (fileType !== null) {
                    newStatus = newStatus.map((type) => {
                      if (type.type === fileType) {
                        return {
                          ...type,
                          status: true,
                          acceptedFileIndex: idx,
                          fileName: file.name,
                          createdAt: new Date(),
                          fileId: null,
                        };
                      }
                      return type;
                    });
                  } else {
                    showToastVar({
                      type: "ERROR",
                      message: "File headers not recoginzed",
                    });
                  }
                  resolve("done");
                },
              });
            });
          } else {
            // CAD IMPORT
            newStatus = newStatus.map((type) => {
              if (type.type === FILE_TYPES.CAD) {
                return {
                  ...type,
                  status: true,
                  acceptedFileIndex: idx,
                  fileName: file.name,
                  createdAt: new Date(),
                  fileId: null,
                };
              }
              return type;
            });
          }
        }
      }
      setFilesStatus(newStatus);
    };

    if (acceptedFiles.length) {
      checkFiles();
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (addedPrevFile) {
      const file = addedPrevFile;
      const newStatus = filesStatusRef.current.map((type) => {
        if (type.name === fileType.name) {
          if (type.acceptedFileIndex !== -1) {
            // Set previous accepted file to null -> will keep the indexes correct
            const newAcceptedFiles = acceptedFiles;
            newAcceptedFiles[type.acceptedFileIndex] = null;
            setAcceptedFiles(newAcceptedFiles);
          }

          // Updating filesStatus with added prev file
          // acceptedFileIndex set to -1 because it is not a new uploaded file
          // fileId is set to the prev file added
          return {
            ...type,
            status: true,
            acceptedFileIndex: -1,
            fileId: file.id,
            fileName: file.name,
            createdAt: file.createdAt,
          };
        }
        return type;
      });
      setFilesStatus(newStatus);
    }
  }, [addedPrevFile]);

  useEffect(() => {
    const fileNotUploaded = filesStatus.find((fileStatus) => {
      // Node file is not required
      if (!fileStatus.status && fileStatus.name !== "Node") {
        return true;
      }
    });
    if (!fileNotUploaded && datasetName) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [filesStatus, datasetName]);

  const createDataset = async () => {
    const res = await createDatasetMutation({
      variables: {
        // warehouseId:
        datasetName: datasetName,
        warehouseId: warehouse.id,
      },
    });

    if (res.data && res.data.createDataset) {
      for (var i = 0; i < filesStatus.length; i++) {
        if (!filesStatus[i].status) continue;
        const loading = filesStatus.map((fileStatus, index) => {
          if (index < i) {
            return { ...fileStatus, loading: false, done: true };
          }
          if (index === i) {
            return { ...fileStatus, loading: true, done: false };
          }
          return fileStatus;
        });
        setFilesStatus(loading);

        let success = true;
        await new Promise(async (resolve, reject) => {
          const acceptedFileIndex = filesStatus[i].acceptedFileIndex;
          const formData = new FormData();
          if (acceptedFileIndex !== -1) {
            // New uploaded file
            const file = acceptedFiles[acceptedFileIndex];
            formData.append("file", file);
            formData.append("datasetId", res.data.createDataset.id);
          } else {
            // Previous file
            formData.append("fileId", filesStatus[i].fileId);
            formData.append("fileName", filesStatus[i].fileName);
            formData.append("fileType", filesStatus[i].name);
            formData.append("datasetId", res.data.createDataset.id);
          }
          const token = localStorage.getItem("jwt-warehouse-optimization");
          try {
            const uploadRes = await axios.post("/upload", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: `Bearer ${token}`,
              },
            });
            if (!uploadRes.data.success) {
              reject(`Could not create file ${filesStatus[i].fileName}.
              ${uploadRes.data.error}`);
            } else {
              resolve("done");
            }
          } catch (err) {
            console.log(err);
            reject("Network error");
          }
        }).catch((e) => {
          setCreating(false);
          const loading = filesStatus.map((fileStatus) => {
            return { ...fileStatus, loading: false, done: false };
          });
          setFilesStatus(loading);
          showToastVar({
            type: "ERROR",
            message: e,
            disableAutoHide: true,
          });
          success = false;
        });

        if (!success) {
          // Stop looping and avoid navigating to "/layouts" - by returning early
          return;
        }
      }
      // Done - navigate back to table view
      history.push({
        pathname: "/layouts",
      });
    }
  };

  const addDatasetFiles = (dataset: GetDatasetsQuery["datasets"][0]) => {
    const newStatus = filesStatusRef.current.map((type) => {
      let file;
      if (type.name === "Cad") {
        file = dataset.cadFile;
      } else if (type.name === "Zone") {
        file = dataset.zoneFile;
      } else if (type.name === "Block") {
        file = dataset.blockFile;
      } else if (type.name === "Rack") {
        file = dataset.rackFile;
      } else if (type.name === "Aisle") {
        file = dataset.aisleFile;
      } else if (type.name === "Shelf") {
        file = dataset.shelfFile;
      } else if (type.name === "Crossaisle") {
        file = dataset.crossAisleFile;
      } else if (type.name === "Depot") {
        file = dataset.depotFile;
      } else if (type.name === "Node") {
        file = dataset.nodeFile;
      } else if (type.name === "Picker") {
        file = dataset.pickerFile;
      } else if (type.name === "Truck") {
        file = dataset.truckFile;
      } else if (type.name === "Cart") {
        file = dataset.cartFile;
      } else if (type.name === "Box") {
        file = dataset.boxFile;
      } else if (type.name === "Order") {
        file = dataset.orderFile;
      } else if (type.name === "Item") {
        file = dataset.itemFile;
      } else if (type.name === "Orderline") {
        file = dataset.orderRowFile;
      }

      if (type.acceptedFileIndex !== -1) {
        // Set previous accepted file to null -> will keep the indexes correct
        const newAcceptedFiles = acceptedFiles;
        newAcceptedFiles[type.acceptedFileIndex] = null;
        setAcceptedFiles(newAcceptedFiles);
      }
      if (file) {
        return {
          ...type,
          status: true,
          acceptedFileIndex: -1,
          fileId: file.id,
          fileName: file.name,
          createdAt: file.createdAt,
        };
      } else {
        return {
          ...type,
        };
      }
    });
    setFilesStatus(newStatus);
  };

  return (
    <>
      {
        <div style={{ width: "100%", flexDirection: "column", justifyContent: "center" }}>
          <div className="upload-top-container">
            <div className="upload-name-container">
              {
                <DataInput
                  label={"Dataset name"}
                  autoFocus={true}
                  setValueCallback={(value) => {
                    setDatasetName(value);
                  }}
                />
              }
            </div>
            <div style={{ display: "flex" }}>
              {/* <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cadImport}
                      onChange={(event) => {
                        setCadImport(event.target.checked);
                      }}
                      name="weekDays"
                      color="primary"
                    />
                  }
                  label="CAD import"
                />
              </div> */}
              <div
                className="wide-button"
                style={{
                  width: 100,
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={async () => {
                  history.push({
                    pathname: "/layouts",
                  });
                }}>
                Cancel
              </div>
              <div
                className="wide-button"
                style={{
                  width: 100,
                  marginRight: 0,
                  opacity: ready && !creating ? 1 : 0.5,
                  cursor: ready && !creating ? "pointer" : "default",
                }}
                onClick={async () => {
                  if (ready && !creating) {
                    setCreating(true);
                    await createDataset();
                  }
                }}>
                {creating ? <Spinner size={30} /> : "Create"}
              </div>
            </div>
          </div>
          <div className="flex-center">
            <div className="upload-container-inner">
              <div className="flex-center prev-files-container">
                <Tabs
                  style={{ marginBottom: 20, marginTop: 30 }}
                  value={tab}
                  indicatorColor="secondary"
                  textColor="secondary"
                  onChange={(event, newValue) => setTab(newValue)}
                  disableRipple={true}>
                  <Tab style={{ minWidth: 100 }} disableRipple={true} label="datasets" />
                  <Tab style={{ minWidth: 100 }} disableRipple={true} label="files" />
                </Tabs>

                {tab === 1 && (
                  <Autocomplete
                    id="account-filter-combo"
                    options={fileTypes}
                    getOptionLabel={(option: any) => option.name}
                    getOptionSelected={(option, value) => {
                      if (option && value) return option.id === value.id;
                    }}
                    value={fileType}
                    onChange={(event: any, newValue: any) => {
                      setFileType(newValue);
                    }}
                    style={{
                      padding: 5,
                      marginRight: 10,
                      width: "70%",
                      color: getCssVariable("--color-white0"),
                      marginBottom: 10,
                    }}
                    renderInput={(params) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField label="File type" {...params} />
                      </div>
                    )}
                  />
                )}
                {tab === 0 && (
                  <div className="files-list">
                    {datasetsData &&
                      datasetsData.datasets.map((dataset, index) => {
                        return (
                          <div className="prev-file" id={"prev-" + index}>
                            <div className="prev-file-inner">
                              <ImFilesEmpty style={{ marginLeft: 10, marginTop: 3 }} />
                              <span className="prev-file-name">{dataset.name}</span>
                              <span
                                style={{
                                  marginLeft: 10,
                                  color: getCssVariable("--color-black0"),
                                }}>
                                {formatDate(dataset.createdAt)}
                              </span>
                            </div>
                            <div
                              style={{
                                padding: 10,
                              }}
                              className={`${dataset.deletable && "hover"}`}
                              onClick={() => {
                                if (dataset.deletable) {
                                  showConfirmDialogVar({
                                    action: "DELETE",
                                    type: "dataset",
                                    onConfirm: async () => {
                                      await deleteDatasetMutation({
                                        variables: {
                                          datasetId: dataset.id,
                                        },
                                      });
                                      await refetchDatasetsData();
                                      showConfirmDialogVar(null);
                                    },
                                    onCancel: () => {
                                      showConfirmDialogVar(null);
                                    },
                                  });
                                }
                              }}>
                              <IoMdTrash
                                title="Delete"
                                className={`${!dataset.deletable && "disabled"}`}
                                style={{
                                  color: getCssVariable("--color-black0"),
                                }}
                              />
                            </div>
                            <div
                              style={{ backgroundColor: getCssVariable("--color-blue0"), padding: 10 }}
                              className="hover"
                              onClick={() => {
                                addDatasetFiles(dataset);
                              }}
                              onMouseEnter={(e) => {
                                const prevFileEl = document.getElementById("prev-" + index);
                                if (prevFileEl) {
                                  prevFileEl.style.transform = "translateX(10px)";
                                }
                              }}
                              onMouseLeave={(e) => {
                                const prevFileEl = document.getElementById("prev-" + index);
                                if (prevFileEl) {
                                  prevFileEl.style.transform = "translateX(0px)";
                                }
                              }}>
                              <BsArrowRight
                                style={{
                                  color: getCssVariable("--color-white0"),
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
                {tab === 1 && (
                  <div className="files-list">
                    {filesData &&
                      filesData.files.map((file, index) => {
                        return (
                          <div className="prev-file" id={"prev-" + index}>
                            <div className="prev-file-inner">
                              <ImFileEmpty style={{ marginLeft: 10, marginTop: 3 }} />
                              <span className="prev-file-name">{file.name}</span>
                              <span
                                style={{
                                  marginLeft: 10,
                                  color: getCssVariable("--color-black0"),
                                }}>
                                {formatDate(file.createdAt)}
                              </span>
                            </div>
                            <div
                              style={{ backgroundColor: getCssVariable("--color-blue0"), padding: 10 }}
                              className="hover"
                              onClick={() => setAddedPrevFile(file)}
                              onMouseEnter={(e) => {
                                const prevFileEl = document.getElementById("prev-" + index);
                                if (prevFileEl) {
                                  prevFileEl.style.transform = "translateX(10px)";
                                }
                              }}
                              onMouseLeave={(e) => {
                                const prevFileEl = document.getElementById("prev-" + index);
                                if (prevFileEl) {
                                  prevFileEl.style.transform = "translateX(0px)";
                                }
                              }}>
                              <BsArrowRight
                                style={{
                                  color: getCssVariable("--color-white0"),
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
              <div className="new-files-container">
                <div className="files-status">
                  {filesStatus.map((type) => {
                    return (
                      <>
                        <div className="file-status">
                          <span style={{ cursor: "default" }}>{type.name}</span>
                          <div style={{ marginTop: 6, fontSize: 16, marginLeft: 4 }}>
                            <IoMdCheckmark style={{ opacity: type.status ? 1 : 0.2 }} />
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <Dropzone
                  setNewFiles={(newFiles) => {
                    setAcceptedFiles([...acceptedFilesRef.current, ...newFiles]);
                  }}
                  setFileRejections={(rejectedFiles) => {
                    showToastVar({
                      type: "ERROR",
                      message: "Invalid file format",
                    });
                  }}
                />
                <div className="files-list">
                  {filesStatus.map((file) => {
                    return file.fileName ? (
                      <>
                        <div className="new-file">
                          <div style={{ display: "flex", alignItems: "center", width: 100 }}>
                            <ImFileEmpty style={{ marginRight: 10, fontSize: 22 }} />
                            {!file.fileId && <div className="new-upload">new</div>}
                          </div>
                          <span style={{ marginLeft: 5, fontWeight: 700 }}>{file.fileName}</span>
                          <div>{file.createdAt && formatDate(file.createdAt)}</div>
                          <div style={{ width: 20 }}>
                            {file.loading && <Spinner size={20} />}
                            {file.done && (
                              <IoMdCheckmark
                                style={{
                                  fontSize: 20,
                                  color: getCssVariable("--color-blue0"),
                                }}
                              />
                            )}
                            {!file.loading && !file.done && <div></div>}
                          </div>
                        </div>
                      </>
                    ) : null;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default DatasetUpload;
