import { ChartData } from "chart.js";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import { GetSimulationResultQuery } from "../../graphql/graphqlTypes";
import { getCssVariable } from "../../utils";

type Props = {
  routes: GetSimulationResultQuery["simulationResult"]["routes"];
  type: "PICKING_ROUNDS_TIME" | "PICKING_ROUNDS_PICKER";
};

const LineChart = (props: Props) => {
  const { routes, type } = props;

  let startFinishTimes = [];
  routes.forEach((r, i) => {
    startFinishTimes.push(r.start);
    startFinishTimes.push(r.finish);
  });
  startFinishTimes.sort((a, b) => {
    if (moment(a) < moment(b)) {
      return -1;
    }
    return 1;
  });

  // Remove duplicates
  //@ts-ignore
  startFinishTimes = [...new Set(startFinishTimes)];

  let labels = [];
  startFinishTimes.forEach((t, i) => {
    // if (i === 0) {
    //   // First label => include date
    labels.push(t);
    // } else if (moment(startFinishTimes[i - 1]).format("YYYY-MM-DD") !== moment(t).format("YYYY-MM-DD")) {
    //   // New date => include date
    //   labels.push(t);
    // } else {
    // Not a new date => no date
    // labels.push(moment(t).format("HH:mm:ss"));
    // }
  });

  let pickers = {};
  routes.forEach((r) => {
    if (!pickers[r.picker]) {
      pickers[r.picker] = 1;
    }
  });
  return (
    <>
      <Line
        data={{
          labels,
          datasets: routes.map((r, i) => {
            pickers[r.picker] = pickers[r.picker] + 1;
            return {
              label: type === "PICKING_ROUNDS_PICKER" ? "Picker" : "Picking round",
              data: startFinishTimes.map((l) => {
                if (moment(r.finish) < moment(l) || moment(r.start) > moment(l)) {
                  return null;
                }
                if (type === "PICKING_ROUNDS_PICKER") {
                  return r.picker;
                } else if (type === "PICKING_ROUNDS_TIME") {
                  return i + 1;
                }
              }),
              backgroundColor:
                type === "PICKING_ROUNDS_PICKER"
                  ? pickers[r.picker] % 2 === 0
                    ? getCssVariable("--color-blue0")
                    : getCssVariable("--color-blue1")
                  : getCssVariable("--color-blue0"),
            };
          }),
        }}
        options={{
          animation: {
            duration: 0,
          },

          scales: {
            // x: {
            //   ticks: {
            //     maxTicksLimit: 20,
            //     autoSkip: true,
            //   },
            // },
            y: {
              title: {
                display: true,
                text: type === "PICKING_ROUNDS_PICKER" ? "Picker" : "Picking round",
                padding: 20,
                font: { size: 18 },
              },
              ticks: {
                stepSize: 1,
                autoSkip: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        }}
      />
    </>
  );
};

export default LineChart;
