import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { useHistory, useLocation } from "react-router-dom";
import { GET_USER } from "../../graphql/queries";
import logo from "../../logo-text.png";
import { useQuery, useApolloClient } from "@apollo/client";
import { getCssVariable } from "../../utils";
import { FiLogOut, FiUser } from "react-icons/fi";
import { GetUserQuery, GetUserQueryVariables } from "../../graphql/graphqlTypes";
import { ReactComponent as LogoText } from "../../logo-hor.svg";

function Navbar() {
  let history = useHistory();
  const { data: userData, refetch: refetchUserData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });
  const location = useLocation();

  return (
    <>
      <div className="navbar">
        <div
          className="navbar-back ion-ios-arrow-back"
          style={
            location.pathname !== "/admin" && !location.pathname.includes("/simulation") ? { marginLeft: -65 } : {}
          }
          onClick={() => {
            history.push("/");
          }}></div>

        <div style={{ position: "relative", marginLeft: 30 }}>
          {/* <div style={{ width: 180 }}> */}
          {/* <img src={logo} style={{ height: 60 }}></img> */}
          <LogoText style={{ width: 200 }} />
          {/* <Logo style={{ fill: "#eee" }} /> */}
          {/* </div> */}
        </div>

        <div className="navbar-logout-container">
          {userData && userData.user.permissions.includes("ViewSimulations") && (
            <div
              className={"navbar-button settings"}
              style={location.pathname.includes("/simulation") ? { borderBottom: "1px solid white", opacity: 1 } : {}}
              onClick={() => {
                history.push({
                  pathname: "/simulation",
                });
              }}>
              <div className="navbar-button-text">SIMULATION</div>
            </div>
          )}
          {userData && userData.user.permissions.includes("ViewDivisions") && (
            <div
              className={"navbar-button settings"}
              style={location.pathname === "/admin" ? { borderBottom: "1px solid white", opacity: 1 } : {}}
              onClick={() => {
                history.push({
                  pathname: "/admin",
                });
              }}>
              <div className="navbar-button-text">ADMIN</div>
            </div>
          )}
          {userData && (
            <div id="user-name" style={{ marginLeft: 40, cursor: "default", marginRight: 20 }}>
              <span style={{ marginTop: 5 }}>
                <FiUser style={{ fontSize: 19, marginRight: 10, position: "absolute", left: -24, top: 2 }} />
              </span>
              {userData.user.name}
            </div>
          )}
          {userData && (
            <>
              <div
                onClick={() => {
                  localStorage.setItem("jwt-warehouse-optimization", "");
                  refetchUserData();
                }}
                className="navbar-logout">
                <FiLogOut style={{ fontSize: 24 }} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Navbar;
