import React, { useEffect, useRef, useState } from "react";
import "./Toast.css";
import { BiCheckCircle, BiError, BiHide } from "react-icons/bi";
import { getCssVariable } from "../../utils";
import { showToastVar } from "../../cache";
import { IoMdClose } from "react-icons/io";

type Props = {
  type: "ERROR" | "SUCCESS";
  message: string;
  disableAutoHide?: boolean;
};

function Toast(props: Props) {
  const { type, message, disableAutoHide } = props;
  const toastRef = useRef(null);
  let timeout = null;

  useEffect(() => {
    if (toastRef) {
      setTimeout(() => {
        toastRef.current.style.bottom = "60px";
      }, 0);
      if (!disableAutoHide) {
        timeout = setTimeout(() => {
          toastRef.current.style.bottom = "-100px";
          setTimeout(() => {
            showToastVar(null);
          }, 1000);
        }, 6000);
      }
    }
    return () => {
      if (!disableAutoHide) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const hide = () => {
    toastRef.current.style.bottom = "-100px";
    setTimeout(() => {
      showToastVar(null);
    }, 1000);
  };

  return (
    <div ref={toastRef} className="flex-center toast-wrapper">
      <div
        className={`flex-center toast-container ${type === "SUCCESS" && "success"}`}
        style={disableAutoHide ? { cursor: "default" } : {}}
        onClick={() => {
          if (!disableAutoHide) {
            hide();
          }
        }}>
        {type === "ERROR" && <BiError style={{ color: getCssVariable("--color-white0"), fontSize: 24 }} />}
        {type === "SUCCESS" && <BiCheckCircle style={{ color: getCssVariable("--color-white0"), fontSize: 24 }} />}
        <div style={{ color: getCssVariable("--color-white0"), marginLeft: 20 }}>{message}</div>
        {disableAutoHide && (
          <IoMdClose
            onClick={() => {
              hide();
            }}
            style={{ color: getCssVariable("--color-white0"), fontSize: 24, marginLeft: 20, cursor: "pointer" }}
          />
        )}
      </div>
    </div>
  );
}

export default Toast;
