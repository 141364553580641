import React, { useState, useEffect, useRef } from "react";
import "./SubNavBar.css";
import { getCssVariable } from "../../utils";
import { createMuiTheme, MuiThemeProvider, Tab, Tabs } from "@material-ui/core";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import { GET_ACCOUNTS, GET_DIVISIONS, GET_USER, GET_WAREHOUSES } from "../../graphql/queries";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { warehouseVar } from "../../cache";
import {
  GetAccountsQuery,
  GetAccountsQueryVariables,
  GetDivisionsQuery,
  GetDivisionsQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  GetWarehousesQuery,
  GetWarehousesQueryVariables,
} from "../../graphql/graphqlTypes";
import { IoMdAdd } from "react-icons/io";

// State stored in localstorage to bring the User back to the previous state
// when navigating back to this view
function getLocalstorageValue(key: "filterWarehouse" | "filterAccount") {
  let state = localStorage.getItem("w-filter-state");
  if (state) {
    let JsonState = JSON.parse(state);
    if (JsonState) {
      return JsonState[key];
    }
  }
}

function SubNavBar() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#319af3",
      },
    },
  });

  const [tab, setTab] = useState<number>(null);

  const [user, setUser] = useState<GetUserQuery["user"]>(null);
  const [comboAccount, setComboAccount] = useState<GetUserQuery["user"]["account"]>(null);
  const [comboWarehouse, setComboWarehouse] = useState<{ id: string; name: string }>(null);
  const [showFilter, setShowFilter] = useState(false);

  const wrapperRef = useRef(null);
  const filterButtonRef = useRef(null);
  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref, func) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !event.target.classList.contains("option") &&
          !event.target.classList.contains("MuiAutocomplete-option") &&
          !event.target.classList.contains("MuiAutocomplete-listbox") &&
          !filterButtonRef.current.contains(event.target)
        ) {
          func();
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef, () => setShowFilter(false));

  let history = useHistory();
  const pathName = useLocation().pathname.replace(/\/$/, "");
  const warehouse = useReactiveVar(warehouseVar);

  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });

  const { data: accountsData } = useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GET_ACCOUNTS, {
    fetchPolicy: "network-only",
  });

  const { data: warehousesData } = useQuery<GetWarehousesQuery, GetWarehousesQueryVariables>(GET_WAREHOUSES, {
    fetchPolicy: "network-only",
    variables: { accountId: comboAccount && comboAccount.id },
    skip: !user || !user.permissions.includes("ViewWarehouses") || !comboAccount,
  });

  useEffect(() => {
    if (comboWarehouse) {
      warehouseVar(comboWarehouse);
    }
  }, [comboWarehouse]);

  useEffect(() => {
    if (pathName.includes("/import")) {
      setTab(0);
    } else if (pathName.includes("/layouts")) {
      setTab(1);
    } else if (pathName.includes("/resources")) {
      setTab(2);
    } else if (pathName.includes("/jobs")) {
      setTab(3);
    } else if (pathName.includes("/optimizations")) {
      setTab(4);
    } else if (pathName === "/" || pathName === "") {
      history.push({
        pathname: "/layouts",
      });
      setTab(0);
    }
  }, [pathName]);

  useEffect(() => {
    if (userData) {
      setUser(userData.user);
    }
  }, [userData]);

  useEffect(() => {
    if (accountsData && userData) {
      if (
        window.localStorage.getItem("w-filter-state") &&
        getLocalstorageValue("filterAccount") &&
        accountsData.accounts.find((account) => account.id === getLocalstorageValue("filterAccount").id)
      ) {
        // Set initial from local storage filter
        setComboAccount(getLocalstorageValue("filterAccount"));
      } else {
        // Set initial
        setComboAccount(userData.user.account);
      }
    }
  }, [accountsData, userData]);

  useEffect(() => {
    if (warehousesData) {
      if (
        window.localStorage.getItem("w-filter-state") &&
        getLocalstorageValue("filterWarehouse") &&
        warehousesData.warehouses.find((warehouse) => warehouse.id === getLocalstorageValue("filterWarehouse").id)
      ) {
        // Set initial from local storage filter
        setComboWarehouse(getLocalstorageValue("filterWarehouse"));
      } else if (warehousesData.warehouses.length) {
        // Set initial
        setComboWarehouse({ id: warehousesData.warehouses[0].id, name: warehousesData.warehouses[0].name });
      }
    }
  }, [warehousesData]);

  useEffect(() => {
    if (comboAccount && comboWarehouse) {
      // Update local storage state
      let filter = {
        filterAccount: comboAccount,
        filterWarehouse: comboWarehouse,
      };
      localStorage.setItem("w-filter-state", JSON.stringify(filter));
    }
  }, [comboAccount, comboWarehouse]);

  return (
    <div className="sub-navbar">
      {showFilter && (
        <div ref={wrapperRef} className="sub-navbar-filter">
          <div className="arrow-up"></div>
          {user && user.permissions.includes("ViewWarehouses") && (
            <>
              {user && user.permissions.includes("ViewAccounts") && (
                <Autocomplete
                  id="account-filter-combo"
                  options={accountsData ? accountsData.accounts : []}
                  getOptionLabel={(option: any) => option.name}
                  getOptionSelected={(option, value) => {
                    if (option && value) return option.id === value.id;
                  }}
                  disableClearable
                  value={comboAccount}
                  onChange={(event: any, newValue: any) => {
                    if (newValue) {
                      setComboWarehouse(null);
                      setComboAccount(newValue);
                    }
                  }}
                  style={{
                    padding: 5,
                    marginRight: 10,
                    width: "100%",
                    marginBottom: 10,
                  }}
                  renderOption={(option) => {
                    return <div className="option">{option.name}</div>;
                  }}
                  renderInput={(params) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField label="Account" {...params} />
                    </div>
                  )}
                />
              )}
              {user && user.permissions.includes("ViewWarehouses") && (
                <Autocomplete
                  id="division-filter-combo"
                  disableClearable
                  options={warehousesData ? warehousesData.warehouses : []}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => {
                    if (option && value) return option.id === value.id;
                  }}
                  value={comboWarehouse}
                  onChange={(event: any, newValue: any) => {
                    setComboWarehouse(newValue);
                  }}
                  style={{
                    padding: 5,
                    marginRight: 10,
                    width: "100%",
                    marginBottom: 10,
                  }}
                  renderInput={(params) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField label="Warehouse" {...params} />
                    </div>
                  )}
                />
              )}
            </>
          )}
        </div>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          className="wide-button"
          ref={filterButtonRef}
          style={{ marginRight: 40, marginLeft: 60, width: "auto", opacity: showFilter ? 0.5 : 1 }}
          onClick={() => setShowFilter(!showFilter)}>
          <div style={{ marginRight: 10, fontWeight: 600, marginBottom: 2 }}>{warehouse && warehouse.name}</div>
          <MdKeyboardArrowDown
            style={{
              fontSize: 21,
              paddingTop: 3,
              transform: showFilter ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all 0.5s",
            }}
          />
        </div>
        <MuiThemeProvider theme={theme}>
          <Tabs
            style={{ marginBottom: 18, marginTop: 30 }}
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, newValue) => {
              switch (newValue) {
                case 0:
                  history.push({
                    pathname: "/import",
                  });
                  break;
                case 1:
                  history.push({
                    pathname: "/layouts",
                  });
                  break;
                case 2:
                  history.push({
                    pathname: "/resources",
                  });
                  break;
                case 3:
                  history.push({
                    pathname: "/jobs",
                  });
                  break;
                case 4:
                  history.push({
                    pathname: "/optimizations",
                  });
                  break;
                default:
                  break;
              }
              setTab(newValue);
            }}
            disableRipple={true}>
            <Tab style={{ minWidth: 100 }} disableRipple={true} label="Import" />
            <Tab style={{ minWidth: 100 }} disableRipple={true} label="Layouts" />
            <Tab style={{ minWidth: 100 }} disableRipple={true} label="Resources" />
            <Tab style={{ minWidth: 100 }} disableRipple={true} label="Jobs" />
            <Tab style={{ minWidth: 100 }} disableRipple={true} label="Optimizations" />
          </Tabs>
        </MuiThemeProvider>
      </div>
      <div
        id="new-optimization-button"
        className="wide-button flex-center"
        style={{
          margin: 0,
          minWidth: 200,
        }}
        onClick={() => {
          history.push({
            pathname: "/optimizations/new",
          });
        }}>
        <IoMdAdd style={{ color: getCssVariable("--color-white0") }} />
        <div style={{ marginBottom: 3, marginLeft: 4 }}>New Optimization</div>
      </div>
    </div>
  );
}

export default SubNavBar;
