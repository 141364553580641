// Apollo Client cache storing "Reactive variables" https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/#storing-local-state-in-reactive-variables

import { makeVar } from "@apollo/client";

// Current warehouse
export const warehouseVar = makeVar<null | { id: string; name: string }>(null);
export const showConfirmDialogVar = makeVar<null | {
  action: "DELETE" | "SAVE" | "STOP_SAVE" | "STOP";
  action2?: "STOP_REMOVE";
  type: string;
  onConfirm: Function;
  onConfirm2?: Function;
  onCancel: Function;
}>(null);
export const showToastVar = makeVar<null | {
  type: "ERROR" | "SUCCESS";
  message: string;
  disableAutoHide?: boolean;
}>(null);
