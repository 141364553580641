import React, { useState, useEffect, useRef } from "react";
import "./ResultsModal.css";
import { IoMdClose, IoMdPlayCircle, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { createMuiTheme, makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import { useQuery } from "@apollo/client";
import {
  GetJobDatasetQuery,
  GetJobDatasetQueryVariables,
  GetSimulationResultCustomerQuery,
  GetSimulationResultCustomerQueryVariables,
  GetSimulationResultQuery,
  GetSimulationResultQueryVariables,
  Simulation,
  Optimization,
  GetOptimizationQuery,
  GetOptimizationQueryVariables,
  GetOptimizationResultQueryVariables,
  GetOptimizationResultQuery,
  Result,
  GetOptimizationLateOrdersQuery,
  GetOptimizationLateOrdersQueryVariables,
} from "../../graphql/graphqlTypes";
import {
  GET_JOB_DATASET,
  GET_OPTIMIZATION,
  GET_OPTIMIZATION_LATE_ORDERS,
  GET_OPTIMIZATION_RESULT,
  GET_SIMULATION_RESULT,
  GET_SIMULATION_RESULT_CUSTOMER,
} from "../../graphql/queries";
import { getCssVariable } from "../../utils";
import { Tab, Tabs } from "@material-ui/core";
import BarChart from "../BarChart/BarChart";
import DataInput from "../DataInput/DataInput";
import LineChart from "../LineChart/LineChart";
import moment from "moment";

type Props = {
  closeModal: Function;
  simulation?: Simulation;
  optimization?: Optimization;
};

function ResultsModal(props: Props) {
  const { closeModal, simulation, optimization } = props;

  const modalHeaderRef = useRef(null);
  const modalHeaderComboRef = useRef(null);
  const modalRef = useRef(null);

  const { data: jobDatasetData, loading: jobDatasetLoading } = useQuery<
    GetJobDatasetQuery,
    GetJobDatasetQueryVariables
  >(GET_JOB_DATASET, {
    variables: { datasetId: simulation ? simulation.datasetId : optimization.dataset.id },
    fetchPolicy: "network-only",
  });

  const {
    data: optimizationLateOrdersData,
    loading: optimizationLateOrdersLoading,
    refetch: optimizationLateOrdersRefetch,
  } = useQuery<GetOptimizationLateOrdersQuery, GetOptimizationLateOrdersQueryVariables>(GET_OPTIMIZATION_LATE_ORDERS, {
    variables: { optimizationId: optimization && optimization.id },
    fetchPolicy: "network-only",
    skip: !optimization,
  });

  const [tab, setTab] = useState<number>(0);
  const [charts, setCharts] = useState([
    { title: "Number of orders per picking round", id: "NUM_ORDERS_PICKING_ROUND" },
    { title: "Number of order rows per picking round", id: "NUM_ORDERROWS_PICKING_ROUND" },
    { title: "Number of articles per picking round", id: "NUM_ARTICLES_PICKING_ROUND" },
    { title: "Weight per picking round", id: "WEIGHT_PICKING_ROUND" },
    { title: "Volume per picking round", id: "VOLUME_PICKING_ROUND" },
    { title: "Distance per picking round", id: "DISTANCE_PICKING_ROUND" },
    { title: "Time per picking round", id: "TIME_PICKING_ROUND" },
  ]);
  useEffect(() => {
    if (simulation) {
      setCharts([...charts, { title: "Picking rounds per picker", id: "PICKING_ROUNDS_PICKER" }]);
    }
  }, []);

  const [currentChart, setCurrentChart] = useState(0);
  const [resultData, setResultData] = useState<Result>(null);

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref, func) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          modalHeaderRef.current !== event.target &&
          modalHeaderComboRef.current !== event.target &&
          modalHeaderComboRef.current &&
          !modalHeaderComboRef.current.contains(event.target)
        ) {
          func();
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(modalRef, () => closeModal());

  const { data: simulationResultData, loading: simulationResultLoading } = useQuery<
    GetSimulationResultQuery,
    GetSimulationResultQueryVariables
  >(GET_SIMULATION_RESULT, {
    fetchPolicy: "no-cache",
    variables: { simulationId: simulation && simulation.id },
    skip: !simulation,
  });

  const { data: optimizationResultData, loading: optimizationResultLoading } = useQuery<
    GetOptimizationResultQuery,
    GetOptimizationResultQueryVariables
  >(GET_OPTIMIZATION_RESULT, {
    fetchPolicy: "no-cache",
    variables: { optimizationId: optimization && optimization.id },
    skip: !optimization,
  });

  const { data: simulationResultCustomerData, loading: simulationResultCustomerLoading } = useQuery<
    GetSimulationResultCustomerQuery,
    GetSimulationResultCustomerQueryVariables
  >(GET_SIMULATION_RESULT_CUSTOMER, {
    fetchPolicy: "no-cache",
    variables: { simulationId: simulation && simulation.id },
    skip: !simulation,
  });

  const simulationHasCustomerData =
    simulationResultCustomerData &&
    simulationResultCustomerData.simulationResult &&
    simulationResultCustomerData.simulationResult.routes;

  useEffect(() => {
    if (simulationResultData) {
      setResultData(simulationResultData.simulationResult);
    }
    if (optimizationResultData) {
      setResultData(optimizationResultData.optimizationResult);
    }
  }, [simulationResultData, optimizationResultData]);

  const getChart = () => {
    const chart = charts[currentChart];
    const cRoutes = simulationHasCustomerData ? simulationResultCustomerData.simulationResult.routes : null;
    const sRoutes = resultData.routes;

    // Get from optimizationResult
    let cX;
    let cY;
    let oX;
    let oY;
    switch (chart.id) {
      case "NUM_ORDERS_PICKING_ROUND":
        if (simulationHasCustomerData) {
          cX = cRoutes.map((r, i) => i + 1 + "");
          cY = cRoutes.map((r) => r.nOrderIds + "");
        }
        oX = sRoutes.map((r, i) => i + 1 + "");
        oY = sRoutes.map((r) => r.nOrderIds + "");
        return (
          <>
            {simulationHasCustomerData && (
              <div className="w-full px-24 pt-12">
                <div className="font-bold mt-4">Result Customer</div>
                <BarChart data={{ x: cX, y: cY }} label="Number of orders" />
              </div>
            )}
            <div className="w-full px-24 pt-12">
              <div className="font-bold mt-4">Result Optiplan</div>
              <BarChart data={{ x: oX, y: oY }} label="Number of orders" />
            </div>
          </>
        );
      case "NUM_ORDERROWS_PICKING_ROUND":
        if (simulationHasCustomerData) {
          cX = cRoutes.map((r, i) => i + 1 + "");
          cY = cRoutes.map((r) => r.nOrderrowIds + "");
        }

        oX = sRoutes.map((r, i) => i + 1 + "");
        oY = sRoutes.map((r) => r.nOrderrowIds + "");
        return (
          <>
            {simulationHasCustomerData && (
              <div className="w-full px-24 pt-12">
                <div className="font-bold mt-4">Result Customer</div>
                <BarChart data={{ x: cX, y: cY }} label="Number of order rows" />
              </div>
            )}
            <div className="w-full px-24 pt-12">
              <div className="font-bold mt-4">Result Optiplan</div>
              <BarChart data={{ x: oX, y: oY }} label="Number of order rows" />
            </div>
          </>
        );
      case "NUM_ARTICLES_PICKING_ROUND":
        if (simulationHasCustomerData) {
          cX = cRoutes.map((r, i) => i + 1 + "");
          cY = cRoutes.map((r) => r.nArticles + "");
        }

        oX = sRoutes.map((r, i) => i + 1 + "");
        oY = sRoutes.map((r) => r.nArticles + "");
        return (
          <>
            {simulationHasCustomerData && (
              <div className="w-full px-24 pt-12">
                <div className="font-bold mt-4">Result Customer</div>
                <BarChart data={{ x: cX, y: cY }} label="Number of articles" />
              </div>
            )}
            <div className="w-full px-24 pt-12">
              <div className="font-bold mt-4">Result Optiplan</div>
              <BarChart data={{ x: oX, y: oY }} label="Number of articles" />
            </div>
          </>
        );
      case "WEIGHT_PICKING_ROUND":
        if (simulationHasCustomerData) {
          cX = cRoutes.map((r, i) => i + 1 + "");
          cY = cRoutes.map((r) => r.weight + "");
        }

        oX = sRoutes.map((r, i) => i + 1 + "");
        oY = sRoutes.map((r) => r.weight + "");
        return (
          <>
            {simulationHasCustomerData && (
              <div className="w-full px-24 pt-12">
                <div className="font-bold mt-4">Result Customer</div>
                <BarChart data={{ x: cX, y: cY }} label="Weight (kg)" />
              </div>
            )}
            <div className="w-full px-24 pt-12">
              <div className="font-bold mt-4">Result Optiplan</div>
              <BarChart data={{ x: oX, y: oY }} label="Weight (kg)" />
            </div>
          </>
        );
      case "VOLUME_PICKING_ROUND":
        if (simulationHasCustomerData) {
          cX = cRoutes.map((r, i) => i + 1 + "");
          cY = cRoutes.map((r) => r.volume + "");
        }

        oX = sRoutes.map((r, i) => i + 1 + "");
        oY = sRoutes.map((r) => r.volume + "");
        return (
          <>
            {simulationHasCustomerData && (
              <div className="w-full px-24 pt-12">
                <div className="font-bold mt-4">Result Customer</div>
                <BarChart data={{ x: cX, y: cY }} label="Volume (m³)" />
              </div>
            )}
            <div className="w-full px-24 pt-12">
              <div className="font-bold mt-4">Result Optiplan</div>
              <BarChart data={{ x: oX, y: oY }} label="Volume (m³)" />
            </div>
          </>
        );

      case "DISTANCE_PICKING_ROUND":
        if (simulationHasCustomerData) {
          cX = cRoutes.map((r, i) => i + 1 + "");
          cY = cRoutes.map((r) => r.distance + "");
        }

        oX = sRoutes.map((r, i) => i + 1 + "");
        oY = sRoutes.map((r) => r.distance + "");
        return (
          <>
            {simulationHasCustomerData && (
              <div className="w-full px-24 pt-12">
                <div className="font-bold mt-4">Result Customer</div>
                <BarChart data={{ x: cX, y: cY }} label="Distance (m)" />
              </div>
            )}
            <div className="w-full px-24 pt-12">
              <div className="font-bold mt-4">Result Optiplan</div>
              <BarChart data={{ x: oX, y: oY }} label="Distance (m)" />
            </div>
          </>
        );

      case "TIME_PICKING_ROUND":
        sRoutes.sort((a, b) => {
          if (moment(a.start) < moment(b.start)) {
            return -1;
          }
          return 1;
        });
        return (
          <>
            {simulationHasCustomerData && (
              <div className="w-full px-24 pt-12 pb-8" style={{ height: cRoutes.length * 16 + 200 }}>
                <div className="font-bold mt-4">Result Customer</div>
                <LineChart routes={cRoutes} type={"PICKING_ROUNDS_TIME"} />
              </div>
            )}
            <div className="w-full px-24 pt-12 mb-12" style={{ height: sRoutes.length * 16 + 200 }}>
              <div className="font-bold mt-4 pb-8">Result Optiplan</div>
              <LineChart routes={sRoutes} type={"PICKING_ROUNDS_TIME"} />
            </div>
          </>
        );
      case "PICKING_ROUNDS_PICKER":
        const pickerIds = [];
        sRoutes.forEach((r) => {
          if (!pickerIds.includes(r.picker)) {
            pickerIds.push(r.picker);
          }
        });
        return (
          <>
            <div className="w-full px-24 pt-12 pb-8" style={{ height: pickerIds.length * 16 + 500 }}>
              <div className="font-bold mt-4">Result Optiplan</div>
              <LineChart routes={sRoutes} type={"PICKING_ROUNDS_PICKER"} />
            </div>
          </>
        );

      default:
        return <></>;
    }
  };

  console.log("TAB", tab);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex-center modal-container">
      <div className="flex-center header modal-header" ref={modalHeaderRef}>
        KEY FIGURES - {simulation ? simulation.name : optimization.name}
        <div className="clickable" style={{ height: 16 }}>
          <IoMdClose
            style={{ position: "absolute", right: "1vw", fontSize: 30, bottom: 15 }}
            onClick={() => {
              closeModal();
            }}
          />
        </div>
      </div>
      <div
        className="flex-center modal-header"
        ref={modalHeaderComboRef}
        style={{ backgroundColor: getCssVariable("--color-black0"), height: 70 }}>
        <Tabs
          style={{ marginBottom: 40, marginTop: 30 }}
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, newValue) => {
            setTab(newValue);
          }}
          disableRipple={true}>
          <Tab style={{ minWidth: 100 }} disableRipple={true} label="Table" />
          <Tab style={{ minWidth: 100 }} disableRipple={true} label="Charts" />
          {optimization && <Tab style={{ minWidth: 100 }} disableRipple={true} label="Late orders" />}
        </Tabs>
      </div>
      <div className="flex items-center modal-canvas-container flex-col overflow-y-auto py-8" ref={modalRef}>
        {resultData && (
          <>
            {tab === 0 && (
              <>
                <div className="flex justify-around border p-8" style={{ marginBottom: 40, width: "60%" }}>
                  <>
                    <div style={{ width: 100 }}>
                      <DataInput
                        label={"Orders"}
                        disabled={true}
                        initValue={jobDatasetData && jobDatasetData.dataset.orderFile.orders.length + ""}
                      />
                    </div>
                    <div style={{ width: 100 }}>
                      <DataInput
                        label={"OrderRows"}
                        disabled={true}
                        initValue={jobDatasetData && jobDatasetData.dataset.orderRowFile.orderRows.length + ""}
                      />
                    </div>
                    <div style={{ width: 100 }}>
                      <DataInput
                        label={"Items"}
                        disabled={true}
                        initValue={jobDatasetData && jobDatasetData.dataset.itemFile.items.length + ""}
                      />
                    </div>
                  </>
                </div>
                <table className="border" style={{ width: "60%", borderCollapse: "collapse" }}>
                  <thead>
                    <th scope="col" style={{ width: 100, fontSize: 12 }}></th>
                    {simulationHasCustomerData && (
                      <th scope="col" style={{ width: 130, fontSize: 12 }}>
                        Result Customer
                      </th>
                    )}
                    <th scope="col" style={{ width: 50, fontSize: 12 }}>
                      Result Optiplan
                    </th>
                  </thead>
                  <tbody style={{ backgroundColor: "white" }}>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Number of picking rounds</td>
                      {simulationHasCustomerData && (
                        <td>{simulationResultCustomerData.simulationResult.nPickingRounds}</td>
                      )}
                      <td>{resultData.nPickingRounds}</td>
                    </tr>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Median orders per picking round</td>
                      {simulationHasCustomerData && (
                        <td>{simulationResultCustomerData.simulationResult.medianOrdersPerRoute}</td>
                      )}
                      <td>{resultData.medianOrdersPerRoute}</td>
                    </tr>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Median order rows per picking round</td>
                      {simulationHasCustomerData && (
                        <td>{simulationResultCustomerData.simulationResult.medianOrderrowsPerRoute}</td>
                      )}
                      <td>{resultData.medianOrderrowsPerRoute}</td>
                    </tr>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Median articles per picking round</td>
                      {simulationHasCustomerData && (
                        <td>{simulationResultCustomerData.simulationResult.medianArticlesPerRoute}</td>
                      )}
                      <td>{resultData.medianArticlesPerRoute}</td>
                    </tr>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Median time per order (s)</td>
                      {simulationHasCustomerData && (
                        <td>{simulationResultCustomerData.simulationResult.medianTimePerOrder}</td>
                      )}
                      <td>{resultData.medianTimePerOrder}</td>
                    </tr>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Median time per order row (s)</td>
                      {simulationHasCustomerData && (
                        <td>{simulationResultCustomerData.simulationResult.medianTimePerOrderrow}</td>
                      )}
                      <td>{resultData.medianTimePerOrderrow}</td>
                    </tr>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Median time per SKU (s)</td>
                      {simulationHasCustomerData && (
                        <td>{simulationResultCustomerData.simulationResult.medianTimePerSKU}</td>
                      )}
                      <td>{resultData.medianTimePerSKU}</td>
                    </tr>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Median time per pcs (s)</td>
                      {simulationHasCustomerData && (
                        <td>{simulationResultCustomerData.simulationResult.medianTimePerPcs}</td>
                      )}
                      <td>{resultData.medianTimePerPcs}</td>
                    </tr>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Total time for all picking rounds (s)</td>
                      {simulationHasCustomerData && (
                        <td>{simulationResultCustomerData.simulationResult.totalTimeForRoutes}</td>
                      )}
                      <td>{resultData.totalTimeForRoutes}</td>
                    </tr>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Median weight per picking round (kg)</td>
                      {simulationHasCustomerData && (
                        <td>{Math.round(simulationResultCustomerData.simulationResult.medianWeightForRoutes)}</td>
                      )}
                      <td>{Math.round(resultData.medianWeightForRoutes)}</td>
                    </tr>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Median volume per picking round (m³)</td>
                      {simulationHasCustomerData && (
                        <td>{Math.round(simulationResultCustomerData.simulationResult.medianVolumeForRoutes)}</td>
                      )}
                      <td>{Math.round(resultData.medianVolumeForRoutes)}</td>
                    </tr>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Number of late orders</td>
                      {simulationHasCustomerData && (
                        <td>{simulationResultCustomerData.simulationResult.nLateOrders}</td>
                      )}
                      <td>{resultData.nLateOrders}</td>
                    </tr>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Number of late picking rounds</td>
                      {simulationHasCustomerData && (
                        <td>
                          {simulationResultCustomerData.simulationResult.nLateRoutes +
                            " (out of " +
                            simulationResultCustomerData.simulationResult.nPickingRounds +
                            ")"}
                        </td>
                      )}
                      <td>{resultData.nLateRoutes + " (out of " + resultData.nPickingRounds + ")"}</td>
                    </tr>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Total distance for all picking rounds (m)</td>
                      {simulationHasCustomerData && (
                        <td>{simulationResultCustomerData.simulationResult.totalDistanceForRoutes}</td>
                      )}
                      <td>{resultData.totalDistanceForRoutes}</td>
                    </tr>
                    <tr className="table-row" style={{ cursor: "default" }}>
                      <td>Median distance per picking round (m)</td>
                      {simulationHasCustomerData && (
                        <td>{simulationResultCustomerData.simulationResult.medianDistanceForRoutes}</td>
                      )}
                      <td>{resultData.medianDistanceForRoutes}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            {tab === 1 && (
              <>
                <div className="flex w-1/2 justify-around items-center min-h-24">
                  <IoIosArrowBack
                    className="text-3xl cursor-pointer"
                    onClick={() => {
                      setCurrentChart(currentChart === 0 ? charts.length - 1 : currentChart - 1);
                    }}
                  />
                  <div className="text-center font-bold text-xl" style={{ width: 500 }}>
                    {charts[currentChart].title}
                  </div>
                  <IoIosArrowForward
                    className="text-2xl cursor-pointer"
                    onClick={() => {
                      setCurrentChart(currentChart === charts.length - 1 ? 0 : currentChart + 1);
                    }}
                  />
                </div>
                <div className="w-full">{getChart()}</div>
              </>
            )}
            {tab === 2 && (
              <>
                <div className="flex justify-around border p-8" style={{ marginBottom: 40, width: "60%" }}>
                  <>
                    <div style={{ width: 100 }}>
                      <DataInput
                        label={"Late orders"}
                        disabled={true}
                        initValue={resultData && resultData.nLateOrders ? resultData.nLateOrders + "" : "0"}
                      />
                    </div>
                    <div style={{ width: 150 }}>
                      <DataInput
                        label={"Late order rows"}
                        disabled={true}
                        initValue={resultData && resultData.nLateOrderrows ? resultData.nLateOrderrows + "" : "0"}
                      />
                    </div>
                  </>
                </div>
                <table className="border" style={{ width: "60%", borderCollapse: "collapse" }}>
                  <thead>
                    <th scope="col" style={{ width: 50, fontSize: 12 }}>
                      Order
                    </th>
                    <th scope="col" style={{ width: 50, fontSize: 12 }}>
                      Delay (minutes)
                    </th>
                    <th scope="col" style={{ width: 50, fontSize: 12 }}>
                      Number of order rows
                    </th>
                    <th scope="col" style={{ width: 50, fontSize: 12 }}>
                      Route
                    </th>
                  </thead>
                  <tbody style={{ backgroundColor: "white" }}>
                    {optimizationLateOrdersData &&
                      optimizationLateOrdersData.optimizationLateOrders.map((order) => {
                        let orderRoutes = order.routeStops.map((rs) => rs.route.number);
                        // Remove duplicates
                        // @ts-ignore
                        orderRoutes = [...new Set(orderRoutes)];
                        return (
                          <tr className="table-row" style={{ cursor: "default" }}>
                            <td>{order.orgOrderId}</td>
                            <td>
                              {Math.round(
                                order.routeStops.reduce((accumulator, item) => {
                                  return accumulator + item.orderRowWindowPeneltyCost;
                                }, 0) / 60,
                              )}
                            </td>
                            <td>{order.orderRows.length}</td>
                            <td>{orderRoutes.join(", ")}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </>
            )}
          </>
        )}
      </div>
    </motion.div>
  );
}

export default ResultsModal;
