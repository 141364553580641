import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { warehouseVar } from "../../cache";
import DataInput from "../../components/DataInput/DataInput";
import Spinner from "../../components/Spinner/Spinner";
import formatDate from "../../formatDate";
import { GetLayoutDatasetQuery, GetLayoutDatasetQueryVariables } from "../../graphql/graphqlTypes";
import { GetResourceDatasetQuery, GetResourceDatasetQueryVariables } from "../../graphql/graphqlTypes";
import { GetJobDatasetQuery, GetJobDatasetQueryVariables } from "../../graphql/graphqlTypes";
import { GET_LAYOUT_DATASET, GET_RESOURCE_DATASET, GET_JOB_DATASET } from "../../graphql/queries";
import "./DatasetDetails.css";

type Props = {};

function DatasetDetails(props: Props) {
  const {} = props;
  const warehouse = useReactiveVar(warehouseVar);
  let history = useHistory();
  const pathName = useLocation().pathname.replace(/\/$/, "");
  const type = pathName.split("/")[1].split("/")[0];
  let { id } = useParams<any>();

  const [files, setFiles] = useState<{ id: string; name: string }[]>([]);

  const { data: layoutDatasetData, loading: layoutDatasetLoading } = useQuery<
    GetLayoutDatasetQuery,
    GetLayoutDatasetQueryVariables
  >(GET_LAYOUT_DATASET, {
    variables: { datasetId: id },
    skip: type !== "layouts",
    fetchPolicy: "network-only",
  });

  const { data: resourceDatasetData, loading: resourceDatasetLoading } = useQuery<
    GetResourceDatasetQuery,
    GetResourceDatasetQueryVariables
  >(GET_RESOURCE_DATASET, {
    variables: { datasetId: id },
    skip: type !== "resources",
    fetchPolicy: "network-only",
  });

  const { data: jobDatasetData, loading: jobDatasetLoading } = useQuery<
    GetJobDatasetQuery,
    GetJobDatasetQueryVariables
  >(GET_JOB_DATASET, {
    variables: { datasetId: id },
    skip: type !== "jobs",
    fetchPolicy: "network-only",
  });

  console.log("ID", id);

  useEffect(() => {
    if (layoutDatasetData) {
      let files = [];
      if (layoutDatasetData.dataset.cadFile) {
        files = [{ id: layoutDatasetData.dataset.cadFile.id, name: layoutDatasetData.dataset.cadFile.name }];
      } else {
        files = [
          { id: layoutDatasetData.dataset.aisleFile.id, name: layoutDatasetData.dataset.aisleFile.name },
          { id: layoutDatasetData.dataset.blockFile.id, name: layoutDatasetData.dataset.blockFile.name },
          { id: layoutDatasetData.dataset.crossAisleFile.id, name: layoutDatasetData.dataset.crossAisleFile.name },
          { id: layoutDatasetData.dataset.depotFile.id, name: layoutDatasetData.dataset.depotFile.name },
          { id: layoutDatasetData.dataset.rackFile.id, name: layoutDatasetData.dataset.rackFile.name },
          { id: layoutDatasetData.dataset.shelfFile.id, name: layoutDatasetData.dataset.shelfFile.name },
          { id: layoutDatasetData.dataset.zoneFile.id, name: layoutDatasetData.dataset.zoneFile.name },
        ];
        if (layoutDatasetData.dataset.nodeFile) {
          files.push({ id: layoutDatasetData.dataset.nodeFile.id, name: layoutDatasetData.dataset.nodeFile.name });
        }
      }

      setFiles(files);
    }
  }, [layoutDatasetData]);

  useEffect(() => {
    if (resourceDatasetData) {
      setFiles([
        { id: resourceDatasetData.dataset.cartFile.id, name: resourceDatasetData.dataset.cartFile.name },
        { id: resourceDatasetData.dataset.boxFile.id, name: resourceDatasetData.dataset.boxFile.name },
        { id: resourceDatasetData.dataset.truckFile.id, name: resourceDatasetData.dataset.truckFile.name },
        { id: resourceDatasetData.dataset.pickerFile.id, name: resourceDatasetData.dataset.pickerFile.name },
      ]);
    }
  }, [resourceDatasetData]);

  useEffect(() => {
    if (jobDatasetData) {
      setFiles([
        { id: jobDatasetData.dataset.orderFile.id, name: jobDatasetData.dataset.orderFile.name },
        { id: jobDatasetData.dataset.orderRowFile.id, name: jobDatasetData.dataset.orderRowFile.name },
        { id: jobDatasetData.dataset.itemFile.id, name: jobDatasetData.dataset.itemFile.name },
      ]);
    }
  }, [jobDatasetData]);

  const downloadFile = (fileId, fileName) => {
    const token = localStorage.getItem("jwt-warehouse-optimization");
    try {
      axios({
        url: "/download/" + fileId,
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (err) {
      console.log(err);
    }
  };

  const loading = layoutDatasetLoading || resourceDatasetLoading || jobDatasetLoading;
  if (loading) {
    return <Spinner size={100} />;
  }

  const data = layoutDatasetData || resourceDatasetData || jobDatasetData;

  return (
    <>
      {data && data.dataset && (
        <>
          <div style={{ display: "flex", width: "fit-content", alignItems: "center" }}>
            <MdKeyboardArrowLeft
              style={{
                fontSize: 30,
                marginRight: 20,
                marginTop: 10,
              }}
              className="hover"
              onClick={() => {
                if (type === "layouts") {
                  history.push("/layouts");
                } else if (type === "resources") {
                  history.push("/resources");
                } else if (type === "jobs") {
                  history.push("/jobs");
                }
              }}
            />
            <div className="header" style={{ fontSize: 32 }}>
              {data && data.dataset && data.dataset.name}
            </div>
          </div>

          <div className="header" style={{ fontSize: 14, fontWeight: 600, marginTop: 10 }}>
            {data && data.dataset && formatDate(data.dataset.createdAt)}
          </div>
          <div className="flex-center" style={{ marginTop: 40, marginBottom: 40 }}>
            {type === "layouts" && (
              <>
                <DataInput
                  label={"Aisles"}
                  disabled={true}
                  initValue={
                    layoutDatasetData.dataset.cadFile
                      ? layoutDatasetData.dataset.cadFile.aisles.length + ""
                      : layoutDatasetData.dataset.aisleFile.aisles.length + ""
                  }
                />
                <DataInput
                  label={"Blocks"}
                  disabled={true}
                  initValue={
                    layoutDatasetData.dataset.cadFile
                      ? layoutDatasetData.dataset.cadFile.blocks.length + ""
                      : layoutDatasetData.dataset.blockFile.blocks.length + ""
                  }
                />
                <DataInput
                  label={"Crossaisles"}
                  disabled={true}
                  initValue={
                    layoutDatasetData.dataset.cadFile
                      ? layoutDatasetData.dataset.cadFile.crossAisles.length + ""
                      : layoutDatasetData.dataset.crossAisleFile.crossAisles.length + ""
                  }
                />
                <DataInput
                  label={"Depots"}
                  disabled={true}
                  initValue={
                    layoutDatasetData.dataset.cadFile
                      ? layoutDatasetData.dataset.cadFile.depots.length + ""
                      : layoutDatasetData.dataset.depotFile.depots.length + ""
                  }
                />
                {layoutDatasetData.dataset.nodeFile && (
                  <DataInput
                    label={"Nodes"}
                    disabled={true}
                    initValue={
                      layoutDatasetData.dataset.cadFile
                        ? layoutDatasetData.dataset.cadFile.nodes.length + ""
                        : layoutDatasetData.dataset.nodeFile.nodes.length + ""
                    }
                  />
                )}
                <DataInput
                  label={"Racks"}
                  disabled={true}
                  initValue={
                    layoutDatasetData.dataset.cadFile
                      ? layoutDatasetData.dataset.cadFile.racks.length + ""
                      : layoutDatasetData.dataset.rackFile.racks.length + ""
                  }
                />
                <DataInput
                  label={"Shelves"}
                  disabled={true}
                  initValue={
                    layoutDatasetData.dataset.cadFile
                      ? layoutDatasetData.dataset.cadFile.shelves.length + ""
                      : layoutDatasetData.dataset.shelfFile.shelves.length + ""
                  }
                />
                <DataInput
                  label={"Zones"}
                  disabled={true}
                  initValue={
                    layoutDatasetData.dataset.cadFile
                      ? layoutDatasetData.dataset.cadFile.zones.length + ""
                      : layoutDatasetData.dataset.zoneFile.zones.length + ""
                  }
                />
              </>
            )}
            {type === "resources" && (
              <>
                <DataInput
                  label={"Carts"}
                  disabled={true}
                  initValue={resourceDatasetData.dataset.cartFile.carts.length + ""}
                />
                <DataInput
                  label={"Boxes"}
                  disabled={true}
                  initValue={resourceDatasetData.dataset.boxFile.boxes.length + ""}
                />
                <DataInput
                  label={"Trucks"}
                  disabled={true}
                  initValue={resourceDatasetData.dataset.truckFile.trucks.length + ""}
                />
                <DataInput
                  label={"Pickers"}
                  disabled={true}
                  initValue={resourceDatasetData.dataset.pickerFile.pickers.length + ""}
                />
              </>
            )}
            {type === "jobs" && (
              <>
                <DataInput
                  label={"Orders"}
                  disabled={true}
                  initValue={jobDatasetData.dataset.orderFile.orders.length + ""}
                />
                <DataInput
                  label={"OrderRows"}
                  disabled={true}
                  initValue={jobDatasetData.dataset.orderRowFile.orderRows.length + ""}
                />
                <DataInput
                  label={"Items"}
                  disabled={true}
                  initValue={jobDatasetData.dataset.itemFile.items.length + ""}
                />
              </>
            )}
          </div>
          <div className="table-container tableFixHead">
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <th scope="col" style={{ width: "100%" }}>
                  Name
                </th>
                <th scope="col" style={{ width: 50 }}>
                  {/* Mail */}
                </th>
              </thead>
              <tbody style={{ backgroundColor: "white" }}>
                {files.map((row) => {
                  return (
                    <tr
                      className="table-row"
                      onClick={() => {
                        downloadFile(row.id, row.name);
                      }}>
                      <td>{row.name}</td>
                      <td className="action-col" style={{ width: 50 }}>
                        <AiOutlineDownload title="Download file" className={`table-action`} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}

export default DatasetDetails;
