import gql from "graphql-tag";

export const GET_USER = gql`
  query getUser($registerToken: String) {
    user(registerToken: $registerToken) {
      id
      name
      email
      roleId
      permissions
      account {
        id
        name
        logoUrl
      }
      division {
        id
        name
      }
      warehouse {
        id
        name
      }
    }
  }
`;

export const GET_DIVISION_USERS = gql`
  query getDivisionUsers($divisionId: String!) {
    divisionUsers(divisionId: $divisionId) {
      id
      name
      email
      lastLoggedIn
      disabled
      archivedAt
      registrationMailSent
      division {
        id
        name
      }
    }
  }
`;

export const GET_ACCOUNT_USERS = gql`
  query getAccountUsers($accountId: String!) {
    accountUsers(accountId: $accountId) {
      id
      name
      email
      lastLoggedIn
      disabled
      archivedAt
      registrationMailSent
      division {
        id
        name
      }
      warehouse {
        id
        name
      }
    }
  }
`;

export const GET_ACCOUNTS = gql`
  query getAccounts {
    accounts {
      id
      name
      logoUrl
    }
  }
`;

export const GET_ACCOUNT = gql`
  query getAccount($accountId: String!) {
    account(accountId: $accountId) {
      id
      name
      logoUrl
      disabled
      deleteable
    }
  }
`;

export const GET_DIVISIONS = gql`
  query getDivisions($accountId: String) {
    divisions(accountId: $accountId) {
      id
      name
      warehouses {
        id
        name
      }
      divisions {
        id
        name
        warehouses {
          id
          name
        }
        divisions {
          id
          name
          warehouses {
            id
            name
          }
          divisions {
            id
            name
            warehouses {
              id
              name
            }
            divisions {
              id
              name
              warehouses {
                id
                name
              }
              divisions {
                id
                name
                warehouses {
                  id
                  name
                }
                divisions {
                  id
                  name
                  warehouses {
                    id
                    name
                  }
                  divisions {
                    id
                    name
                    warehouses {
                      id
                      name
                    }
                    divisions {
                      id
                      name
                      warehouses {
                        id
                        name
                      }
                      divisions {
                        id
                        name
                        warehouses {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_WAREHOUSES = gql`
  query getWarehouses($accountId: String!) {
    warehouses(accountId: $accountId) {
      id
      name
    }
  }
`;

export const GET_WAREHOUSE_TIME_WINDOW = gql`
  query getWarehouseTimeWindow($warehouseId: String!) {
    warehouse(warehouseId: $warehouseId) {
      id
      name
      startWindow
      endWindow
      onlyWeekdays
      excludedDays
    }
  }
`;

export const GET_WAREHOUSE_WMS = gql`
  query getWarehouseWMS($warehouseId: String!) {
    warehouse(warehouseId: $warehouseId) {
      id
      name
      wms
      shelfFormat
    }
  }
`;

export const GET_WAREHOUSE_DISTANCE_MATRIX = gql`
  query getWarehouseDistanceMatrix($warehouseId: String!) {
    warehouse(warehouseId: $warehouseId) {
      distanceMatrix
    }
  }
`;

export const GET_DIVISION = gql`
  query getDivision($divisionId: String!) {
    division(divisionId: $divisionId) {
      id
      name
      deleteable
      type
    }
  }
`;

export const GET_USER_DIVISION_ROLES = gql`
  query getUserDivisionRoles($divisionId: String!) {
    userDivisionRoles(divisionId: $divisionId) {
      id
      name
      email
      roles {
        id
        name
        permissions {
          id
          name
          category
        }
      }
    }
  }
`;

export const GET_ALL_USER_DIVISION_ROLES = gql`
  query getAllUserDivisionRoles($accountId: String) {
    allUserDivisionRoles(accountId: $accountId) {
      id
      name
      email
      roles {
        id
        name
        permissions {
          id
          name
          category
        }
        divisions {
          id
          name
        }
        warehouses {
          id
          name
        }
      }
    }
  }
`;

export const GET_ROLES = gql`
  query getRoles {
    roles {
      id
      name
      permissions {
        id
        name
        category
      }
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query getPermissions {
    permissions {
      id
      name
      category
      action
    }
  }
`;

export const GET_DATASETS = gql`
  query getDatasets($warehouseId: String!) {
    datasets(warehouseId: $warehouseId) {
      id
      name
      createdAt
      distanceMatrixStatus
      optimizations {
        id
      }
      simulations {
        id
      }
      deletable
      cadFile {
        id
        name
        createdAt
      }
      aisleFile {
        id
        name
        createdAt
      }
      blockFile {
        id
        name
        createdAt
      }
      boxFile {
        id
        name
        createdAt
      }
      cartFile {
        id
        name
        createdAt
      }
      crossAisleFile {
        id
        name
        createdAt
      }
      depotFile {
        id
        name
        createdAt
      }
      nodeFile {
        id
        name
        createdAt
      }
      itemFile {
        id
        name
        createdAt
      }
      orderFile {
        id
        name
        createdAt
      }
      orderRowFile {
        id
        name
        createdAt
      }
      pickerFile {
        id
        name
        createdAt
      }
      rackFile {
        id
        name
        createdAt
      }
      shelfFile {
        id
        name
        createdAt
      }
      truckFile {
        id
        name
        createdAt
      }
      zoneFile {
        id
        name
        createdAt
      }
    }
  }
`;

export const GET_LAYOUT_DATASET = gql`
  query getLayoutDataset($datasetId: String!) {
    dataset(datasetId: $datasetId) {
      id
      name
      createdAt
      cadFile {
        id
        name
        zones {
          id
        }
        blocks {
          id
        }
        aisles {
          id
        }
        racks {
          id
        }
        shelves {
          id
        }
        crossAisles {
          id
        }
        depots {
          id
        }
        nodes {
          id
        }
      }
      aisleFile {
        id
        name
        aisles {
          id
        }
      }
      blockFile {
        id
        name
        blocks {
          id
        }
      }
      crossAisleFile {
        id
        name
        crossAisles {
          id
        }
      }
      depotFile {
        id
        name
        depots {
          id
        }
      }
      nodeFile {
        id
        name
        nodes {
          id
        }
      }
      rackFile {
        id
        name
        racks {
          id
        }
      }
      shelfFile {
        id
        name
        shelves {
          id
        }
      }
      zoneFile {
        id
        name
        zones {
          id
        }
      }
    }
  }
`;

export const GET_RESOURCE_DATASET = gql`
  query getResourceDataset($datasetId: String!) {
    dataset(datasetId: $datasetId) {
      id
      name
      createdAt
      cartFile {
        id
        name
        carts {
          id
        }
      }
      boxFile {
        id
        name
        boxes {
          id
        }
      }
      truckFile {
        id
        name
        trucks {
          id
        }
      }
      pickerFile {
        id
        name
        pickers {
          id
        }
      }
    }
  }
`;

export const GET_JOB_DATASET = gql`
  query getJobDataset($datasetId: String!) {
    dataset(datasetId: $datasetId) {
      id
      name
      createdAt
      orderFile {
        id
        name
        orders {
          id
        }
      }
      orderRowFile {
        id
        name
        orderRows {
          id
        }
      }
      itemFile {
        id
        name
        items {
          id
        }
      }
    }
  }
`;

export const GET_FILES = gql`
  query getFiles($warehouseId: String!, $type: String!) {
    files(warehouseId: $warehouseId, type: $type) {
      id
      name
      createdAt
    }
  }
`;

export const GET_OPTIMIZATIONS = gql`
  query getOptimizations($warehouseId: String!) {
    optimizations(warehouseId: $warehouseId) {
      id
      name
      createdAt
      startedAt
      estimatedFinishedAt
      finishedAt
      types
      status
      dataset {
        id
        name
      }
    }
  }
`;

export const GET_OPTIMIZATION = gql`
  query getOptimization($optimizationId: String!) {
    optimization(optimizationId: $optimizationId) {
      id
      name
      createdAt
      startedAt
      estimatedFinishedAt
      finishedAt
      dataset {
        id
        name
      }
      optimizationConfig {
        pickingTime
        loadOnloadTime
        maxRouteTime
        velocity
        verticalVelocity
        executionTime
        oneOrderMultipleBoxes
        oneRouteOneOrder
        preProcessing
        postProcessing
        volumeOverloadPriority
        weightOverloadPriority
        clusterOrderSize
        clusterOrderrowSize
      }
      types
      status
      subGroups {
        id
        name
        startWindow
        endWindow
        status
        orders {
          id
        }
        solution {
          id
          totalTime
          pickingRounds {
            id
            number
            nRoutes
            totalTime
            routes {
              id
              number
              orderQuatity
              orderRowQuantity
              startTime
              deliveryTime
              finishTime
              totalTravelTime
              maxBoxOverloadPeneltyCost
              maxBoxVolumeOverloadPeneltyCost
              maxBoxWeightOverloadPeneltyCost
              maxWindowPeneltyCost
              totalBoxOverloadPeneltyCost
              totalBoxVolumeOverloadPeneltyCost
              totalBoxWeightOverloadPeneltyCost
              totalWindowPeneltyCost
              routeStops {
                id
                arrivalTime
                pickingTime
                deliveryTime
                startWindow
                endWindow
                orderRow {
                  orgOrderRowId
                }
                shelf {
                  orgShelfId
                }
                item {
                  orgItemId
                }
                order {
                  orgOrderId
                }
                orderRowWindowPeneltyCost
              }
            }
          }
          routes {
            id
            number
            orderQuatity
            orderRowQuantity
            startTime
            deliveryTime
            finishTime
            totalTravelTime
            maxBoxOverloadPeneltyCost
            maxBoxVolumeOverloadPeneltyCost
            maxBoxWeightOverloadPeneltyCost
            maxWindowPeneltyCost
            totalBoxOverloadPeneltyCost
            totalBoxVolumeOverloadPeneltyCost
            totalBoxWeightOverloadPeneltyCost
            totalWindowPeneltyCost
            routeStops {
              id
              arrivalTime
              pickingTime
              deliveryTime
              startWindow
              endWindow
              orderRow {
                orgOrderRowId
              }
              shelf {
                orgShelfId
              }
              item {
                orgItemId
              }
              order {
                orgOrderId
              }
              orderRowWindowPeneltyCost
            }
          }
        }
      }
    }
  }
`;

export const GET_AISLES = gql`
  query getAisles($datasetId: String!) {
    aisles(datasetId: $datasetId) {
      id
      orgAisleId
      type
      frontEndX
      frontEndY
      tailEndX
      tailEndY
      direction
      position
    }
  }
`;

export const GET_SHELVES = gql`
  query getShelves($datasetId: String!) {
    shelves(datasetId: $datasetId) {
      id
      orgShelfId
      xCoor
      yCoor
    }
  }
`;

export const GET_DEPOTS = gql`
  query getDepots($datasetId: String!) {
    depots(datasetId: $datasetId) {
      id
      name
      depotId
      orgDepotId
      xCoor
      yCoor
    }
  }
`;

export const GET_CROSSAISLES = gql`
  query getCrossaisles($datasetId: String!) {
    crossaisles(datasetId: $datasetId) {
      id
      firstId
      secondId
      firstType
      secondType
      generated
    }
  }
`;

export const GET_NODES = gql`
  query getNodes($datasetId: String!) {
    nodes(datasetId: $datasetId) {
      id
      nodeId
      orgNodeId
      xCoor
      yCoor
    }
  }
`;

export const GET_SIMULATIONS = gql`
  query getSimulations($warehouseId: String!) {
    simulations(warehouseId: $warehouseId) {
      id
      name
      createdAt
      status
      datasetId
      currentTime
      simulationConfig {
        name
        datasetId
        simulationDate
        warehouseOpening
        warehouseClosing
        optimizationFrequencyInterval
        reoptimizeOrders
        bufferPercentage
        fixedBufferTime
        maxBufferTime
        optimizationTimeToFinish
        shifts {
          start
          end
          nPickers
        }
        optimizationConfig {
          pickingTime
          loadOnloadTime
          maxRouteTime
          velocity
          verticalVelocity
          executionTime
          oneOrderMultipleBoxes
          oneRouteOneOrder
          preProcessing
          postProcessing
          volumeOverloadPriority
          weightOverloadPriority
          clusterOrderSize
          clusterOrderrowSize
        }
      }
    }
  }
`;

export const GET_SIMULATION_RESULT_CUSTOMER = gql`
  query getSimulationResultCustomer($simulationId: String!) {
    simulationResult(simulationId: $simulationId, customer: true) {
      id
      medianVolumeForRoutes
      medianWeightForRoutes
      totalTimeForRoutes
      medianTimePerPcs
      medianTimePerSKU
      medianTimePerOrderrow
      medianTimePerOrder
      medianArticlesPerRoute
      medianOrderrowsPerRoute
      medianOrdersPerRoute
      nPickingRounds
      nLateOrders
      nLateRoutes
      totalDistanceForRoutes
      medianDistanceForRoutes
      routes {
        id
        nOrderIds
        nOrderrowIds
        nArticles
        weight
        volume
        distance
        picker
        start
        finish
      }
    }
  }
`;

export const GET_SIMULATION_RESULT = gql`
  query getSimulationResult($simulationId: String!) {
    simulationResult(simulationId: $simulationId, customer: false) {
      id
      medianVolumeForRoutes
      medianWeightForRoutes
      totalTimeForRoutes
      medianTimePerPcs
      medianTimePerSKU
      medianTimePerOrderrow
      medianTimePerOrder
      medianArticlesPerRoute
      medianOrderrowsPerRoute
      medianOrdersPerRoute
      nPickingRounds
      nLateOrders
      nLateRoutes
      totalDistanceForRoutes
      medianDistanceForRoutes
      routes {
        id
        nOrderIds
        nOrderrowIds
        nArticles
        weight
        volume
        distance
        picker
        start
        finish
      }
    }
  }
`;

export const GET_OPTIMIZATION_RESULT = gql`
  query getOptimizationResult($optimizationId: String!) {
    optimizationResult(optimizationId: $optimizationId) {
      id
      medianVolumeForRoutes
      medianWeightForRoutes
      totalTimeForRoutes
      medianTimePerPcs
      medianTimePerSKU
      medianTimePerOrderrow
      medianTimePerOrder
      medianArticlesPerRoute
      medianOrderrowsPerRoute
      medianOrdersPerRoute
      nPickingRounds
      nLateOrders
      lateOrderIds
      nLateOrderrows
      nLateRoutes
      totalDistanceForRoutes
      medianDistanceForRoutes
      routes {
        id
        nOrderIds
        nOrderrowIds
        nArticles
        weight
        volume
        distance
        picker
        start
        finish
      }
    }
  }
`;

export const GET_OPTIMIZATION_LATE_ORDERS = gql`
  query getOptimizationLateOrders($optimizationId: String!) {
    optimizationLateOrders(optimizationId: $optimizationId) {
      orgOrderId
      orderRows {
        id
      }
      routeStops {
        orderRowWindowPeneltyCost
        route {
          number
        }
      }
    }
  }
`;
