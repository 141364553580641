import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./Dropzone.css";
import axios from "axios";
import { BiUpload } from "react-icons/bi";

type Props = {
  setNewFiles: Function;
  setFileRejections: Function;
};

function Dropzone(props: Props) {
  const { setNewFiles, setFileRejections } = props;
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    if (fileRejections.length) {
      setFileRejections(fileRejections);
    }
    if (!acceptedFiles.length) {
      return;
    }
    setNewFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    accept: "text/csv, text/plain, application/vnd.ms-excel, application/json",
  });

  const dropArea = () => {
    return isDragActive ? (
      <div className="drop-area-container">
        <div className="drop-area active">
          <BiUpload style={{ fontSize: 30 }} />
          <span style={{ fontSize: 16, textAlign: "center" }}>Click to upload or drag new files here</span>
          <span style={{ fontSize: 12, textAlign: "center" }}>.csv, .txt and .json files supported</span>
        </div>
      </div>
    ) : (
      <div className="drop-area-container">
        <div className="drop-area">
          <BiUpload style={{ fontSize: 30 }} />
          <span style={{ fontSize: 16, textAlign: "center" }}>Click to upload or drag new files here</span>
          <span style={{ fontSize: 12, textAlign: "center" }}>.csv, .txt and .json files supported</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div {...getRootProps()} tabIndex={null} style={{ width: "90%" }}>
        <input {...getInputProps()} />
        {dropArea()}
      </div>
    </>
  );
}

export default Dropzone;
