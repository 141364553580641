import moment from "moment";
export default function formatDate(d: Date | string, includeTime?: boolean) {
  let date;
  if (typeof d === "string" && /^-?\d+$/.test(d)) {
    // timestamp
    d = new Date(parseInt(d));
  }
  date = moment(d);
  const today = moment(new Date());
  const yesterday = moment(new Date()).subtract(1, "day");

  if (date.format("YYYY-MM-DD") === today.format("YYYY-MM-DD")) {
    return "Today • " + date.format("HH:mm");
  } else if (date.format("YYYY-MM-DD") === yesterday.format("YYYY-MM-DD")) {
    return "Yesterday • " + date.format("HH:mm");
  } else {
    if (includeTime) {
      return date.format("HH:mm D MMM YYYY");
    } else {
      return date.format("D MMM YYYY");
    }
  }
}
