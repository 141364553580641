import React, { useEffect, useRef, useState } from "react";
import { addNodeUnderParent } from "./tree-data-utils";
import { IoMdAdd } from "react-icons/io";
import SortableTree from "react-sortable-tree";
import "./SortableTree.css";
import { useMutation, useQuery } from "@apollo/client";
import { GET_DIVISIONS, GET_USER } from "../../graphql/queries";
import { CgListTree } from "react-icons/cg";
import { UPDATE_DIVISION } from "../../graphql/mutations";
import {
  GetDivisionQuery,
  GetDivisionsQuery,
  GetDivisionsQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  UpdateDivisionMutation,
  UpdateDivisionMutationVariables,
} from "../../graphql/graphqlTypes";

type Props = {
  setSelectedCallback: Function;
  accountId: string;
  selectedId: string;
  selectedType: GetDivisionQuery["division"]["type"];
};

export default function Sortable(props: Props) {
  const { setSelectedCallback, accountId, selectedId, selectedType } = props;

  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });

  const [treeData, setTreeData] = useState<any>([]);
  const [selectedNodeId, setSelectedNodeId] = useState<any>(0);
  const [editingNewDivision, setEditingNewDivision] = useState(false);
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  const { data: divisionsData } = useQuery<GetDivisionsQuery, GetDivisionsQueryVariables>(GET_DIVISIONS, {
    fetchPolicy: "network-only",
    variables: { accountId: accountId },
    skip: !accountId,
  });

  const [updateDivision] = useMutation<UpdateDivisionMutation, UpdateDivisionMutationVariables>(UPDATE_DIVISION);

  const formatTreeData = (data) => {
    // Format treeData: "divisions and warehouses merged into -> children" and "name -> title for each warehouse and division"
    // By recursively traversing divisions and merging "divisions" and "warehouses"

    const recursive = (division) => {
      let divisions = [];
      division.divisions.forEach((division) => {
        divisions.push(recursive(division));
      });
      let warehouses = division.warehouses.map((w) => {
        return { ...w, title: w.name };
      });
      return { ...division, title: division.name, children: [...divisions, ...warehouses] };
    };

    let formattedTreeData = [];
    data.divisions.forEach((division) => {
      formattedTreeData.push(recursive(division));
    });

    // Expand nodes after refetch
    let stringified = JSON.stringify(formattedTreeData);
    expandedNodes.forEach((expandedNode) => {
      stringified = stringified.split(`"id":"${expandedNode}"`).join(`"id":"${expandedNode}","expanded":true`);
    });

    let newTreeData = JSON.parse(stringified);
    return newTreeData;
  };

  useEffect(() => {
    if (divisionsData) {
      const formatted = formatTreeData(divisionsData);
      setTreeData(formatted);
      setEditingNewDivision(false);
    }
  }, [divisionsData]);

  useEffect(() => {
    if (accountId) {
      // Switched account -> collapse & deselect
      setExpandedNodes([]);
      setSelectedNodeId(0);
    } else {
      // New account being created -> Hide tree
      setTreeData([]);
    }
  }, [accountId]);

  useEffect(() => {
    if (selectedId) {
      // New division created OR division updated -> need to reselect the division
      setSelectedNodeId(selectedId);
    } else {
      // Division removed -> deselect
      setSelectedNodeId(0);
    }
  }, [selectedId]);

  const nodeClicked = (event: any, rowInfo: any) => {
    if (event.target.className.includes("collapseButton") || event.target.className.includes("expandButton")) {
      if (event.target.className.includes("collapseButton")) {
        let tmp = expandedNodes;
        tmp.splice(expandedNodes.indexOf(rowInfo.node.id), 1);
        setExpandedNodes(tmp);
      } else {
        setExpandedNodes([...expandedNodes, rowInfo.node.id]);
      }
    } else {
      if (
        (selectedNodeId === "new-division" || selectedNodeId === "new-warehouse") &&
        (rowInfo.node.id !== "new-division" || selectedNodeId === "new-warehouse")
      ) {
        // Remove temp new division node (cancel creation of division)
        const formatted = formatTreeData(divisionsData);
        setTreeData(formatted);
        setEditingNewDivision(false);
      }
      setSelectedNodeId(rowInfo.node.id);
      setSelectedCallback({ type: "division", id: rowInfo.node.id });
    }
  };

  useEffect(() => {
    if (expandedNodes) {
      // Scroll right when expanding nodes
      if (document.getElementById("virualized-list") && document.getElementById("virualized-list").children[0]) {
        document.getElementById("virualized-list").children[0].scrollLeft =
          document.getElementById("virualized-list").children[0].scrollWidth;
      }
    }
  }, [expandedNodes]);

  const getNodeKey = ({ node }: any) => node.id;

  const canDrop = ({ node, nextParent, prevPath, nextPath }: any) => {
    if (nextParent === null) {
      return false;
    }

    if (node.isTwin && nextParent && nextParent.isTwin) {
      return false;
    }

    if (editingNewDivision) {
      return false;
    }

    if (userData && !userData.user.permissions.includes("EditDivisions")) {
      return false;
    }

    if (nextParent.__typetitle === "Warehouse") {
      // Cannot set warehouse or division as child to a warehouse
      return false;
    }

    return true;
  };

  if (!userData) {
    return <></>;
  }

  const newDivision = (isWarehouse) => {
    if (!accountId || editingNewDivision) {
      return;
    }
    if (selectedType !== "DIVISION") {
      // Division & Warehouse can only be created as child to a division
      return;
    }
    const tempId = isWarehouse ? "new-warehouse" : "new-division";
    if (selectedNodeId && selectedNodeId !== "new-division" && selectedNodeId !== "new-warehouse") {
      const newTreeData = addNodeUnderParent({
        //@ts-ignore
        treeData: treeData,
        parentKey: selectedNodeId,
        expandParent: true,
        getNodeKey,
        newNode: {
          title: "",
          id: tempId,
        },
        addAsFirstChild: false,
        //@ts-ignore
      }).treeData;
      setTreeData(newTreeData);
    } else {
      setTreeData(
        addNodeUnderParent({
          //@ts-ignore
          treeData: treeData,
          parentKey: treeData[0].id,
          expandParent: true,
          getNodeKey,
          newNode: {
            title: "",
            id: tempId,
          },
          addAsFirstChild: false,
          //@ts-ignore
        }).treeData,
      );
    }
    setEditingNewDivision(true);
    setSelectedCallback({
      type: "division",
      id: tempId,
      parent: selectedNodeId ? selectedNodeId : treeData[0].id,
    });
    setSelectedNodeId(tempId);
    // To expand parent when new node is created
    setExpandedNodes([...expandedNodes, selectedNodeId ? selectedNodeId : treeData[0].id]);
  };

  return (
    <>
      <div>
        {userData && userData.user.permissions.includes("CreateDivisions") && (
          <div className="flex-center">
            <div
              className={`wide-button flex-center register ${
                (!accountId || selectedType !== "DIVISION") && "disabled"
              }`}
              style={{
                marginRight: 10,
                opacity: !accountId || editingNewDivision || selectedType !== "DIVISION" ? 0.5 : 1,
                width: "100%",
                cursor: !accountId || editingNewDivision || selectedType !== "DIVISION" ? "default" : "pointer",
              }}
              onClick={() => {
                newDivision(false);
              }}>
              <div className="plus-icon-wrapper" style={{ position: "static" }}>
                <IoMdAdd />
              </div>
              <span style={{ marginBottom: 4 }}>location</span>
            </div>
            <div
              className={`wide-button flex-center register ${
                (!accountId || selectedType !== "DIVISION") && "disabled"
              }`}
              style={{
                marginRight: 0,
                width: "100%",
                opacity: !accountId || editingNewDivision || selectedType !== "DIVISION" ? 0.5 : 1,
                cursor: !accountId || editingNewDivision || selectedType !== "DIVISION" ? "default" : "pointer",
              }}
              onClick={() => {
                newDivision(true);
              }}>
              <div className="plus-icon-wrapper" style={{ position: "static" }}>
                <IoMdAdd />
              </div>
              <span style={{ marginBottom: 4 }}>warehouse</span>
            </div>
          </div>
        )}

        {userData && userData.user.permissions.includes("EditUsers") && (
          <div
            className={`wide-button register overview ${!accountId && "disabled"}`}
            style={{
              opacity: !accountId ? 0.5 : 1,
              cursor: !accountId ? "default" : "pointer",
              margin: "10px 0px 20px 0px",
            }}
            onClick={() => {
              if (!accountId) {
                return;
              }
              setSelectedNodeId(0);
              setSelectedCallback({ type: "overview" });
            }}>
            <CgListTree style={{ marginRight: 10 }} />
            <div style={{ marginBottom: 5 }}>Overview</div>
          </div>
        )}
      </div>

      <div style={{ height: "calc(100vh - 460px)", width: "100%" }}>
        <div style={{ height: "100%", width: "100%" }}>
          <SortableTree
            treeData={treeData}
            getNodeKey={({ node }) => node.id}
            onChange={(treeData) => {
              setTreeData(treeData);
            }}
            reactVirtualizedListProps={{
              id: "virualized-list",
            }}
            canDrag={userData && userData.user.permissions.includes("EditDivisions")}
            canDrop={canDrop}
            onMoveNode={(info) => {
              updateDivision({
                variables: {
                  divisionId: info.node.id,
                  parentDivisionId: info.nextParentNode.id,
                },
              }).catch(() => {
                const formatted = formatTreeData(divisionsData);
                setTreeData(formatted);
              });
            }}
            generateNodeProps={(rowInfo) => {
              let nodeProps = {
                onClick: (event: any) => nodeClicked(event, rowInfo),
              };
              if (selectedNodeId === rowInfo.node.id) {
                //@ts-ignore
                nodeProps.className = "selected-node";
              }
              return nodeProps;
            }}
          />
        </div>
      </div>
    </>
  );
}
