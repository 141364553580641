import React, { useState, useEffect } from "react";
import "./App/App.css";
import Navbar from "../components/Navbar/Navbar";
import Home from "./Home/Home";
import logo from "../logo.png";
import { ReactComponent as Icon } from "../icon.svg";
import { getCssVariable } from "../utils";
import Settings from "./Settings/Settings";
import SubNavBar from "../components/SubNavBar/SubNavBar";
import { GetUserQuery } from "../graphql/graphqlTypes";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_USER } from "../graphql/queries";
import { AnimatePresence, motion } from "framer-motion";
import Simulation from "./Simulation/Simulation";
import { showConfirmDialogVar, showToastVar } from "../cache";
import Toast from "../components/Toast/Toast";
import ConfirmDialog from "../components/ConfirmDialog/ConfirmDialog";

function App(props) {
  const path = props.location.pathname;
  const { data: userData } = useQuery<GetUserQuery>(GET_USER, {
    fetchPolicy: "network-only",
  });
  const user = userData?.user;
  const showToast = useReactiveVar(showToastVar);
  const showConfirmDialog = useReactiveVar(showConfirmDialogVar);
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div className="App">
        <AnimatePresence exitBeforeEnter>
          {showConfirmDialog && <ConfirmDialog {...showConfirmDialog} />}
        </AnimatePresence>
        {showToast && <Toast {...showToast} />}
        <Navbar />

        {path !== "/admin" && !path.includes("/simulation") && user && user.permissions.includes("ViewWarehouses") && (
          <SubNavBar />
        )}
        {path !== "/admin" && !path.includes("/simulation") && user && user.permissions.includes("ViewWarehouses") && (
          <Home />
        )}
        {path === "/admin" && user && user.permissions.includes("ViewDivisions") && <Settings />}
        {path.includes("simulation") && user && user.permissions.includes("ViewSimulations") && <Simulation />}
      </div>
    </motion.div>
  );
}

export default App;
