import { useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import DatasetTable from "../DatasetTable/DatasetTable";
import { GET_DATASETS, GET_OPTIMIZATIONS } from "../../graphql/queries";
import "./Home.css";
import { warehouseVar, showConfirmDialogVar, showToastVar } from "../../cache";
import DatasetUpload from "../DatasetUpload/DatasetUpload";
import OptimizationTable from "../OptimizationTable/OptimizationTable";
import OptimizationCreate from "../OptimizationCreate/OptimizationCreate";
import DatasetDetails from "../DatasetDetails/DatasetDetails";
import OptimizationDetails from "../OptimizationDetails/OptimizationDetails";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import Toast from "../../components/Toast/Toast";
import { useHistory, useLocation } from "react-router-dom";
import {
  GetDatasetsQuery,
  GetDatasetsQueryVariables,
  GetOptimizationsQuery,
  GetOptimizationsQueryVariables,
} from "../../graphql/graphqlTypes";
import { AnimatePresence, motion } from "framer-motion";

function Home() {
  const warehouse = useReactiveVar(warehouseVar);

  const pathName = useLocation().pathname.replace(/\/$/, "");
  let history = useHistory();

  const [polling, setPolling] = useState(false);

  console.log(pathName);

  const { data: datasetsData, refetch: refetchDatasetsData } = useQuery<GetDatasetsQuery, GetDatasetsQueryVariables>(
    GET_DATASETS,
    {
      // fetchPolicy: "network-only",
      skip: pathName === "/optimizations" || !warehouse,
      variables: { warehouseId: warehouse && warehouse.id },
    },
  );
  const { data: optimizationsData, refetch: refetchOptimizations } = useQuery<
    GetOptimizationsQuery,
    GetOptimizationsQueryVariables
  >(GET_OPTIMIZATIONS, {
    // fetchPolicy: "network-only",
    skip: pathName !== "/optimizations" || !warehouse,
    variables: { warehouseId: warehouse && warehouse.id },
    pollInterval: polling ? 5000 : 0,
  });

  const optimizationTable = pathName === "/optimizations";
  const optimizationCreate = pathName === "/optimizations/new";
  const datasetTable = pathName === "/layouts" || pathName === "/resources" || pathName === "/jobs";
  const datasetUpload = pathName === "/import";
  const datasetDetails =
    pathName.includes("/layouts/") || pathName.includes("/resources/") || pathName.includes("/jobs/");
  const optimizationDetails = !optimizationCreate && pathName.includes("/optimizations/");

  useEffect(() => {
    // Start polling if build is in progress
    if (optimizationsData) {
      const found = optimizationsData.optimizations.find((o) => {
        if (o.status === "IN_PROGRESS") {
          return true;
        }
      });
      if (found) {
        setPolling(true);
      } else {
        setPolling(false);
      }
    }
  }, [optimizationsData]);

  return (
    <>
      <div className="home-container-wrapper">
        <div className={`edit-container ${optimizationTable && "optimization"}`}>
          <div className={`edit-container-inner ${optimizationTable && "optimization"}`}>
            {datasetTable && (
              <DatasetTable data={datasetsData && datasetsData.datasets} refetch={refetchDatasetsData} />
            )}
            {optimizationTable && (
              <>
                <OptimizationTable
                  data={
                    optimizationTable &&
                    optimizationsData &&
                    optimizationsData.optimizations.filter((o) => o.status === "NOT_STARTED" || o.status === "ERROR")
                  }
                  refetch={refetchOptimizations}
                  type={"NOT_STARTED"}
                />

                <OptimizationTable
                  data={
                    optimizationTable &&
                    optimizationsData &&
                    optimizationsData.optimizations.filter((o) => o.status === "IN_PROGRESS")
                  }
                  refetch={refetchOptimizations}
                  type={"IN_PROGRESS"}
                />

                <OptimizationTable
                  data={
                    optimizationTable &&
                    optimizationsData &&
                    optimizationsData.optimizations.filter((o) => o.status === "FINISHED")
                  }
                  refetch={refetchOptimizations}
                  type={"FINISHED"}
                />
              </>
            )}

            {datasetUpload && <DatasetUpload />}
            {optimizationCreate && <OptimizationCreate />}
            {datasetDetails && <DatasetDetails />}
            {optimizationDetails && <OptimizationDetails />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
