import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  Checkbox,
  createStyles,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  AiFillBug,
  AiOutlineBorderOuter,
  AiOutlineBarChart,
  AiOutlineDownload,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { BiCheckCircle, BiError, BiPlusCircle, BiCheck } from "react-icons/bi";
import { IoMdTrash } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { showConfirmDialogVar, showToastVar, warehouseVar } from "../../cache";
import Spinner from "../../components/Spinner/Spinner";
import formatDate from "../../formatDate";
import {
  DeleteSimulationMutation,
  DeleteSimulationMutationVariables,
  GetDatasetsQuery,
  GetDatasetsQueryVariables,
  GetResourceDatasetQuery,
  GetResourceDatasetQueryVariables,
  GetSimulationsQuery,
  GetSimulationsQueryVariables,
  StartSimulationMutation,
  StartSimulationMutationVariables,
  StopSimulationMutation,
  StopSimulationMutationVariables,
} from "../../graphql/graphqlTypes";
import { DELETE_SIMULATION, START_SIMULATION, STOP_SIMULATION } from "../../graphql/mutations";
import { GET_DATASETS, GET_RESOURCE_DATASET, GET_SIMULATIONS } from "../../graphql/queries";
import { getLocalstorageValue } from "../../utils";
import "./Simulation.css";
import Papa from "papaparse";
import { BiUpload } from "react-icons/bi";
import { AnimatePresence } from "framer-motion";
import ResultsModal from "../../components/ResultsModal/ResultsModal";
import { IoSettingsOutline } from "react-icons/io5";
import OptimizationConfig from "../../components/OptimizationConfig/OptimizationConfig";
import { FaStopCircle } from "react-icons/fa";

function Simulation() {
  const warehouse = getLocalstorageValue("filterWarehouse");
  const [polling, setPolling] = useState(false);
  const { data: datasetsData, refetch: refetchDatasetsData } = useQuery<GetDatasetsQuery, GetDatasetsQueryVariables>(
    GET_DATASETS,
    {
      skip: !warehouse,
      variables: { warehouseId: warehouse && warehouse.id },
      fetchPolicy: "network-only",
    },
  );
  const { data: simulationsData, refetch: refetchSimulations } = useQuery<
    GetSimulationsQuery,
    GetSimulationsQueryVariables
  >(GET_SIMULATIONS, {
    skip: !warehouse,
    variables: { warehouseId: warehouse && warehouse.id },
    fetchPolicy: "network-only",
    pollInterval: polling ? 5000 : 0,
  });

  const [startSimulation] = useMutation<StartSimulationMutation, StartSimulationMutationVariables>(START_SIMULATION);
  const [deleteSimulation] = useMutation<DeleteSimulationMutation, DeleteSimulationMutationVariables>(
    DELETE_SIMULATION,
  );
  const [stopSimulation] = useMutation<StopSimulationMutation, StopSimulationMutationVariables>(STOP_SIMULATION);

  useEffect(() => {
    // Start polling if build is in progress
    if (simulationsData) {
      const simulationInProgress = simulationsData.simulations.find((s) => {
        if (s.status === "IN_PROGRESS") {
          return true;
        }
      });
      if (simulationInProgress) {
        setPolling(true);
      } else {
        setPolling(false);
      }
    }
  }, [simulationsData]);

  const [name, setName] = useState(null);
  const [comboDataset, setComboDataset] = useState(null);
  const [simulationDate, setSimulationDate] = useState(moment().format("YYYY-MM-DD"));
  const [warehouseOpening, setWarehouseOpening] = useState("07:00");
  const [warehouseClosing, setWarehouseClosing] = useState("23:00");
  const [optimizationFrequencyInterval, setOptimizationFrequencyInterval] = useState(15);
  const [reoptimizeOrders, setReoptimzeOrders] = useState(true);
  const [useFixedBuffer, setUseFixedBuffer] = useState(false);
  const [bufferPercentage, setBufferPercentage] = useState(40);
  const [fixedBufferTime, setFixedBufferTime] = useState(20);
  const [maxBufferTime, setMaxBufferTime] = useState(15);
  const [optimizationTimeToFinish, setOptimizationTimeToFinish] = useState(5);
  const [shifts, setShifts] = useState<{ start?: string; end: string; nPickers: number }[]>([
    { start: "07:00", end: "23:00", nPickers: 1 },
  ]);
  const [showResults, setShowResults] = useState(null);
  const [showOptimizationConfig, setShowOptimizationConfig] = useState(false);
  const [optimizationConfig, setOptimizationConfig] = useState(null);

  const [file, setFile] = useState(null);

  const { data: resourceDatasetData, loading: resourceDatasetLoading } = useQuery<
    GetResourceDatasetQuery,
    GetResourceDatasetQueryVariables
  >(GET_RESOURCE_DATASET, {
    variables: { datasetId: comboDataset ? comboDataset.id : null },
    skip: !comboDataset,
    fetchPolicy: "network-only",
  });

  let ready = false;
  if (
    name &&
    name !== " " &&
    comboDataset &&
    simulationDate &&
    warehouseOpening &&
    warehouseClosing &&
    optimizationFrequencyInterval &&
    ((bufferPercentage && maxBufferTime && !useFixedBuffer) || (useFixedBuffer && fixedBufferTime)) &&
    optimizationTimeToFinish
  ) {
    ready = true;
  }

  const setConfigStateFromSimulation = (simulationId) => {
    const found = simulationsData.simulations.find((simulation) => {
      return simulation.id === simulationId;
    });
    const foundDataset = datasetsData.datasets.find((dataset) => {
      return dataset.id === found.simulationConfig.datasetId;
    });
    setName(found.simulationConfig.name);
    setComboDataset(foundDataset);
    setSimulationDate(found.simulationConfig.simulationDate);
    setWarehouseOpening(found.simulationConfig.warehouseOpening);
    setWarehouseClosing(found.simulationConfig.warehouseClosing);
    setOptimizationFrequencyInterval(found.simulationConfig.optimizationFrequencyInterval);
    setReoptimzeOrders(found.simulationConfig.reoptimizeOrders);
    setUseFixedBuffer(found.simulationConfig.bufferPercentage ? false : true);
    setBufferPercentage(found.simulationConfig.bufferPercentage);
    setFixedBufferTime(found.simulationConfig.fixedBufferTime);
    setMaxBufferTime(found.simulationConfig.maxBufferTime);
    setOptimizationTimeToFinish(found.simulationConfig.optimizationTimeToFinish);
    setShifts(found.simulationConfig.shifts);
    setOptimizationConfig(found.simulationConfig.optimizationConfig);
  };

  const validateFileHeaders = async () => {
    await new Promise((resolve, reject) => {
      Papa.parse(file, {
        preview: 1,
        delimiter: "", // Auto
        delimitersToGuess: [",", "\t", ";"],
        complete: (results, file) => {
          let headers: any = results.data[0];
          headers = headers.map((h) => h?.toLowerCase().replace("-", "_").split(" ").join(""));
          const pickingListHeaders = [
            "sort_id",
            "order_number",
            "sku_id",
            "cart_shelf_number",
            "picked_quantity",
            "picked",
          ];
          if (pickingListHeaders.every((i) => headers.includes(i))) {
            resolve("ok");
          } else {
            let missingHeaders = "";
            pickingListHeaders.forEach((header) => {
              if (!headers.includes(header)) {
                missingHeaders += missingHeaders === "" ? header : ", " + header;
              }
            });
            reject(missingHeaders);
          }
        },
      });
    }).catch((e) => {
      console.log(e);
      showToastVar({
        type: "ERROR",
        message: "File headers missing: " + e,
      });
      setFile(null);
    });
  };

  useEffect(() => {
    if (file) {
      validateFileHeaders();
    }
  }, [file]);

  const downloadResultFile = (simulationId, simulationName) => {
    const token = localStorage.getItem("jwt-warehouse-optimization");
    try {
      axios({
        url: "/simulation/" + simulationId,
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", simulationName + "-routes" + ".xlsx");
        document.body.appendChild(link);
        link.click();
      });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadDebugFile = (simulationId, simulationName) => {
    const token = localStorage.getItem("jwt-warehouse-optimization");
    try {
      axios({
        url: "/simulation-debug/" + simulationId,
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", simulationName + "-debug" + ".txt");
        document.body.appendChild(link);
        link.click();
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <AnimatePresence exitBeforeEnter={true}>
        {showResults && (
          <ResultsModal
            simulation={showResults}
            closeModal={() => {
              setShowResults(null);
            }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter>
        {showOptimizationConfig && (
          <OptimizationConfig
            onClose={() => {
              setShowOptimizationConfig(false);
            }}
            onSave={(config) => {
              setOptimizationConfig(config);
            }}
            initialConfig={optimizationConfig}
          />
        )}
      </AnimatePresence>
      <div className="flex m-8">
        <div className="bg-white py-12 px-8">
          <div className="flex flex-col h-screen justify-between" style={{ width: 400, height: "calc(100vh - 250px)" }}>
            {/* <div className="text-lg font-bold" style={{ marginBottom: 20 }}>
              Simulation
            </div> */}
            <div
              className="simulation-config-container overflow-y-auto overflow-x-hidden"
              style={{ height: "86%", paddingRight: 30 }}>
              <TextField
                onChange={(newValue) => {
                  setName(newValue.target.value);
                }}
                style={{ marginBottom: 20 }}
                label="Simulation name"
                value={name}
                autoFocus={true}
                InputLabelProps={{ shrink: true, style: { fontSize: 18, fontWeight: "bold" } }} // font size of input label
              />

              <Autocomplete
                id="account-combo"
                options={datasetsData ? datasetsData.datasets : []}
                getOptionLabel={(option: any) => option.name}
                getOptionSelected={(option, value) => {
                  if (option && value) return option.id === value.id;
                }}
                value={comboDataset}
                onChange={(event: any, newValue: any) => {
                  setComboDataset(newValue);
                }}
                style={{ marginBottom: 20 }}
                renderInput={(params) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true, style: { fontSize: 18, fontWeight: "bold" } }}
                      label="Dataset"
                    />
                  </div>
                )}
              />

              {/* 
              Från Elin ang. fixed winstart/winend:	
              Eftersom simuleringen som ska göras nu endast innehåller ett dataset för en dag så har jag manuellt satt winstart och winend. (Datum och öppettider är fixa). 
              "För simuleringen som ska göras nu, fungerar indatan som den är? Om ja, då kör vi på det nu och fortsätter denna diskussion längre fram. ok?""   
              */}
              <div style={{ width: "100%", fontSize: 14, fontWeight: "bold" }}>
                Simulation date <span className="italic font-light text-xs">(Must match fixed winstart/winend)</span>
              </div>
              <TextField
                type="date"
                style={{ marginBottom: 20 }}
                value={simulationDate}
                onChange={(e) => {
                  setSimulationDate(e.target.value);
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: 18, fontWeight: "bold" } }} // font size of input label
              />

              <div style={{ width: "100%", fontSize: 14, fontWeight: "bold" }}>
                Warehouse opening <span className="italic font-light text-xs">(Must match fixed winstart/winend)</span>
              </div>
              <TextField
                style={{ marginBottom: 20 }}
                onChange={(newValue) => {
                  if (newValue.target.value) {
                    setWarehouseOpening(newValue.target.value);
                  }
                }}
                // label="Warehouse opening"
                type="time"
                value={warehouseOpening}
                InputLabelProps={{ shrink: true, style: { fontSize: 18, fontWeight: "bold" } }} // font size of input label
              />

              <div style={{ width: "100%", fontSize: 14, fontWeight: "bold" }}>
                Warehouse closing <span className="italic font-light text-xs">(Must match fixed winstart/winend)</span>
              </div>
              <TextField
                style={{ marginBottom: 20 }}
                onChange={(newValue) => {
                  if (newValue.target.value) {
                    setWarehouseClosing(newValue.target.value);
                  }
                }}
                // label="Warehouse closing"
                type="time"
                value={warehouseClosing}
                InputLabelProps={{ shrink: true, style: { fontSize: 18, fontWeight: "bold" } }} // font size of input label
              />

              <div style={{ marginBottom: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reoptimizeOrders}
                      onChange={(event) => {
                        setReoptimzeOrders(event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={<div style={{ fontSize: 14, fontWeight: "bold" }}>Re-optimize orders</div>}
                />
              </div>

              <div style={{ marginBottom: 20 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useFixedBuffer}
                      onChange={(event) => {
                        setUseFixedBuffer(event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={<div style={{ fontSize: 14, fontWeight: "bold" }}>Use fixed buffer time</div>}
                />
              </div>

              {!useFixedBuffer && (
                <TextField
                  onChange={(newValue) => {
                    setBufferPercentage(parseInt(newValue.target.value));
                  }}
                  style={{ width: "100%", marginBottom: 20 }}
                  label="Buffer percentage"
                  value={bufferPercentage}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    inputProps: { min: 1 },
                  }}
                  InputLabelProps={{ shrink: true, style: { fontSize: 18, fontWeight: "bold" } }} // font size of input label
                />
              )}

              {useFixedBuffer && (
                <TextField
                  onChange={(newValue) => {
                    setFixedBufferTime(parseInt(newValue.target.value));
                  }}
                  style={{ width: "100%", marginBottom: 20 }}
                  label="Fixed buffer time"
                  value={fixedBufferTime}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                    inputProps: { min: 1 },
                  }}
                  InputLabelProps={{ shrink: true, style: { fontSize: 18, fontWeight: "bold" } }} // font size of input label
                />
              )}

              {!useFixedBuffer && (
                <TextField
                  onChange={(newValue) => {
                    setMaxBufferTime(parseInt(newValue.target.value));
                  }}
                  style={{ width: "100%", marginBottom: 20 }}
                  label="Max buffer time"
                  value={maxBufferTime}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                    inputProps: { min: 1 },
                  }}
                  InputLabelProps={{ shrink: true, style: { fontSize: 18, fontWeight: "bold" } }} // font size of input label
                />
              )}

              <TextField
                onChange={(newValue) => {
                  setOptimizationFrequencyInterval(parseInt(newValue.target.value));
                }}
                style={{ width: "100%", marginBottom: 20 }}
                label="Optimization frequency"
                value={optimizationFrequencyInterval}
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: 18, fontWeight: "bold" } }} // font size of input label
              />

              <TextField
                onChange={(newValue) => {
                  setOptimizationTimeToFinish(parseInt(newValue.target.value));
                }}
                style={{ width: "100%", marginBottom: 20 }}
                label="Optimization duration"
                value={optimizationTimeToFinish}
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                  inputProps: { min: 1 },
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: 18, fontWeight: "bold" } }} // font size of input label
              />

              <div style={{ fontSize: 14, fontWeight: "bold", marginBottom: 10 }}>Optimization configuration</div>
              <div style={{ position: "relative", marginBottom: 20 }}>
                <IoSettingsOutline
                  className="hover:opacity-50 cursor-pointer transition-all"
                  onClick={() => {
                    setShowOptimizationConfig(true);
                  }}
                  style={{ fontSize: 26 }}
                />
                {optimizationConfig && (
                  <BiCheck
                    style={{
                      fontSize: 14,
                      position: "absolute",
                      left: 18,
                      top: 10,
                      backgroundColor: "#333333",
                      borderRadius: 10,
                      color: "white",
                      padding: 2,
                    }}
                  />
                )}
              </div>

              <div className="flex">
                <div style={{ fontSize: 14, fontWeight: "bold" }}>Customer picking rounds</div>
                <div
                  className="px-2 cursor-pointer"
                  title={
                    "Customer picking rounds in .csv format. \nRequired columns: sort_id, order_number, sku_id, cart_shelf_number, picked_quantity, picked"
                  }>
                  <AiOutlineInfoCircle fontSize={18} />
                </div>
              </div>

              <div className="text-center flex mt-2 items-center" style={{ marginBottom: 20 }}>
                <label className="py-2 px-4 cursor-pointer border flex items-center justify-center">
                  <input
                    type="file"
                    multiple
                    accept=".csv"
                    onChange={(e) => {
                      var files = e.target.files;
                      setFile(files[0]);
                    }}
                  />
                  <div>Attach file</div>
                  <BiUpload className="ml-4 text-xl" />
                </label>
                {file && (
                  <div className="ml-4" style={{ width: 200 }}>
                    {file && file.name}
                  </div>
                )}
              </div>
              {resourceDatasetData && (
                <>
                  <div className="flex items-center">
                    <div style={{ fontSize: 16, marginRight: 10, fontWeight: 700 }}>Shifts</div>
                    <BiPlusCircle
                      className="hover"
                      style={{ color: "#319af3", cursor: "pointer" }}
                      onClick={() => {
                        setShifts([...shifts, { end: "", nPickers: 0 }]);
                      }}
                    />
                  </div>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <th scope="col" style={{ width: 100, fontSize: 12 }}>
                        Starts at
                      </th>
                      <th scope="col" style={{ width: 100, fontSize: 12 }}>
                        Ends at
                      </th>
                      <th scope="col" style={{ width: 130, fontSize: 12 }}>
                        Number of pickers
                      </th>
                      <th scope="col" style={{ width: 50, fontSize: 12 }}>
                        {/* action */}
                      </th>
                    </thead>
                    <tbody style={{ backgroundColor: "white" }}>
                      {shifts.map((row, i) => {
                        return (
                          <tr className="table-row" style={{ cursor: "default" }}>
                            <td>
                              {
                                <TextField
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  style={{ width: 80 }}
                                  onChange={(newValue) => {
                                    let accept = true;

                                    // Check that it is not empty
                                    if (!newValue.target.value) {
                                      accept = false;
                                    }

                                    if (accept) {
                                      const newShifts = shifts.map((shift, idx) => {
                                        if (i === idx) {
                                          return { ...shift, start: newValue.target.value };
                                        }
                                        return shift;
                                      });
                                      setShifts(newShifts);
                                    }
                                  }}
                                  type="time"
                                  value={shifts[i].start}
                                />
                              }
                            </td>
                            <td>
                              {
                                <TextField
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  style={{ width: 80 }}
                                  onChange={(newValue) => {
                                    let accept = true;

                                    // Check that it is not empty
                                    if (!newValue.target.value) {
                                      accept = false;
                                    }

                                    if (accept) {
                                      const newShifts = shifts.map((shift, idx) => {
                                        if (i === idx) {
                                          return { ...shift, end: newValue.target.value };
                                        }
                                        return shift;
                                      });
                                      setShifts(newShifts);
                                    }
                                  }}
                                  type="time"
                                  value={shifts[i].end}
                                />
                              }
                            </td>
                            <td>
                              {
                                <TextField
                                  onChange={(newValue) => {
                                    let newShifts = [];
                                    shifts.forEach((shift, idx) => {
                                      if (idx === i) {
                                        newShifts.push({ ...shift, nPickers: parseInt(newValue.target.value) });
                                      } else {
                                        newShifts.push({ ...shift });
                                      }
                                    });
                                    setShifts(newShifts);
                                  }}
                                  style={{ width: 110, marginLeft: 20 }}
                                  value={shifts[i].nPickers}
                                  type="number"
                                  InputProps={{
                                    inputProps: { min: 1 },
                                  }}
                                />
                              }
                            </td>
                            <td className="action-col">
                              <IoMdTrash
                                title="Delete"
                                className={`table-action ${i === 0 && "inactive"}`}
                                onClick={async (e) => {
                                  if (i !== 0) {
                                    const newShifts = [...shifts];
                                    newShifts.splice(i, 1);
                                    setShifts(newShifts);
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}
            </div>
            <div
              className="wide-button m-4"
              style={{
                opacity: ready ? 1 : 0.5,
                cursor: ready ? "pointer" : "default",
              }}
              onClick={async () => {
                if (ready) {
                  // Start
                  const res = await startSimulation({
                    variables: {
                      config: {
                        datasetId: comboDataset.id,
                        name: name.replace(" ", ""),
                        optimizationFrequencyInterval: optimizationFrequencyInterval,
                        optimizationTimeToFinish: optimizationTimeToFinish,
                        simulationDate: simulationDate,
                        warehouseClosing: warehouseClosing,
                        warehouseOpening: warehouseOpening,
                        reoptimizeOrders: reoptimizeOrders,
                        bufferPercentage: useFixedBuffer ? null : bufferPercentage,
                        fixedBufferTime: useFixedBuffer ? fixedBufferTime : null,
                        maxBufferTime: useFixedBuffer ? null : maxBufferTime,
                        shifts: [...(shifts as any)].filter((s) => {
                          if ((!s.start && !s.end) || !s.nPickers) {
                            return false;
                          }
                          return true;
                        }),
                        optimizationConfig: optimizationConfig,
                      },
                      file: file,
                    },
                  }).catch((e) => {
                    return { data: null };
                  });
                  if (res.data && res.data.startSimulation && res.data.startSimulation.success) {
                    refetchSimulations();
                    showToastVar({
                      type: "SUCCESS",
                      message: "Simulation started",
                    });
                    setName(" ");
                  } else {
                    showToastVar({
                      type: "ERROR",
                      message: "Error starting simulation",
                    });
                  }
                }
              }}>
              Start simulation
            </div>
          </div>
        </div>
        <div className="ml-8 bg-white p-12 w-full">
          <div className="table-container tableFixHead" style={{ maxHeight: "calc(100vh - 250px)" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <th scope="col" style={{ width: "30%" }}>
                  Name
                </th>
                <th scope="col" style={{ width: "30%" }}>
                  Created at
                </th>
                <th scope="col" style={{ width: "20%" }}>
                  Status
                </th>
                <th scope="col" style={{ width: "20%" }}>
                  Simulation time
                </th>
                <th scope="col">{/* action */}</th>
              </thead>
              <tbody style={{ backgroundColor: "white" }}>
                {!datasetsData && (
                  <td colSpan={10}>
                    <Spinner size={100} />
                  </td>
                )}
                {simulationsData &&
                  simulationsData.simulations.map((row) => {
                    return (
                      <tr
                        className="table-row"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setConfigStateFromSimulation(row.id);
                        }}>
                        <td>{row.name}</td>
                        <td>{formatDate(row.createdAt)}</td>
                        <td>
                          {
                            <div className="flex items-center justify-center" style={{ width: 50 }}>
                              {row.status === "IN_PROGRESS" && <Spinner size={30} />}
                              {row.status === "ERROR" && <BiError style={{ fontSize: 20 }} />}
                              {row.status === "FINISHED" && <BiCheckCircle style={{ fontSize: 20 }} />}
                              {row.status === "STOPPED" && <FaStopCircle style={{ fontSize: 20 }} />}
                            </div>
                          }
                        </td>
                        <td>{row.status === "IN_PROGRESS" && row.currentTime ? row.currentTime : "-"}</td>
                        <td className="action-col flex items-center justify-center" style={{ height: 60 }}>
                          {row.status === "IN_PROGRESS" && (
                            <FaStopCircle
                              title="Stop simulation"
                              className={`table-action`}
                              onClick={async (e) => {
                                e.stopPropagation();
                                showConfirmDialogVar({
                                  action: "STOP",
                                  type: "simulation",
                                  onConfirm: async () => {
                                    const res = await stopSimulation({ variables: { simulationId: row.id } }).catch(
                                      (e) => {
                                        return { data: null };
                                      },
                                    );
                                    if (res.data && res.data.stopSimulation && res.data.stopSimulation.success) {
                                      refetchSimulations();
                                    } else {
                                      showToastVar({
                                        type: "ERROR",
                                        message: "Error stopping simulation",
                                      });
                                    }
                                    showConfirmDialogVar(null);
                                  },
                                  onCancel: () => {
                                    showConfirmDialogVar(null);
                                  },
                                });
                              }}
                            />
                          )}
                          <AiOutlineBarChart
                            title="Show results"
                            className={`table-action ${row.status !== "FINISHED" && "opacity-50 cursor-default"}`}
                            onClick={async (e) => {
                              e.stopPropagation();
                              if (row.status === "FINISHED") {
                                // downloadResultFile(row.id, row.name);
                                setShowResults(row);
                              }
                            }}
                          />
                          <AiOutlineDownload
                            title="Download routes"
                            className={`table-action ${row.status !== "FINISHED" && "opacity-50 cursor-default"}`}
                            style={{ fontSize: 18 }}
                            onClick={async (e) => {
                              e.stopPropagation();
                              if (row.status === "FINISHED") {
                                downloadResultFile(row.id, row.name);
                              }
                            }}
                          />
                          <AiFillBug
                            title="Download log"
                            className={`table-action ${row.status === "IN_PROGRESS" && "opacity-50 cursor-default"}`}
                            style={{ fontSize: 18 }}
                            onClick={async (e) => {
                              e.stopPropagation();
                              if (row.status !== "IN_PROGRESS") {
                                downloadDebugFile(row.id, row.name);
                              }
                            }}
                          />
                          <IoMdTrash
                            title="Delete simulation"
                            className={`table-action ${row.status === "IN_PROGRESS" && "opacity-50 cursor-default"}`}
                            style={{ fontSize: 20 }}
                            onClick={async (e) => {
                              e.stopPropagation();
                              if (row.status !== "IN_PROGRESS") {
                                showConfirmDialogVar({
                                  action: "DELETE",
                                  type: "simulation",
                                  onConfirm: async () => {
                                    const res = await deleteSimulation({ variables: { simulationId: row.id } }).catch(
                                      (e) => {
                                        return { data: null };
                                      },
                                    );
                                    if (res.data && res.data.deleteSimulation && res.data.deleteSimulation.success) {
                                      refetchSimulations();
                                    } else {
                                      showToastVar({
                                        type: "ERROR",
                                        message: "Error deleting simulation",
                                      });
                                    }
                                    showConfirmDialogVar(null);
                                  },
                                  onCancel: () => {
                                    showConfirmDialogVar(null);
                                  },
                                });
                              }
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Simulation;
