import React, { useState } from "react";
import "./ConfirmDialog.css";
import { getCssVariable } from "../../utils";
import Spinner from "../Spinner/Spinner";
import { motion } from "framer-motion";

type Props = {
  action: "DELETE" | "SAVE" | "STOP_SAVE" | "STOP";
  action2?: "STOP_REMOVE";
  type: string;
  onConfirm: Function;
  onConfirm2?: Function;
  onCancel: Function;
};

function DataInput(props: Props) {
  const { action, action2, type, onConfirm, onConfirm2, onCancel } = props;

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  let message = "";
  if (action === "DELETE") {
    message = `Are you sure you want to delete this ${type}?`;
  } else if (action === "STOP_SAVE") {
    message = `Stopping ${type}. Would you like to save or remove the incomplete results?`;
  } else if (action === "STOP") {
    message = `Are you sure you want to stop this ${type}?`;
  }

  let actionText = "";
  if (action === "DELETE") {
    actionText = "Delete";
  } else if (action === "SAVE") {
    actionText = "Save";
  } else if (action === "STOP_SAVE") {
    actionText = "Save results";
  } else if (action === "STOP") {
    actionText = "Stop";
  }

  let actionText2 = "";
  if (action2 === "STOP_REMOVE") {
    actionText2 = "Remove results";
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex-center dialog-wrapper"
      onClick={() => onCancel()}>
      <div className="flex-center dialog-container" onClick={(e) => e.stopPropagation()}>
        <div style={{ fontWeight: 700, fontSize: 18 }}>{message}</div>
        <div style={{ display: "flex", marginTop: 30 }}>
          <div
            className="wide-button"
            style={{ backgroundColor: "white", color: getCssVariable("--color-blue0") }}
            onClick={(e) => {
              if (!loading && !loading2) {
                e.stopPropagation();
                onCancel();
              }
            }}>
            Cancel
          </div>
          <div
            className="wide-button"
            onClick={(e) => {
              if (!loading && !loading2) {
                setLoading(true);
                e.stopPropagation();
                onConfirm();
              }
            }}>
            {loading && <Spinner size={30} />}
            {!loading && actionText}
          </div>
          {onConfirm2 && (
            <div
              className="wide-button"
              style={{ marginLeft: 10 }}
              onClick={(e) => {
                if (!loading && !loading2) {
                  setLoading2(true);
                  e.stopPropagation();
                  onConfirm2();
                }
              }}>
              {loading2 && <Spinner size={30} />}
              {!loading2 && actionText2}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default DataInput;
