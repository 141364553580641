import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import axios from "axios";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { BiError, BiFile } from "react-icons/bi";
import { ImFilesEmpty } from "react-icons/im";
import { BsArrowRight } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import DataInput from "../../components/DataInput/DataInput";
import Dropzone from "../../components/Dropzone/Dropzone";
import formatDate from "../../formatDate";
import { CREATE_OPTIMIZATION } from "../../graphql/mutations";
import { GET_FILES, GET_DATASETS } from "../../graphql/queries";
import { FILE_TYPES, getCssVariable, getFileType, useStateRef } from "../../utils";
import { showToastVar, warehouseVar } from "../../cache";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Checkbox, FormControlLabel, FormGroup, TextField } from "@material-ui/core";
import "./OptimizationCreate.css";
import "../DatasetUpload/DatasetUpload.css";
import { useHistory } from "react-router-dom";
import {
  CreateOptimizationMutation,
  CreateOptimizationMutationVariables,
  GetDatasetsQuery,
  GetDatasetsQueryVariables,
} from "../../graphql/graphqlTypes";
import Spinner from "../../components/Spinner/Spinner";
import OptimizationConfig from "../../components/OptimizationConfig/OptimizationConfig";
import { AnimatePresence } from "framer-motion";

function OptimizationCreate() {
  const [optimizationName, setOptimizationName] = useState<string>(null);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState(null);
  const [showConfig, setShowConfig] = useState(false);
  const warehouse = useReactiveVar(warehouseVar);
  let history = useHistory();

  const [checkboxes, setCheckboxes] = useState({
    pickingRounds: false,
    resourceAllocation: false,
  });

  const [datasetStatus, setDatasetStatus] = useState({
    datasetName: "",
    datasetId: "",
    createdAt: "",
  });

  const { data: datasetsData } = useQuery<GetDatasetsQuery, GetDatasetsQueryVariables>(GET_DATASETS, {
    skip: !warehouse,
    variables: { warehouseId: warehouse && warehouse.id },
    fetchPolicy: "network-only",
  });

  const [createOptimizationMutation] = useMutation<CreateOptimizationMutation, CreateOptimizationMutationVariables>(
    CREATE_OPTIMIZATION,
  );

  useEffect(() => {
    if (datasetStatus.datasetId && optimizationName) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [datasetStatus, optimizationName]);

  const createOptimization = async () => {
    let types = [];
    if (checkboxes.pickingRounds) {
      types.push("PICKING_ROUNDS");
    }
    if (checkboxes.resourceAllocation) {
      types.push("RESOURCE_ALLOCATION");
    }

    setLoading(true);
    try {
      await createOptimizationMutation({
        variables: {
          warehouseId: warehouse.id,
          optimizationName: optimizationName,
          datasetId: datasetStatus.datasetId,
          types: types,
          config: config,
        },
      });
      // Done - navigate back to table view
      history.push({
        pathname: "/optimizations",
      });
    } catch {
      showToastVar({
        type: "ERROR",
        message: "Failed to create optimization",
      });
    }

    setLoading(false);
  };

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {showConfig && (
          <OptimizationConfig
            onClose={() => {
              setShowConfig(false);
            }}
            onSave={(config) => {
              setConfig(config);
            }}
            initialConfig={config}
          />
        )}
      </AnimatePresence>
      {
        <div style={{ width: "100%", flexDirection: "column", justifyContent: "center" }}>
          <div className="upload-top-container">
            <div className="upload-name-container">
              {
                <DataInput
                  label={"Optimization Name"}
                  autoFocus={true}
                  setValueCallback={(value) => {
                    setOptimizationName(value);
                  }}
                />
              }
            </div>
            {/* <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkboxes.pickingRounds}
                    onChange={(event) => {
                      setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                    }}
                    name="pickingRounds"
                    color="primary"
                  />
                }
                label="Picking rounds"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkboxes.resourceAllocation}
                    onChange={(event) => {
                      setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                    }}
                    name="resourceAllocation"
                    color="primary"
                  />
                }
                label="Resource allocation"
              />
            </FormGroup> */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div
                className="wide-button"
                style={{
                  width: 100,
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={async () => {
                  history.push({
                    pathname: "/optimizations",
                  });
                }}>
                Cancel
              </div>
              <div
                className="wide-button"
                style={{
                  width: 100,
                  marginRight: 0,
                  opacity: ready ? 1 : 0.5,
                  cursor: ready ? "pointer" : "default",
                }}
                onClick={async () => {
                  if (ready) {
                    await createOptimization();
                  }
                }}>
                Create
              </div>
              <IoSettingsOutline
                className="hover:opacity-50 cursor-pointer transition-all"
                onClick={() => {
                  setShowConfig(true);
                }}
                style={{ fontSize: 26, marginLeft: 10 }}
              />
            </div>
          </div>
          <div className="flex-center">
            <div className="upload-container-inner">
              <div className="flex-center prev-files-container">
                <div className="header" style={{ padding: 20, color: getCssVariable("--color-blue0") }}>
                  Created Datasets
                </div>
                <div className="files-list">
                  {datasetsData &&
                    datasetsData.datasets.map((dataset, index) => {
                      return (
                        <div className={`prev-file`} id={"prev-" + index} title={"Add"}>
                          <div className="prev-file-inner">
                            <ImFilesEmpty style={{ marginRight: 10 }} />
                            <span style={{ marginLeft: 5, fontWeight: 700 }}>{dataset.name}</span>
                            <span style={{ marginLeft: 10, color: getCssVariable("--color-black0") }}>
                              {formatDate(dataset.createdAt)}
                            </span>
                            {/* {dataset.distanceMatrixStatus !== "FINISHED" && (
                              <BiError style={{ color: getCssVariable("--color-black0") }} />
                            )} */}
                          </div>
                          <div
                            style={{ backgroundColor: getCssVariable("--color-blue0"), padding: 10 }}
                            className="hover"
                            onClick={() => {
                              // if (dataset.distanceMatrixStatus === "FINISHED") {
                              setDatasetStatus({
                                datasetId: dataset.id,
                                datasetName: dataset.name,
                                createdAt: dataset.createdAt,
                              });
                              // }
                            }}
                            onMouseEnter={(e) => {
                              const prevFileEl = document.getElementById("prev-" + index);
                              if (prevFileEl) {
                                prevFileEl.style.transform = "translateX(10px)";
                              }
                            }}
                            onMouseLeave={(e) => {
                              const prevFileEl = document.getElementById("prev-" + index);
                              if (prevFileEl) {
                                prevFileEl.style.transform = "translateX(0px)";
                              }
                            }}>
                            <BsArrowRight
                              style={{
                                color: getCssVariable("--color-white0"),
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="new-files-container">
                <div className="files-list">
                  {datasetStatus.datasetName ? (
                    <>
                      <div className="new-file">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <ImFilesEmpty style={{ marginRight: 10, fontSize: 22 }} />
                        </div>
                        <div>{datasetStatus.datasetName}</div>
                        <div>{formatDate(datasetStatus.createdAt)}</div>
                        <div style={{ width: 20 }}>
                          {loading && <Spinner size={20} />}
                          {!loading && <div></div>}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default OptimizationCreate;
