import { useMutation, useQuery } from "@apollo/client";
import { MenuItem, TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { IoMdClose, IoMdCheckmark } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import { UPDATE_DIVISION_USER_ROLES } from "../../graphql/mutations";
import "./DataTableRoleRow.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IoMdTrash } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";
import { GET_USER } from "../../graphql/queries";
import {
  GetAccountUsersQuery,
  GetRolesQuery,
  GetUserQuery,
  GetUserQueryVariables,
  UpdateDivisionUserRolesMutation,
  UpdateDivisionUserRolesMutationVariables,
} from "../../graphql/graphqlTypes";

type Props = {
  user: any;
  division: string;
  removeNewRow: Function;
  newRowAdded: Function;
  refetch: Function;
  allRoles: GetRolesQuery["roles"];
  allUsers: GetAccountUsersQuery["accountUsers"];
  allDivisions: { id: string; name: string }[];
  isSubRow?: boolean;
  divisionRoles?: any;
  editable: boolean;
};

function DataTableRoleRow(props: Props) {
  const {
    user,
    division,
    removeNewRow,
    newRowAdded,
    refetch,
    allRoles,
    allUsers,
    allDivisions,
    isSubRow,
    divisionRoles,
    editable,
  } = props;
  const rolesRef: any = useRef(null);

  const [editing, setEditing] = useState(false);
  const [id, setId] = useState<string>(user.id);
  const [roles, setRoles] = useState<GetRolesQuery["roles"]>([]);
  const [options, setOptions] = useState<GetRolesQuery["roles"]>([]);
  const [initialRoles, setInitialRoles] = useState<GetRolesQuery["roles"]>(divisionRoles ? divisionRoles : user.roles);
  const [comboUser, setComboUser] = useState<GetAccountUsersQuery["accountUsers"][0]>(null);
  const [expanded, setExpanded] = useState(false);

  //Subrow states
  const [divisionId, setDivisionId] = useState<string>(division);
  const [divisions, setDivisions] = useState<any>([]);
  const [comboDivision, setComboDivision] = useState<{ id: string; name: string }>(null);

  useEffect(() => {
    if (division !== divisionId) {
      setExpanded(false);
    }
  }, [division]);

  const [updateDivisionUserRoles] = useMutation<
    UpdateDivisionUserRolesMutation,
    UpdateDivisionUserRolesMutationVariables
  >(UPDATE_DIVISION_USER_ROLES);

  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (allRoles) {
      setOptions(allRoles);
      const userRoles = divisionRoles ? divisionRoles : user.roles;
      const roles = allRoles.filter((role: any) => {
        const found = userRoles.find((userRole: any) => {
          if (userRole.id === role.id) return true;
        });
        return found ? true : false;
      });
      setRoles(roles);
      setInitialRoles(roles);
    }
  }, [allRoles, divisionRoles, user]);

  useEffect(() => {
    if (!id || (isSubRow && divisionId === "new")) {
      //new role
      setEditing(true);
      if (rolesRef && rolesRef.current) {
        rolesRef.current.focus();
      }
    } else {
      setEditing(false);
    }

    if (!division) {
      /* OVERVIEW */
      /* FORMATTING EACH USER BY DIVISION->ROLE */
      let userDivisions = [];
      let userDivisionIds = [];
      const userRoles = user.roles;
      if (userRoles) {
        userRoles.forEach((userRole) => {
          if (!userRole.divisions) return; // Can be users from previous request (when switching from usersTable e.g.) which does not have divisions
          const divisions = [...userRole.divisions, ...userRole.warehouses];
          const role = { id: userRole.id, name: userRole.name };

          if (divisions) {
            divisions.forEach((division) => {
              const index = userDivisionIds.indexOf(division.id);
              if (index !== -1) {
                // Already exists
                const prevRoles = userDivisions[index].roles;
                userDivisions.splice(index, 1, {
                  ...userDivisions[index],
                  roles: [...prevRoles, role],
                });
              } else {
                userDivisions.push({
                  id: division.id,
                  name: division.name,
                  roles: [role],
                });
                userDivisionIds.push(division.id);
              }
            });
          }
        });
      }
      setDivisions(userDivisions);
    }
  }, [user]);

  const getDivisionName = () => {
    const found = allDivisions.find((d) => {
      return d.id === divisionId;
    });
    if (found) {
      return found.name;
    } else {
      return "";
    }
  };

  return (
    <>
      <tr
        className={`${isSubRow ? "subrow" : ""} ${!division ? "expandable-row" : ""}`}
        style={expanded ? { borderBottom: "none" } : {}}
        onClick={() => {
          if (!division) {
            setExpanded(!expanded);
          }
        }}>
        {(!division || isSubRow) && (
          <td style={{ width: 20 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {!isSubRow && (
                <MdKeyboardArrowDown
                  style={{
                    transform: expanded ? "rotate(0deg)" : "rotate(-90deg)",
                  }}
                  className="expand-arrow"
                />
              )}
            </div>
          </td>
        )}
        {!isSubRow && (
          <td style={{ width: "30%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {!user.id && (
                <Autocomplete
                  style={{ width: "100%" }}
                  size="small"
                  disabled={!editable}
                  disableClearable
                  options={allUsers ? allUsers.filter((u) => u.id !== (userData && userData.user.id)) : []}
                  onChange={(e, value) => {
                    setEditing(true);
                    setComboUser(value);
                    setId(value.id);
                  }}
                  getOptionLabel={(option: any) => option.name}
                  getOptionSelected={(option, value) => {
                    if (option && value) return option.id === value.id;
                  }}
                  value={comboUser}
                  renderInput={(params) => <TextField {...params} variant="standard" />}
                />
              )}
              {user.id && <div>{user.name ? user.name : comboUser && comboUser.name}</div>}
            </div>
          </td>
        )}
        {isSubRow && (
          <td style={{ width: "30%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {divisionId === "new" && (
                <Autocomplete
                  style={{ width: "100%" }}
                  size="small"
                  disabled={!editable || id === (userData && userData.user.id)}
                  disableClearable
                  options={allDivisions ? allDivisions : []}
                  onChange={(e, value) => {
                    setEditing(true);
                    setComboDivision(value);
                    setDivisionId(value.id);
                  }}
                  getOptionLabel={(option: any) => option.name}
                  getOptionSelected={(option, value) => {
                    if (option && value) return option.id === value.id;
                  }}
                  value={comboDivision}
                  renderInput={(params) => <TextField {...params} variant="standard" />}
                />
              )}
              {divisionId && allDivisions && divisionId !== "new" && <div>{getDivisionName()}</div>}
            </div>
          </td>
        )}
        <td style={{ width: "100%" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Autocomplete
              multiple
              disabled={!division || !editable || id === (userData && userData.user.id)}
              disableClearable={!division}
              style={{ width: "100%" }}
              size="small"
              options={options}
              onChange={(e, value) => {
                setEditing(true);
                setRoles(value);
              }}
              getOptionLabel={(option: any) => option.name}
              getOptionSelected={(option, value) => {
                if (option && value) return option.id === value.id;
              }}
              value={roles}
              renderInput={(params) => <TextField {...params} variant="standard" />}
            />
          </div>
        </td>
        <td className="action-col" style={{ display: "flex", justifyContent: "flex-end" }}>
          {editable && (
            <>
              {!editing && (
                <>
                  {division && (
                    <IoMdTrash
                      title="Delete"
                      className={`table-action ${id === (userData && userData.user.id) && "inactive"}`}
                      onClick={async () => {
                        if (id === (userData && userData.user.id)) return;
                        const allUserRoles = initialRoles.map((role: any) => {
                          return role.id;
                        });
                        await updateDivisionUserRoles({
                          variables: {
                            userId: id,
                            divisionId: divisionId,
                            addedRoleIds: [],
                            removedRoleIds: allUserRoles,
                          },
                        });
                        setEditing(false);
                        refetch();
                      }}
                    />
                  )}
                </>
              )}
              {editing && division && (
                <>
                  {
                    <div
                      className="confirm-cancel-button"
                      style={{
                        opacity: id && divisionId !== "new" && roles.length ? 1 : 0.3,
                        cursor: id && divisionId !== "new" && roles.length ? "pointer" : "default",
                      }}
                      onClick={async () => {
                        if (id && divisionId !== "new" && roles.length) {
                          const addedRoles = roles
                            .filter((role: any) => {
                              const found = initialRoles.find((initialRole: any) => {
                                if (initialRole.id === role.id) return true;
                              });
                              if (found) {
                                return false;
                              }
                              return true;
                            })
                            .map((role: any) => {
                              return role.id;
                            });
                          const removedRoles = initialRoles
                            .filter((initialRole: any) => {
                              const found = roles.find((role: any) => {
                                if (initialRole.id === role.id) return true;
                              });
                              if (found) {
                                return false;
                              }
                              return true;
                            })
                            .map((role: any) => {
                              return role.id;
                            });

                          await updateDivisionUserRoles({
                            variables: {
                              userId: id,
                              divisionId: divisionId,
                              addedRoleIds: addedRoles,
                              removedRoleIds: removedRoles,
                            },
                          });

                          setInitialRoles(roles);
                          setEditing(false);
                          newRowAdded();
                          refetch();
                        }
                      }}>
                      <IoMdCheckmark title="Confirm" />
                    </div>
                  }
                  <div
                    className="confirm-cancel-button"
                    onClick={() => {
                      setEditing(false);
                      if (!isSubRow) {
                        if (!user.id) {
                          // Remove created row
                          removeNewRow();
                        } else {
                          setRoles(initialRoles);
                        }
                      } else {
                        removeNewRow();
                        setRoles(initialRoles);
                      }
                    }}>
                    <IoMdClose title="Cancel" />
                  </div>
                </>
              )}
            </>
          )}
        </td>
      </tr>
      {expanded &&
        divisions.map((division, index) => {
          return (
            <DataTableRoleRow
              {...props}
              key={"divsion_" + division.id + index}
              isSubRow={true}
              division={division.id}
              allDivisions={
                division.id === "new"
                  ? allDivisions.filter((division) => {
                      const found = divisions.find((usedDivision) => {
                        return usedDivision.id === division.id;
                      });
                      return !found;
                    })
                  : allDivisions
              }
              divisionRoles={division.roles}
              removeNewRow={() => {
                setEditing(false);
                setDivisions(
                  divisions.filter((division) => {
                    return division.id !== "new";
                  }),
                );
              }}
            />
          );
        })}
      {editable && expanded && id !== (userData && userData.user.id) && (
        <tr style={{ height: 40 }}>
          <td
            className={`add-row ${editing && "disabled"}`}
            colSpan={4}
            onClick={() => {
              if (!editing) {
                setEditing(true);
                setDivisions([...divisions, { id: "new", name: null, roles: [] }]);
              }
            }}>
            <div
              className="flex-center"
              style={{
                opacity: editing ? 0.2 : 1,
                transition: "all 0.5s",
              }}>
              <IoMdAdd />
              <div style={{ marginBottom: 4, marginLeft: 4 }}>Add role</div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default DataTableRoleRow;
