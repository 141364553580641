import { MenuItem, TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { IoMdClose, IoMdCheckmark } from "react-icons/io";
import "./DataInput.css";

type Props = {
  label: string;
  initValue?: string;
  onConfirm?: Function;
  disabled?: boolean;
  autoFocus?: boolean;
  setValueCallback?: Function;
};

function DataInput(props: Props) {
  const { label, initValue, onConfirm, disabled, autoFocus, setValueCallback } = props;

  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState("");

  const inputRef = useRef(null);

  useEffect(() => {
    if (value !== initValue) {
      setValue(initValue ? initValue : "");
      if (inputRef && autoFocus) {
        inputRef.current.focus();
      }
    }
  }, [initValue]);

  useEffect(() => {
    if (setValueCallback) {
      setValueCallback(value);
    }
  }, [value]);

  return (
    <div style={{ position: "relative", display: "flex", width: "100%" }}>
      <TextField
        label={label}
        value={value}
        disabled={disabled}
        autoFocus={autoFocus ? true : false}
        inputRef={inputRef}
        inputProps={{
          style: { fontSize: 24 },
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onConfirm(value);
            setEditing(false);
          }
        }}
        onChange={(e) => {
          setEditing(true);
          setValue(e.target.value);
        }}
      />
      {editing && onConfirm && (
        <div
          className="confirm-cancel-button"
          style={{
            position: "absolute",
            right: 10,
            bottom: 10,
          }}
          onClick={() => {
            onConfirm(value);
            setEditing(false);
          }}>
          <IoMdCheckmark title="Confirm" />
        </div>
      )}
    </div>
  );
}

export default DataInput;
