import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { IoMdAdd, IoMdTrash, IoMdPlayCircle } from "react-icons/io";
import { FaStopCircle } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { showConfirmDialogVar, warehouseVar } from "../../cache";
import Spinner from "../../components/Spinner/Spinner";
import CircularProgressbar from "../../components/CircularProgressbar/CircularProgressbar";
import formatDate from "../../formatDate";
import {
  DeleteOptimizationMutation,
  DeleteOptimizationMutationVariables,
  GetOptimizationsQuery,
  StartOptimizationMutation,
  StartOptimizationMutationVariables,
  StopOptimizationMutation,
  StopOptimizationMutationVariables,
} from "../../graphql/graphqlTypes";
import { DELETE_OPTIMIZATION, START_OPTIMIZATION, STOP_OPTIMIZATION } from "../../graphql/mutations";
import { getCssVariable } from "../../utils";
import "./OptimizationTable.css";
import { BiError } from "react-icons/bi";

type Props = {
  data: GetOptimizationsQuery["optimizations"];
  refetch: Function;
  type: "NOT_STARTED" | "IN_PROGRESS" | "FINISHED";
};

function OptimizationTable(props: Props) {
  const { data, refetch, type } = props;
  const [startOptimizationMutation] = useMutation<StartOptimizationMutation, StartOptimizationMutationVariables>(
    START_OPTIMIZATION,
  );
  const [stopOptimizationMutation] = useMutation<StopOptimizationMutation, StopOptimizationMutationVariables>(
    STOP_OPTIMIZATION,
  );
  const [deleteOptimizationMutation] = useMutation<DeleteOptimizationMutation, DeleteOptimizationMutationVariables>(
    DELETE_OPTIMIZATION,
  );
  const warehouse = useReactiveVar(warehouseVar);
  let history = useHistory();
  const pathName = useLocation().pathname.replace(/\/$/, "");

  let title = "";
  switch (type) {
    case "NOT_STARTED":
      title = "Queue";
      break;
    case "IN_PROGRESS":
      title = "In progress";
      break;
    case "FINISHED":
      title = "Finished";
      break;
    default:
      break;
  }

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <div className="optimization-table-container">
        <div className="header" style={{ fontSize: 20, marginBottom: 20 }}>
          {title}
        </div>
        <div className="table-container optimization tableFixHead">
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th scope="col" style={{ width: "33%" }}>
                  Name
                </th>
                {type === "NOT_STARTED" && (
                  <th scope="col" style={{ width: "33%" }}>
                    Created at
                  </th>
                )}
                {type === "IN_PROGRESS" && (
                  <th scope="col" style={{ width: "33%" }}>
                    Started at
                  </th>
                )}
                {type === "FINISHED" && (
                  <th scope="col" style={{ width: "33%" }}>
                    Finished at
                  </th>
                )}
                {
                  <th scope="col" style={{ width: "33%" }}>
                    Dataset
                  </th>
                }
                <th scope="col" style={{ width: 30 }}>
                  {/* error */}
                </th>
                <th scope="col" style={{ width: 30 }}>
                  {/* delete */}
                </th>
                <th scope="col" style={{ width: 30 }}>
                  {/* start */}
                </th>
              </tr>
            </thead>

            <tbody style={{ backgroundColor: "white" }}>
              {!data && (
                <td colSpan={10}>
                  <Spinner size={100} />
                </td>
              )}
              {data &&
                data.map((row) => {
                  return (
                    <tr
                      className="table-row"
                      onClick={() => {
                        history.push({
                          pathname: pathName + "/" + row.id,
                        });
                      }}>
                      <td>{row.name}</td>
                      {type === "NOT_STARTED" && <td>{formatDate(row.createdAt)}</td>}
                      {type === "IN_PROGRESS" && <td>{formatDate(row.startedAt)}</td>}
                      {type === "FINISHED" && <td>{formatDate(row.finishedAt)}</td>}
                      {/* <td style={{ display: "flex" }}>
                        {row.types.map((type) => (
                          <div className="optimization-type">
                            {type === "PICKING_ROUNDS" && "Picking rounds"}
                            {type === "RESOURCE_ALLOCATION" && "Resource allocation"}
                          </div>
                        ))}
                      </td> */}
                      {<td>{row.dataset.name}</td>}
                      {(type === "NOT_STARTED" || type === "FINISHED") && (
                        <td className="action-col">
                          <IoMdTrash
                            title="Delete"
                            className={`table-action`}
                            style={{
                              color: getCssVariable("--color-black0"),
                            }}
                            onClick={async (e) => {
                              e.stopPropagation();
                              showConfirmDialogVar({
                                action: "DELETE",
                                type: "optimization",
                                onConfirm: async () => {
                                  await deleteOptimizationMutation({
                                    variables: {
                                      optimizationId: row.id,
                                    },
                                  });
                                  refetch();
                                  showConfirmDialogVar(null);
                                },
                                onCancel: () => {
                                  showConfirmDialogVar(null);
                                },
                              });
                            }}
                          />
                        </td>
                      )}

                      {type === "NOT_STARTED" && row.status === "ERROR" && (
                        <td className="action-col">
                          <BiError
                            title="Optimization failed"
                            className={`table-action`}
                            style={{ opacity: 1, cursor: "default", color: getCssVariable("--color-black0") }}
                          />
                        </td>
                      )}
                      <td className="action-col">
                        {type === "NOT_STARTED" && (
                          <IoMdPlayCircle
                            title="Start Optimization"
                            className={`table-action ${row.status !== "NOT_STARTED" && "inactive"}`}
                            onClick={async (e) => {
                              e.stopPropagation();
                              if (row.status === "NOT_STARTED") {
                                await startOptimizationMutation({
                                  variables: {
                                    optimizationId: row.id,
                                  },
                                });
                                refetch();
                              }
                            }}
                          />
                        )}
                        {type === "IN_PROGRESS" && (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <CircularProgressbar
                              startedAt={row.startedAt}
                              estimatedFinishedAt={row.estimatedFinishedAt}
                            />
                            <div style={{ display: "flex" }}>
                              <FaStopCircle
                                title="Stop Optimization"
                                className={`table-action`}
                                onClick={async (e) => {
                                  e.stopPropagation();
                                  if (row.status === "IN_PROGRESS") {
                                    showConfirmDialogVar({
                                      action: "STOP_SAVE",
                                      action2: "STOP_REMOVE",
                                      type: "optimization",
                                      onConfirm: async () => {
                                        await stopOptimizationMutation({
                                          variables: {
                                            optimizationId: row.id,
                                            keepSolution: true,
                                          },
                                        });
                                        refetch();
                                        showConfirmDialogVar(null);
                                      },
                                      onConfirm2: async () => {
                                        await stopOptimizationMutation({
                                          variables: {
                                            optimizationId: row.id,
                                            keepSolution: false,
                                          },
                                        });
                                        refetch();
                                        showConfirmDialogVar(null);
                                      },
                                      onCancel: () => {
                                        showConfirmDialogVar(null);
                                      },
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {type === "NOT_STARTED" && (
          <div
            className={`add-row`}
            onClick={() => {
              history.push({
                pathname: pathName + "/new",
              });
            }}>
            <div
              className="flex-center"
              style={{
                transition: "all 0.5s",
              }}>
              <IoMdAdd />
              <div style={{ marginBottom: 4, marginLeft: 4 }}>New Optimization</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OptimizationTable;
