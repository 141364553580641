import { ChartData } from "chart.js";
import React, { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { getCssVariable } from "../../utils";

type Props = {
  data: {
    x: string[];
    y: string[];
  };
  label: string;
};

const BarChart = (props: Props) => {
  const { data, label } = props;

  const chartData: ChartData<"bar", string[], string> = {
    labels: data.x,
    datasets: [
      {
        label: label,
        data: data.y,
        backgroundColor: [getCssVariable("--color-blue0")],
      },
    ],
  };

  return (
    <>
      <Bar
        data={chartData}
        options={{
          plugins: { legend: { labels: { font: { size: 16 } } } },
          scales: {
            x: {
              grid: {
                color: "white",
                lineWidth: 0,
                drawBorder: false,
              },
              title: {
                display: true,
                text: "Picking round",
                padding: 20,
                font: { size: 18 },
              },
            },
            y: {
              title: {
                display: true,
                text: label,
                padding: 20,
                font: { size: 18 },
              },
            },
            // y: {
            //   display: false,
            // },
          },
        }}
      />
    </>
  );
};

export default BarChart;
