import { MenuItem, TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import "./DataCombo.css";

type Props = {
  label: string;
  initValue: string | boolean;
  onConfirm: Function;
  options: { name: string; value: boolean }[];
  disabled: boolean;
};

function DataCombo(props: Props) {
  const { label, initValue, onConfirm, options, disabled } = props;
  const [value, setValue] = useState<string>(null);

  return (
    <>
      <TextField
        select
        label={label}
        value={value !== null ? value : initValue}
        disabled={disabled}
        inputProps={{
          style: { fontSize: 24 },
        }}
        onChange={(event) => {
          setValue(event.target.value);
          onConfirm(event.target.value);
        }}>
        {options.map((option: any) => (
          <MenuItem key={option.name} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}

export default DataCombo;
