// Custom hook to handle issue with state not updating in scroll event listener callback
// https://stackoverflow.com/questions/53845595/wrong-react-hooks-behaviour-with-event-listener
import { useEffect, useRef, useState } from "react";
export function useStateRef<T>(initialValue: T) {
  const [value, setValue] = useState(initialValue);

  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  var withTypes: [T, React.Dispatch<React.SetStateAction<T>>, React.MutableRefObject<T>] = [value, setValue, ref];

  return withTypes;
}

// function useState<S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>];

// Getting CSS variables from Javascript
export const getCssVariable = (name) => {
  return getComputedStyle(document.documentElement).getPropertyValue(name);
};

// Getting file type based on csv headers
export enum FILE_TYPES {
  "ZONE",
  "BLOCK",
  "RACK",
  "AISLE",
  "SHELF",
  "CROSSAISLE",
  "DEPOT",
  "PICKER",
  "TRUCK",
  "CART",
  "BOX",
  "ORDER",
  "ITEM",
  "ORDERLINE",
  "NODE",
  "CAD",
}

const aisleHeaders = [
  "aisle_id",
  "rack_id",
  "zone_id",
  "type",
  "front_end_x",
  "front_end_y",
  "tail_end_x",
  "tail_end_y",
  "left_aisle",
  "right_aisle",
  "below_aisle",
  "up_aisle",
  "block_id",
  "direction",
  "position",
];
const blockHeaders = ["zone_id", "block_id", "b_value", "t_value"];
const boxHeaders = [
  "box_type",
  "length",
  "width",
  "height",
  "volume_capacity",
  "weight_capacity",
  // "item_capacity", //not mandatory
];
const cartHeaders = ["cart_id", "cart_type", "truck_id", "box_type", "nr_of_boxes"];
const crossaisleHeaders = ["id_1", "id_2", "id1_type", "id2_type"];
const depotHeaders = ["id", "name", "x_coordinate", "y_coordinate"];
const nodeHeaders = ["node_id", "x_coordinate", "y_coordinate"];
const itemHeaders = ["orderline_id", "item_id", "weight", "volume", "shelf_id", "available_quantity", "node_id"];
const orderHeaders = ["order_id", "externorder_id", "winstart", "winend"];
const orderlineHeaders = [
  "order_id",
  "externorder_id",
  "orderline_id",
  "item_id",
  "quantity",
  "weight",
  "volume",
  "shelf_id",
  "node_id",
];
const pickerHeaders = ["picker_id", "first_name", "surname", "competence"];
const rackHeaders = ["rack_id", "block_id", "maxpick_level"];
const shelfHeaders = ["shelf_id", "rack_id", "x_coordinate", "y_coordinate", "z_coordinate"];
const truckHeaders = ["truck_id", "cart_id", "maxpicklevel", "max_cart", "max_box"];
const zoneHeaders = ["zone_id", "zone_name"];

const astroItemHeaders = ["partno", "vikt(kg)", "volym(l)", "rack", "horcoor", "vercoor"];
const astroOrderHeaders = ["shorto04", "eorderid", "regdate", "pickdate"];
const astroOrderlineHeaders = ["shorto04", "eorderid", "shorto08", "partno", "qty", "wght", "vol"];

export const getFileType = (headers) => {
  console.log(headers);
  let type = null;
  headers = headers.map((h) => h?.toLowerCase().replace("-", "_").split(" ").join(""));
  if (aisleHeaders.every((i) => headers.includes(i))) {
    type = FILE_TYPES.AISLE;
  } else if (blockHeaders.every((i) => headers.includes(i))) {
    type = FILE_TYPES.BLOCK;
  } else if (boxHeaders.every((i) => headers.includes(i))) {
    type = FILE_TYPES.BOX;
  } else if (truckHeaders.every((i) => headers.includes(i))) {
    type = FILE_TYPES.TRUCK;
  } else if (cartHeaders.every((i) => headers.includes(i))) {
    type = FILE_TYPES.CART;
  } else if (crossaisleHeaders.every((i) => headers.includes(i))) {
    type = FILE_TYPES.CROSSAISLE;
  } else if (depotHeaders.every((i) => headers.includes(i))) {
    type = FILE_TYPES.DEPOT;
  } else if (nodeHeaders.every((i) => headers.includes(i))) {
    type = FILE_TYPES.NODE;
  } else if (itemHeaders.every((i) => headers.includes(i)) || astroItemHeaders.every((i) => headers.includes(i))) {
    type = FILE_TYPES.ITEM;
  } else if (orderHeaders.every((i) => headers.includes(i)) || astroOrderHeaders.every((i) => headers.includes(i))) {
    type = FILE_TYPES.ORDER;
  } else if (
    orderlineHeaders.every((i) => headers.includes(i)) ||
    astroOrderlineHeaders.every((i) => headers.includes(i))
  ) {
    type = FILE_TYPES.ORDERLINE;
  } else if (pickerHeaders.every((i) => headers.includes(i))) {
    type = FILE_TYPES.PICKER;
  } else if (rackHeaders.every((i) => headers.includes(i))) {
    type = FILE_TYPES.RACK;
  } else if (shelfHeaders.every((i) => headers.includes(i))) {
    type = FILE_TYPES.SHELF;
  } else if (zoneHeaders.every((i) => headers.includes(i))) {
    type = FILE_TYPES.ZONE;
  }
  return type;
};

// State stored in localstorage to bring the User back to the previous state
// when navigating back to this view
export function getLocalstorageValue(key: "filterWarehouse" | "filterAccount") {
  let state = localStorage.getItem("w-filter-state");
  if (state) {
    let JsonState = JSON.parse(state);
    if (JsonState) {
      return JsonState[key];
    }
  }
}
