import { useMutation, useQuery } from "@apollo/client";
import { MenuItem, TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { RiMailCheckLine, RiMailSendLine, RiInboxArchiveLine, RiInboxUnarchiveLine } from "react-icons/ri";
import { IoMdClose, IoMdCheckmark } from "react-icons/io";
import { REGISTER, SEND_REGISTRATION_MAIL, UPDATE_USER, UPDATE_USER_ARCHIVED } from "../../graphql/mutations";
import { GET_DIVISION, GET_USER } from "../../graphql/queries";
import "./DataTableRow.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  GetUserQuery,
  GetUserQueryVariables,
  RegisterMutation,
  RegisterMutationVariables,
  SendRegistrationMailMutation,
  SendRegistrationMailMutationVariables,
  UpdateUserArchivedMutation,
  UpdateUserArchivedMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from "../../graphql/graphqlTypes";

type Props = {
  user: any;
  division: string;
  removeNewRow: Function;
  newRowAdded: Function;
  refetch: Function;
  allDivisions: { id: string; name: string }[];
  editable: boolean;
};

function DataTableRow(props: Props) {
  const { user, division, removeNewRow, newRowAdded, refetch, allDivisions, editable } = props;
  const nameRef: any = useRef(null);

  const [editing, setEditing] = useState(false);
  const [id, setId] = useState<string>(user.id);
  const [name, setName] = useState<string>(user.name);
  const [email, setEmail] = useState<string>(user.email);
  const [disabled, setDisabled] = useState<any>(user.disabled);
  const [registrationMailSent, setRegistrationMailSent] = useState<boolean>(user.registrationMailSent);
  const [archivedAt, setArchivedAt] = useState<string | boolean>(user.archivedAt);
  const [emailError, setEmailError] = useState(false);

  const [register] = useMutation<RegisterMutation, RegisterMutationVariables>(REGISTER);
  const [update] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UPDATE_USER);
  const [sendRegistrationMail] = useMutation<SendRegistrationMailMutation, SendRegistrationMailMutationVariables>(
    SEND_REGISTRATION_MAIL,
  );
  const [updateUserArchived] = useMutation<UpdateUserArchivedMutation, UpdateUserArchivedMutationVariables>(
    UPDATE_USER_ARCHIVED,
  );
  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });

  // Overview states
  const [divisionId, setDivisionId] = useState<string>(user.divison ? user.division.id : division);
  const [comboDivision, setComboDivision] = useState<{ id: string; name: string }>(null);

  useEffect(() => {
    if (!id) {
      //new user
      setEditing(true);
      if (nameRef && nameRef.current) {
        nameRef.current.focus();
      }
    } else {
      setEditing(false);
    }

    if (!division) {
      setComboDivision(user.division ? user.division : user.warehouse);
    }
  }, [user]);

  const isCorrectEmail = () => {
    if (email !== null) {
      if (email.match(/^\S+@\S+\.\S+$/)) {
        return true;
      }
    }
    return false;
  };

  return (
    <tr>
      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            value={name}
            inputRef={nameRef}
            disabled={!editable}
            onChange={(event) => {
              setEditing(true);
              setName(event.target.value);
            }}
          />
        </div>
      </td>
      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            value={email}
            error={emailError}
            disabled={!editable}
            onChange={(event) => {
              setEditing(true);
              setEmail(event.target.value);
            }}
          />
        </div>
      </td>
      {!division && (
        <td style={{ width: "30%" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {
              <Autocomplete
                style={{ width: "100%" }}
                size="small"
                disabled={!editable || id === (userData && userData.user.id)}
                disableClearable
                options={allDivisions ? allDivisions : []}
                onChange={(e, value) => {
                  setEditing(true);
                  setComboDivision(value);
                  setDivisionId(value.id);
                }}
                getOptionLabel={(option: any) => option.name}
                getOptionSelected={(option, value) => {
                  if (option && value) return option.id === value.id;
                }}
                value={comboDivision}
                renderInput={(params) => <TextField {...params} variant="standard" />}
              />
            }
          </div>
        </td>
      )}
      <td>
        <TextField
          select
          value={disabled}
          disabled={!editable || id === (userData && userData.user.id)}
          onChange={(event) => {
            setEditing(true);
            setDisabled(event.target.value);
          }}>
          {[
            { value: false, name: "Enabled" },
            { value: true, name: "Disabled" },
          ].map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </td>
      <td className="action-col" style={{ display: "flex", justifyContent: "flex-end" }}>
        {editable && (
          <>
            {!editing && (
              <>
                {!registrationMailSent && isCorrectEmail() && (
                  <RiMailSendLine
                    title="Send registration email"
                    className="table-action"
                    onClick={async () => {
                      const res = await sendRegistrationMail({
                        variables: { userId: id },
                      });
                      if (res.data.sendRegistrationMail && res.data.sendRegistrationMail.success) {
                        setRegistrationMailSent(true);
                      }
                    }}
                  />
                )}
                {registrationMailSent && (
                  <RiMailCheckLine title="Registration email sent" className="table-action inactive" />
                )}
                {!archivedAt && (
                  <RiInboxArchiveLine
                    title="Archive"
                    className={`table-action ${id === (userData && userData.user.id) && "inactive"}`}
                    onClick={async () => {
                      if (id !== (userData && userData.user.id)) {
                        const res = await updateUserArchived({
                          variables: { userId: id, archived: true },
                        });
                        if (res.data.updateUserArchived && res.data.updateUserArchived.success) {
                          setArchivedAt(true);
                        }
                      }
                    }}
                  />
                )}
                {archivedAt && (
                  <RiInboxUnarchiveLine
                    title="Unarchive"
                    className="table-action"
                    onClick={async () => {
                      const res = await updateUserArchived({
                        variables: { userId: id, archived: false },
                      });
                      if (res.data.updateUserArchived && res.data.updateUserArchived.success) {
                        setArchivedAt(false);
                      }
                    }}
                  />
                )}
              </>
            )}

            {editing && (
              <>
                {
                  <div
                    className="confirm-cancel-button"
                    style={{
                      opacity: isCorrectEmail() && divisionId ? 1 : 0.3,
                      cursor: isCorrectEmail() && divisionId ? "pointer" : "default",
                    }}
                    onClick={async () => {
                      if (!isCorrectEmail() || !divisionId) {
                        return;
                      }
                      if (!id) {
                        //create user

                        const res = await register({
                          variables: {
                            name: name,
                            email: email,
                            divisionId: divisionId,
                            disabled: disabled,
                          },
                        });
                        if (res.data.register && res.data.register.id) {
                          // SUCCESS
                          setEmailError(false);
                          setEditing(false);
                          // setId(res.data.register.id);
                          // setName(res.data.register.name);
                          // setEmail(res.data.register.email);
                          // setDisabled(res.data.register.disabled)
                          newRowAdded();
                          refetch();
                        } else {
                          // Failed
                          setEmailError(true);
                        }
                      } else {
                        const res = await update({
                          variables: {
                            userId: id,
                            name: name,
                            email: email,
                            divisionId: divisionId,
                            disabled: disabled,
                          },
                        });
                        if (res.data.updateUser && res.data.updateUser.id) {
                          // SUCCESS
                          setEmailError(false);
                          setEditing(false);
                          // setId(res.data.updateUser.id);
                          // setName(res.data.updateUser.name);
                          // setEmail(res.data.updateUser.email);
                          // setDisabled(res.data.updateUser.disabled)
                          refetch();
                        } else {
                          // Failed
                          setEmailError(true);
                        }
                      }
                    }}>
                    <IoMdCheckmark title="Confirm" />
                  </div>
                }
                <div
                  className="confirm-cancel-button"
                  onClick={() => {
                    setEditing(false);
                    if (!user.id) {
                      // Remove created row
                      removeNewRow();
                      return;
                    }
                    setName(user.name);
                    setEmail(user.email);
                    setDisabled(user.disabled);
                  }}>
                  <IoMdClose title="Cancel" />
                </div>
              </>
            )}
          </>
        )}
      </td>
    </tr>
  );
}

export default DataTableRow;
