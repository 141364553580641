import React, { useState, useEffect, useRef } from "react";
import "./Login.css";
import { TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import { LOGIN, RECOVER_PASSWORD } from "../../graphql/mutations";
import { GET_USER } from "../../graphql/queries";
import { ReactComponent as LogoText } from "../../logo-vert.svg";
import {
  LoginMutation,
  LoginMutationVariables,
  RecoverPasswordMutation,
  RecoverPasswordMutationVariables,
} from "../../graphql/graphqlTypes";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "40%",
      },
    },
  }),
);

export default function Login() {
  const classes = useStyles();
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [emailComplete, setEmailComplete] = useState(false);
  const [password, setPassword] = useState("");
  const [responseError, setResponseError] = useState("");
  const [recoverEmailSent, setRecoverEmailSent] = useState(false);
  const [recoverEmailError, setRecoverEmailError] = useState(false);

  const [login] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN);
  const [recover] = useMutation<RecoverPasswordMutation, RecoverPasswordMutationVariables>(RECOVER_PASSWORD);

  const emailRef = useRef<any>(null);
  const loginRef = useRef<any>(null);
  const passwordRef = useRef<any>(null);

  let client = useApolloClient();

  useEffect(() => {
    client
      .query({ query: GET_USER, fetchPolicy: "network-only" })
      .then((res) => {
        if (res.data) {
          // Already logged in: redirect to builder
          setTimeout(() => {
            history.push({
              pathname: "/",
            });
          }, 1000);
        }
      })
      .catch((err) => {
        // Not logged in: do nothing
      });
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (email && email.match(/^\S+@\S+\.\S+$/)) {
      setEmailComplete(true);
    } else {
      setEmailComplete(false);
    }
  }, [email]);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div className="login-container">
        <div className="login-container-inner">
          {
            <>
              <div className="login-logo-container">
                <div>
                  <LogoText style={{ width: 360, marginBottom: -60 }} />
                  {/* <img src={logo} style={{ height: 150 }}></img> */}
                </div>
              </div>
            </>
          }

          {
            <div className="login-input-container">
              {
                <TextField
                  style={{ marginBottom: 20 }}
                  error={!!responseError}
                  inputRef={emailRef}
                  InputProps={{ autoFocus: true }}
                  id="email"
                  autoComplete="email"
                  label="Email"
                  variant="outlined"
                  onChange={handleChange}
                />
              }
              {
                <TextField
                  error={!!responseError}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      if (loginRef && loginRef.current) {
                        loginRef.current.click();
                        if (passwordRef && passwordRef.current) {
                          passwordRef.current.blur();
                        }
                      }
                    }
                  }}
                  inputRef={passwordRef}
                  InputProps={{ type: "password" }}
                  id="password"
                  label="Password"
                  variant="outlined"
                  onChange={handleChange}
                />
              }

              {
                <div
                  tabIndex={0}
                  ref={loginRef}
                  className={`wide-button ${(!emailComplete || !password) && "disabled"}`}
                  style={{ marginTop: 30 }}
                  onClick={async () => {
                    if (emailComplete && password) {
                      localStorage.removeItem("vf-modal-state");
                      const { data } = await login({
                        variables: { email: email, password: password },
                      });
                      if (data.login.token) {
                        localStorage.setItem("jwt-warehouse-optimization", data.login.token);
                        setTimeout(() => {
                          history.push({
                            pathname: "/",
                          });
                        }, 1000);
                      } else {
                        setResponseError("Wrong password");
                      }
                    }
                  }}>
                  <div>Sign in</div>
                </div>
              }
              {emailComplete && responseError && (
                <>
                  {!recoverEmailSent && (
                    <div
                      className="recovery-link hover"
                      onClick={async () => {
                        try {
                          const { data, errors } = await recover({
                            variables: { email: email },
                          });
                          setRecoverEmailSent(true);
                        } catch {
                          setRecoverEmailError(true);
                        }
                      }}>
                      Forgot your password?
                    </div>
                  )}
                  {recoverEmailSent && <div className="recovery-link">Password recovery email sent</div>}
                  {recoverEmailError && <div className="recovery-link">Invalid email</div>}
                </>
              )}
            </div>
          }
        </div>
      </div>
    </motion.div>
  );
}
