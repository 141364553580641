import { Checkbox, FormControlLabel, MenuItem, TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import "./VisualizationModal.css";
import { fabric } from "fabric";
import layoutBg from "../../warehouse-layout.png";
import { useQuery } from "@apollo/client";
import { GET_AISLES, GET_CROSSAISLES, GET_DEPOTS, GET_NODES, GET_SHELVES } from "../../graphql/queries";
import {
  GetAislesQuery,
  GetAislesQueryVariables,
  GetCrossaislesQuery,
  GetCrossaislesQueryVariables,
  GetDepotsQuery,
  GetDepotsQueryVariables,
  GetNodesQuery,
  GetNodesQueryVariables,
  GetOptimizationQuery,
  GetShelvesQuery,
  GetShelvesQueryVariables,
} from "../../graphql/graphqlTypes";
import { getCssVariable, useStateRef } from "../../utils";
import { IoMdClose, IoMdPlayCircle } from "react-icons/io";
import { AiOutlineBorderInner, AiOutlineBorderOuter } from "react-icons/ai";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { MdZoomIn, MdZoomOut } from "react-icons/md";
import Spinner from "../Spinner/Spinner";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { motion } from "framer-motion";

const useStyles = makeStyles({
  root: {
    height: 200,
  },
});

// const route = [
//   { shelfId: "C02-033" },
//   { shelfId: "C04-026" },
//   { shelfId: "C05-013" },
//   { shelfId: "C30-008" },
//   { shelfId: "C37-005" },
//   { shelfId: "C44-009" },
//   { shelfId: "B01-023" },
//   { shelfId: "A03-054" },
//   { shelfId: "A03-042" },
//   { shelfId: "A08-018" },
//   { shelfId: "A07-039" },
//   { shelfId: "A49-016" },
//   { shelfId: "A22-019" },
//   { shelfId: "A13-014" },
//   { shelfId: "A02-027" },
//   { shelfId: "A02-010" },
// ];

type Props = {
  dataset: { id?: string; name?: string };
  closeModal: Function;
  optimization?: GetOptimizationQuery["optimization"];
  initRoute?: { id?: string; number?: string };
  initPickingRound?: { id?: string; number?: string };
  initSubGroup?: { startWindow?: string; number?: string };
};

let shelvesGroup;
let aislesGroup;
let crossAislesGroup;
let nodesGroup;
let depotsGroup;
let generatedCrossAislesGroup;

function VisualizationModal(props: Props) {
  const { dataset, closeModal, initRoute, initPickingRound, initSubGroup, optimization } = props;

  const modalRef = useRef(null);
  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref, func) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          modalHeaderRef.current !== event.target &&
          (!optimization || modalHeaderComboRef.current !== event.target) &&
          (!optimization || modalHeaderComboRef.current) &&
          (!optimization || !modalHeaderComboRef.current.contains(event.target)) &&
          !event.target.classList.contains("option") &&
          !event.target.classList.contains("MuiAutocomplete-option") &&
          !event.target.classList.contains("MuiAutocomplete-listbox")
        ) {
          func();
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(modalRef, () => closeModal());

  const getRouteFromId = (routeId) => {
    let route:
      | GetOptimizationQuery["optimization"]["subGroups"][0]["solution"]["pickingRounds"][0]["routes"][0]["routeStops"]
      | null = null;
    optimization.subGroups.forEach((subGroup) => {
      if (!subGroup.solution) {
        return;
      }
      if (subGroup.solution.pickingRounds.length) {
        subGroup.solution.pickingRounds.forEach((pr) => {
          const foundPr = pr.routes.find((r) => {
            if (r.id === routeId) return true;
          });
          if (foundPr) {
            route = foundPr.routeStops;
          }
        });
      } else {
        const foundPr = subGroup.solution.routes.find((r) => {
          if (r.id === routeId) return true;
        });
        if (foundPr) {
          route = foundPr.routeStops;
        }
      }
    });
    return route;
  };

  const { data: aislesData, loading: aislesLoading } = useQuery<GetAislesQuery, GetAislesQueryVariables>(GET_AISLES, {
    fetchPolicy: "no-cache",
    variables: { datasetId: dataset.id },
  });

  const { data: depotsData, loading: depotsLoading } = useQuery<GetDepotsQuery, GetDepotsQueryVariables>(GET_DEPOTS, {
    fetchPolicy: "no-cache",
    variables: { datasetId: dataset.id },
  });

  const { data: shelvesData, loading: shelvesLoading } = useQuery<GetShelvesQuery, GetShelvesQueryVariables>(
    GET_SHELVES,
    {
      fetchPolicy: "no-cache",
      variables: { datasetId: dataset.id },
    },
  );

  const { data: crossAislesData, loading: crossAislesLoading } = useQuery<
    GetCrossaislesQuery,
    GetCrossaislesQueryVariables
  >(GET_CROSSAISLES, {
    fetchPolicy: "no-cache",
    variables: { datasetId: dataset.id },
  });

  const { data: nodesData } = useQuery<GetNodesQuery, GetNodesQueryVariables>(GET_NODES, {
    fetchPolicy: "no-cache",
    variables: { datasetId: dataset.id },
  });

  const modalHeaderRef = useRef(null);
  const modalHeaderComboRef = useRef(null);
  const [canvas, setCanvas, canvasRef] = useStateRef<fabric.Canvas>(null);
  const [zoom, setZoom] = useState<number>(1);
  const [routeRects, setRouteRects] = useState<fabric.Rect[]>([]);
  const [routeTexts, setRouteTexts] = useState<fabric.Group[]>([]);
  const [routeInfos, setRouteInfos, routeInfosRef] = useStateRef<fabric.Textbox[]>([]);
  const [routePlaying, setRoutePlaying] = useState<boolean>(false);
  const [doneRendering, setDoneRendering] = useState<boolean>(false);
  const [outline, setOutline] = useState<fabric.Image>(null);
  const [showOutline, setShowOutline] = useState<boolean>(false);
  const [layoutGroup, setLayoutGroup] = useState<fabric.Group>(null);
  const [showLayout, setShowLayout] = useState<boolean>(true);
  const [showShelves, setShowShelves] = useState<boolean>(true);
  const [showAisles, setShowAisles] = useState<boolean>(true);
  const [showCrossAisles, setShowCrossAisles] = useState<boolean>(true);
  const [showGeneratedCrossAisles, setShowGeneratedCrossAisles] = useState<boolean>(false);
  const [showNodes, setShowNodes] = useState<boolean>(true);
  const [showDepots, setShowDepots] = useState<boolean>(true);

  const [route, setRoute] = useState<
    GetOptimizationQuery["optimization"]["subGroups"][0]["solution"]["pickingRounds"][0]["routes"][0]["routeStops"]
  >([]);
  const [comboRoute, setComboRoute] = useState<{ id?: string; number?: string }>(initRoute);
  const [comboPickingRound, setComboPickingRound] = useState<{ id?: string; number?: string }>(initPickingRound);
  const [comboSubGroup, setComboSubGroup] = useState<{ id?: string; startWindow?: string }>(initSubGroup);

  useEffect(() => {
    setCanvas(initCanvas());
  }, []);

  useEffect(() => {
    if (comboRoute) {
      const route = getRouteFromId(comboRoute.id);
      setRoute(route);
    } else {
      setRoute(null);
    }
  }, [comboRoute]);

  const initCanvas = () => {
    return new fabric.Canvas("canvas", {
      height: window.innerHeight * 0.7,
      width: window.innerWidth * 0.7,
      backgroundColor: "white",
      preserveObjectStacking: true,
    });
  };

  const getComboRoutes = () => {
    if (comboSubGroup) {
      const subGroup = optimization.subGroups.find((sg) => {
        if (sg.id === comboSubGroup.id) return true;
      });

      if (subGroup.solution.pickingRounds.length && comboPickingRound) {
        const pickingRound = subGroup.solution.pickingRounds.find((pr) => {
          if (pr.id === comboPickingRound.id) return true;
        });
        return pickingRound.routes;
      } else if (subGroup.solution.routes.length) {
        return subGroup.solution.routes;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (canvas && aislesData && shelvesData && depotsData && crossAislesData && nodesData) {
      if (doneRendering) {
        // route changed -> clear
        canvas.clear();
        canvas.setZoom(1);
        setZoom(1);
      }

      const sizeFactor = window.innerHeight / 3;

      const shelvesInGroup = [];
      shelvesData.shelves.forEach((shelf) => {
        shelvesInGroup.push(
          new fabric.Rect({
            top: shelf.yCoor,
            left: shelf.xCoor,
            width: 1 * sizeFactor,
            height: 1 * sizeFactor,
            fill: "#319af3",
          }),
        );
      });
      shelvesGroup = new fabric.Group(shelvesInGroup);
      canvas.add(shelvesGroup);

      const depotsInGroup = [];
      depotsData.depots.forEach((depot) => {
        var rect = new fabric.Rect({
          height: 10 * sizeFactor,
          width: 10 * sizeFactor,
          fill: "#5f97ff",
          originX: "center",
          originY: "center",
        });

        var text = new fabric.Text(depot.name, {
          fontSize: 3 * sizeFactor,
          originX: "center",
          originY: "center",
          fill: "black",
          flipY: true,
          flipX: false,
          fontFamily: "arial",
          fontWeight: 600,
        });

        var group = new fabric.Group([rect, text], {
          left: depot.xCoor - 5 * sizeFactor,
          top: depot.yCoor - 5 * sizeFactor,
        });

        depotsInGroup.push(group);
      });
      depotsGroup = new fabric.Group(depotsInGroup);
      canvas.add(depotsGroup);

      const aislesInGroup = [];
      aislesData.aisles.forEach((aisle) => {
        const frontEndX = aisle.frontEndX;
        const frontEndY = aisle.frontEndY;
        const tailEndX = aisle.tailEndX;
        const tailEndY = aisle.tailEndY;

        const aisleWidth = sizeFactor * (aisle.type === "wide" ? 2 : 1);

        if (frontEndX === tailEndX) {
          // Vertical

          const rect = new fabric.Rect({
            top: frontEndY,
            left: frontEndX - aisleWidth / 3.5,
            width: aisleWidth,
            height: tailEndY - frontEndY,
            fill: "#5f97ff",
          });
          // canvas.add(rect);
          // rect.sendToBack();
          aislesInGroup.push(rect);

          const text = new fabric.Text(aisle.direction === 2 ? "←→" : "→", {
            fontSize: 5 * sizeFactor,
            width: 40,
            left:
              aisle.direction === 1
                ? rect.getCenterPoint().x + 2.5 * sizeFactor
                : rect.getCenterPoint().x - 2.5 * sizeFactor,
            top:
              aisle.direction === 2
                ? rect.getCenterPoint().y + 5 * sizeFactor
                : rect.getCenterPoint().y + 2.5 * sizeFactor,
            angle: aisle.direction === 1 ? 90 : 270,
            fontWeight: 800,
            flipY: true,
            fontFamily: "arial",
            fill: "#102940",
          });

          aislesInGroup.push(text);
        } else {
          // Horizontal

          const rect = new fabric.Rect({
            top: frontEndY - aisleWidth / 3.5,
            left: frontEndX,
            width: tailEndX - frontEndX,
            height: aisleWidth,
            fill: "#5f97ff",
          });
          // canvas.add(rect);
          // rect.sendToBack();

          aislesInGroup.push(rect);

          const text = new fabric.Text(aisle.direction === 2 ? "←→" : "→", {
            fontSize: 5 * sizeFactor,
            width: 40,
            top:
              aisle.direction === 1
                ? rect.getCenterPoint().y + 2.5 * sizeFactor
                : rect.getCenterPoint().y - 2.5 * sizeFactor,
            left: aisle.direction === 2 ? rect.getCenterPoint().x + 5 * sizeFactor : rect.getCenterPoint().x,
            angle: aisle.direction === 1 ? 180 : 0,
            fontWeight: 800,
            flipY: true,
            fontFamily: "arial",
            fill: "#102940",
          });

          aislesInGroup.push(text);
        }
      });

      aislesGroup = new fabric.Group(aislesInGroup);
      canvas.add(aislesGroup);

      const crossAislesInGroup = [];
      const generatedCrossAislesInGroup = [];

      crossAislesData.crossaisles.forEach((ca) => {
        // Get start coordinate
        let start = { x: 0, y: 0 };
        if (ca.firstType === 0) {
          // aisle tail/front
          const aisleId = ca.firstId.replace("tail", "").replace("front", "");
          const aisle = aislesData.aisles.find((a) => {
            return a.orgAisleId === aisleId;
          });
          if (ca.firstId.includes("front")) {
            start = { x: aisle.frontEndX, y: aisle.frontEndY };
          } else {
            start = { x: aisle.tailEndX, y: aisle.tailEndY };
          }
        } else if (ca.firstType === 1) {
          // shelf
          const shelf = shelvesData.shelves.find((s) => {
            return s.orgShelfId === ca.firstId;
          });
          start = { x: shelf.xCoor, y: shelf.yCoor };
        } else if (ca.firstType === 2) {
          // depot
          const depot = depotsData.depots.find((d) => {
            return d.orgDepotId === ca.firstId;
          });
          start = { x: depot.xCoor, y: depot.yCoor };
        }

        // Get end coordinate
        let end = { x: 0, y: 0 };
        if (ca.secondType === 0) {
          // aisle tail/front
          const aisleId = ca.secondId.replace("tail", "").replace("front", "");
          const aisle = aislesData.aisles.find((a) => {
            return a.orgAisleId === aisleId;
          });
          if (ca.secondId.includes("front")) {
            end = { x: aisle.frontEndX, y: aisle.frontEndY };
          } else {
            end = { x: aisle.tailEndX, y: aisle.tailEndY };
          }
        } else if (ca.secondType === 1) {
          // shelf
          const shelf = shelvesData.shelves.find((s) => {
            return s.orgShelfId === ca.secondId;
          });
          end = { x: shelf.xCoor, y: shelf.yCoor };
        } else if (ca.secondType === 2) {
          // depot
          const depot = depotsData.depots.find((d) => {
            return d.orgDepotId === ca.secondId;
          });
          end = { x: depot.xCoor, y: depot.yCoor };
        }

        var line = new fabric.Line([start.x, start.y, end.x, end.y], {
          stroke: "red",
          strokeWidth: 0.2 * sizeFactor,
        });

        if (ca.generated) {
          generatedCrossAislesInGroup.push(line);
        } else {
          crossAislesInGroup.push(line);
        }

        // canvas.add(line);

        // canvas.add(
        const startPoint = new fabric.Rect({
          top: start.y,
          left: start.x,
          width: 1 * sizeFactor,
          height: 1 * sizeFactor,
          fill: "#FF0000",
        });
        // canvas.add(startPoint);
        if (ca.generated) {
          generatedCrossAislesInGroup.push(startPoint);
        } else {
          crossAislesInGroup.push(startPoint);
        }

        // );
        // canvas.add(
        const endPoint = new fabric.Rect({
          top: end.y,
          left: end.x,
          width: 1 * sizeFactor,
          height: 1 * sizeFactor,
          fill: "#FF0000",
        });
        // canvas.add(endPoint);
        if (ca.generated) {
          generatedCrossAislesInGroup.push(endPoint);
        } else {
          crossAislesInGroup.push(endPoint);
        }

        // );
      });

      crossAislesGroup = new fabric.Group(crossAislesInGroup);
      canvas.add(crossAislesGroup);
      generatedCrossAislesGroup = new fabric.Group(generatedCrossAislesInGroup);
      canvas.add(generatedCrossAislesGroup);
      generatedCrossAislesGroup.visible = false;

      const nodesInGroup = [];
      nodesData.nodes.forEach((n) => {
        const rect = new fabric.Rect({
          top: n.yCoor,
          left: n.xCoor,
          width: 1 * sizeFactor,
          height: 1 * sizeFactor,
          fill: "#00FF00",
        });
        nodesInGroup.push(rect);
      });

      nodesGroup = new fabric.Group(nodesInGroup);
      canvas.add(nodesGroup);

      if (route) {
        const routeRectsTmp: fabric.Rect[] = [];
        const routeTextsTmp: fabric.Group[] = [];
        const routeInfosTmp: fabric.Textbox[] = [];
        route.forEach((stop, index) => {
          const shelf = shelvesData.shelves.find((shelf) => shelf.orgShelfId.includes(stop.shelf.orgShelfId));
          if (shelf) {
            const rect = new fabric.Rect({
              top: shelf.yCoor,
              left: shelf.xCoor,
              width: 1 * sizeFactor,
              height: 1 * sizeFactor,
              fill: "#102940",
              opacity: 0,
            });
            routeRectsTmp.push(rect);
            rect.lockMovementX = true;
            rect.lockMovementY = true;
            rect.hasControls = false;
            rect.hasBorders = false;
            rect.hoverCursor = "default";
            canvas.add(rect);

            var circle = new fabric.Circle({
              radius: 4 * sizeFactor,
              fill: "#102940",
              originX: "center",
              originY: "center",
            });

            var text = new fabric.Text(index + 1 + "", {
              fontSize: 2 * sizeFactor,
              fill: "white",
              originX: "center",
              originY: "center",
              flipY: true,
              fontFamily: "arial",
            });

            var group = new fabric.Group([circle, text], {
              top: shelf.yCoor,
              left: shelf.xCoor,
              opacity: 0,
            });
            group["routeStopId"] = stop.id;
            group.lockMovementX = true;
            group.lockMovementY = true;
            group.hasControls = false;
            group.hasBorders = false;
            group.hoverCursor = "pointer";

            routeTextsTmp.push(group);
            canvas.add(group);

            let info = "\n";
            info = info + "Shelf " + stop.shelf.orgShelfId + "\n";
            info = info + "OrderRow " + stop.orderRow?.orgOrderRowId + "\n";
            info = info + "Item " + stop.item.orgItemId + "\n";
            info = info + "Arrival time " + stop.arrivalTime + "\n";
            info = info + "Delivery time " + stop.deliveryTime + "\n";

            var infoText = new fabric.Textbox(info, {
              fontSize: 4 * sizeFactor,
              fontFamily: "Arial",
              fill: "white",
              width: 50 * sizeFactor,
              backgroundColor: "#102940",
              top: shelf.yCoor,
              left: shelf.xCoor,
              opacity: 0,
              flipY: true,
              textAlign: "center",
            });

            infoText["routeStopId"] = stop.id;
            routeInfosTmp.push(infoText);
            canvas.add(infoText);
          }
        });
        setRouteRects(routeRectsTmp);
        setRouteTexts(routeTextsTmp);
        setRouteInfos(routeInfosTmp);
      }

      canvas.discardActiveObject();
      var sel = new fabric.ActiveSelection(canvas.getObjects(), {
        canvas: canvas,
      });

      canvas.setActiveObject(sel);
      const group = sel.toGroup();
      group.scaleToHeight(window.innerHeight * 2);
      // group.scaleToWidth(window.innerWidth * 0.5);
      group.center();
      group.flipY = true;
      group.flipX = false;

      group.hasControls = false;
      group.hasBorders = false;
      group.lockMovementX = true;
      group.lockMovementY = true;

      setLayoutGroup(group);

      canvas.discardActiveObject();

      if (!doneRendering) {
        setDoneRendering(true);

        canvas.on("mouse:wheel", function (opt) {
          //@ts-ignore
          var delta = opt.e.deltaY;
          console.log(delta);
          if (delta < 0) delta = -100; //Universal scroll speed
          if (delta > 0) delta = 100;
          var zoom = canvas.getZoom();
          zoom *= 0.999 ** delta;
          if (zoom > 4) zoom = 4;
          if (zoom < 0.2) zoom = 0.2;
          //@ts-ignore
          canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
          setZoom(zoom);
          opt.e.preventDefault();
          opt.e.stopPropagation();
        });

        var panning = false;
        canvas.on("mouse:up", function (e) {
          panning = false;
        });

        canvas.on("mouse:down", function (e) {
          if (e.target) {
            panning = true;
          }
        });
        canvas.on("mouse:move", function (e) {
          if (panning && e && e.e) {
            //@ts-ignore
            var delta = new fabric.Point(e.e.movementX, e.e.movementY);
            canvas.relativePan(delta);
          }
        });
        canvas.on("mouse:over", function (e) {
          //@ts-ignore
          if (e.target && e.target.routeStopId) {
            routeInfosRef.current.forEach((routeInfo) => {
              //@ts-ignore
              if (routeInfo.routeStopId === e.target.routeStopId) {
                routeInfo.opacity = 1;
                canvas.setZoom(canvas.getZoom() + 0.00001); // force update render
              }
            });
          }
        });
        canvas.on("mouse:out", function (e) {
          //@ts-ignore
          if (e.target && e.target.routeStopId) {
            routeInfosRef.current.forEach((routeInfo) => {
              //@ts-ignore
              if (routeInfo.routeStopId === e.target.routeStopId) {
                routeInfo.opacity = 0;
                canvas.setZoom(canvas.getZoom() + 0.00001); // force update render
              }
            });
          }
        });
      } else {
        group.viewportCenter();
      }

      fabric.Image.fromURL(layoutBg, function (img) {
        img.scaleToHeight(window.innerHeight * 0.5).scaleToWidth(window.innerWidth * 0.5);
        setOutline(img);
      });

      // canvas.setBackgroundImage(layoutBg, canvas.renderAll.bind(canvas), {
      //   lockUniScaling: false,
      //   width: 100,
      //   height: 100,
      // });
    }
  }, [canvas, aislesData, shelvesData, depotsData, crossAislesData, nodesData, route]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        // 1000px is minimum responsive width
        canvasRef.current.setHeight(window.innerHeight * 0.7);
        canvasRef.current.setWidth(window.innerWidth * 0.7);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const startRoute = () => {
    setRoutePlaying(true);
    routeTexts.forEach((text) => {
      canvas.add(text);
      // text.opacity = 1;
    });
    routeRects.forEach((rect) => {
      canvas.add(rect);
      // rect.opacity = 1;
    });
    let index = 0;
    var outer = setInterval(function () {
      if (index >= routeRects.length) {
        clearInterval(outer);
        setRoutePlaying(false);
      } else {
        routeRects[index].opacity = 1;
        routeTexts[index].opacity = 1;
        canvas.setZoom(canvas.getZoom() + 0.00001); // force update render
        index++;
      }
    }, 1000);
  };

  const classes = useStyles();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex-center modal-container">
      <div className="flex-center header modal-header" ref={modalHeaderRef}>
        {optimization ? optimization.name : dataset.name}
        <div className="clickable" style={{ height: 16 }}>
          <IoMdClose
            style={{ position: "absolute", right: "1vw", fontSize: 30, bottom: 15 }}
            onClick={() => {
              closeModal();
            }}
          />
        </div>
      </div>
      {optimization && (
        <div
          className="flex-center modal-header"
          ref={modalHeaderComboRef}
          style={{ backgroundColor: getCssVariable("--color-black0") }}>
          <div style={{ marginBottom: 5, marginRight: 10 }}>Subgroup:</div>
          <Autocomplete
            options={optimization.subGroups.filter((sg) => sg.solution)}
            getOptionLabel={(option: any) => {
              return moment(parseInt(option.startWindow)).format("YYYY-MM-DD");
            }}
            getOptionSelected={(option, value) => {
              if (option && value) return option.id === value.id;
            }}
            disableClearable
            value={comboSubGroup}
            onChange={(event: any, newValue: any) => {
              if (newValue) {
                setComboRoute(null);
                setComboPickingRound(null);
                setComboSubGroup(newValue);
              }
            }}
            style={{
              padding: 5,
              marginRight: 40,
              width: 140,
            }}
            renderOption={(option) => {
              return <div className="option">{moment(parseInt(option.startWindow)).format("YYYY-MM-DD")}</div>;
            }}
            renderInput={(params) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField {...params} />
              </div>
            )}
          />
          {comboSubGroup &&
            optimization.subGroups.find((sg) => {
              if (sg.id === comboSubGroup.id) return true;
            }).solution.pickingRounds.length > 0 && (
              <>
                <div style={{ marginBottom: 5, marginRight: 10 }}>Picking round:</div>
                <Autocomplete
                  options={
                    comboSubGroup
                      ? optimization.subGroups.find((sg) => {
                          if (sg.id === comboSubGroup.id) return true;
                        })?.solution.pickingRounds
                      : []
                  }
                  getOptionLabel={(option: any) => option.number + ""}
                  getOptionSelected={(option, value) => {
                    if (option && value) return option.id === value.id;
                  }}
                  disableClearable
                  value={comboPickingRound}
                  onChange={(event: any, newValue: any) => {
                    if (newValue) {
                      setComboRoute(null);
                      setComboPickingRound(newValue);
                    }
                  }}
                  style={{
                    padding: 5,
                    marginRight: 40,
                    width: 50,
                  }}
                  renderOption={(option) => {
                    return <div className="option">{option.number + ""}</div>;
                  }}
                  renderInput={(params) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField {...params} />
                    </div>
                  )}
                />
              </>
            )}
          <div style={{ marginBottom: 5, marginRight: 10 }}>Route:</div>
          <Autocomplete
            options={getComboRoutes()}
            getOptionLabel={(option: any) => option.number + ""}
            getOptionSelected={(option, value) => {
              if (option && value) return option.id === value.id;
            }}
            disableClearable
            value={comboRoute}
            onChange={(event: any, newValue: any) => {
              if (newValue) {
                setComboRoute(newValue);
              }
            }}
            style={{
              padding: 5,
              width: 50,
            }}
            renderOption={(option) => {
              return <div className="option">{option.number + ""}</div>;
            }}
            renderInput={(params) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField {...params} />
              </div>
            )}
          />
        </div>
      )}
      <div className="flex-center modal-canvas-container flex-col" ref={modalRef}>
        <div className="canvas-tools-container">
          <div className={classes.root}>
            <Slider
              orientation="vertical"
              defaultValue={30}
              value={zoom}
              min={0.2}
              step={0.1}
              max={4}
              aria-labelledby="vertical-slider"
              onChange={(e, newValue: number) => {
                canvas.zoomToPoint(layoutGroup.getCenterPoint(), zoom);
                setZoom(newValue);
              }}
            />
          </div>
          <div>
            <MdZoomIn
              className={`visualization-button ${zoom >= 4 && "disabled"}`}
              onClick={() => {
                if (zoom < 4) {
                  canvas.zoomToPoint(layoutGroup.getCenterPoint(), zoom + 0.1);
                  setZoom(zoom + 0.1);
                }
              }}
            />
          </div>
          <div>
            <MdZoomOut
              className={`visualization-button ${zoom <= 0.5 && "disabled"}`}
              style={{ marginTop: 0 }}
              onClick={() => {
                if (zoom > 0.2) {
                  setZoom(zoom - 0.1);
                  canvas.zoomToPoint(layoutGroup.getCenterPoint(), zoom - 0.1);
                }
              }}
            />
          </div>
          {optimization && (
            <div>
              <IoMdPlayCircle
                className={`visualization-button ${(routePlaying || !route || !comboRoute) && "disabled"}`}
                style={{ marginTop: 0 }}
                onClick={() => {
                  if (!routePlaying && route && comboRoute) {
                    startRoute();
                  }
                }}
              />
            </div>
          )}
          {/* <div>
              <AiOutlineBorderOuter
                className={`visualization-button`}
                style={{
                  marginTop: 0,
                  color: showOutline ? getCssVariable("--color-blue0") : getCssVariable("--color-black3"),
                }}
                onClick={() => {
                  if (!showOutline) {
                    canvas.add(outline);
                    outline.sendToBack();
                  } else {
                    canvas.remove(outline);
                  }
                  setShowOutline(!showOutline);
                }}
              />
            </div>
            <div>
              <AiOutlineBorderInner
                className={`visualization-button`}
                style={{
                  marginTop: 0,
                  color: showLayout ? getCssVariable("--color-blue0") : getCssVariable("--color-black3"),
                }}
                onClick={() => {
                  if (!showLayout) {
                    canvas.add(layoutGroup);
                    layoutGroup.bringToFront();
                  } else {
                    canvas.remove(layoutGroup);
                  }
                  setShowLayout(!showLayout);
                }}
              />
            </div> */}
        </div>
        {!doneRendering && (
          <div style={{ position: "absolute", zIndex: 11 }}>
            <Spinner size={100} />
          </div>
        )}
        <canvas id="canvas" style={{ border: "1px solid var(--color-black3)" }} />
        <div className="flex" style={{ marginTop: 20 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showShelves}
                onChange={(event) => {
                  setShowShelves(event.target.checked);
                  shelvesGroup.visible = event.target.checked;
                  canvas.setZoom(canvas.getZoom() + 0.00001);
                }}
                color="primary"
              />
            }
            label={<div style={{ fontSize: 14, fontWeight: "bold" }}>Shelves</div>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showAisles}
                onChange={(event) => {
                  setShowAisles(event.target.checked);
                  aislesGroup.visible = event.target.checked;
                  canvas.setZoom(canvas.getZoom() + 0.00001);
                }}
                color="primary"
              />
            }
            label={<div style={{ fontSize: 14, fontWeight: "bold" }}>Aisles</div>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showCrossAisles}
                onChange={(event) => {
                  setShowCrossAisles(event.target.checked);
                  crossAislesGroup.visible = event.target.checked;
                  canvas.setZoom(canvas.getZoom() + 0.00001);
                }}
                color="primary"
              />
            }
            label={<div style={{ fontSize: 14, fontWeight: "bold" }}>Crossaisles</div>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showGeneratedCrossAisles}
                onChange={(event) => {
                  setShowGeneratedCrossAisles(event.target.checked);
                  generatedCrossAislesGroup.visible = event.target.checked;
                  canvas.setZoom(canvas.getZoom() + 0.00001);
                }}
                color="primary"
              />
            }
            label={<div style={{ fontSize: 14, fontWeight: "bold" }}>Generated crossaisles</div>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showNodes}
                onChange={(event) => {
                  setShowNodes(event.target.checked);
                  nodesGroup.visible = event.target.checked;
                  canvas.setZoom(canvas.getZoom() + 0.00001);
                }}
                color="primary"
              />
            }
            label={<div style={{ fontSize: 14, fontWeight: "bold" }}>Nodes</div>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showDepots}
                onChange={(event) => {
                  setShowDepots(event.target.checked);
                  depotsGroup.visible = event.target.checked;
                  canvas.setZoom(canvas.getZoom() + 0.00001);
                }}
                color="primary"
              />
            }
            label={<div style={{ fontSize: 14, fontWeight: "bold" }}>Depots</div>}
          />
        </div>
      </div>
    </motion.div>
  );
}

export default VisualizationModal;
