import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import axios from "axios";
import { AnimatePresence } from "framer-motion";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { AiOutlineBarChart, AiOutlineBorderOuter, AiOutlineDownload, AiOutlineInfoCircle } from "react-icons/ai";
import { BiError } from "react-icons/bi";
import { BsTriangleFill } from "react-icons/bs";
import { FaStopCircle } from "react-icons/fa";
import { IoIosArrowRoundForward, IoMdPlayCircle, IoMdTrash } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { MdKeyboardArrowDown, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { showConfirmDialogVar, warehouseVar } from "../../cache";
import CircularProgressbar from "../../components/CircularProgressbar/CircularProgressbar";
import DataInput from "../../components/DataInput/DataInput";
import OptimizationConfig from "../../components/OptimizationConfig/OptimizationConfig";
import ResultsModal from "../../components/ResultsModal/ResultsModal";
import Spinner from "../../components/Spinner/Spinner";
import VisualizationModal from "../../components/VisualizationModal/VisualizationModal";
import formatDate from "../../formatDate";
import {
  DeleteOptimizationMutation,
  DeleteOptimizationMutationVariables,
  GetOptimizationQuery,
  GetOptimizationQueryVariables,
  StartOptimizationMutation,
  StartOptimizationMutationVariables,
  StopOptimizationMutation,
  StopOptimizationMutationVariables,
} from "../../graphql/graphqlTypes";
import { DELETE_OPTIMIZATION, START_OPTIMIZATION, STOP_OPTIMIZATION } from "../../graphql/mutations";
import { GET_OPTIMIZATION } from "../../graphql/queries";
import { getCssVariable } from "../../utils";
import "./OptimizationDetails.css";

const getHoursFromSeconds = (s: number) => {
  const date = new Date(s * 1000);
  const hours = date.getUTCHours() > 9 ? date.getUTCHours() : "0" + date.getUTCHours();
  const minutes = date.getUTCMinutes() > 9 ? date.getUTCMinutes() : "0" + date.getUTCMinutes();
  const seconds = date.getUTCSeconds() > 9 ? date.getUTCSeconds() : "0" + date.getUTCSeconds();
  return hours + ":" + minutes + ":" + seconds;
};

type RoutesTableProps = {
  pickingRound?: GetOptimizationQuery["optimization"]["subGroups"][0]["solution"]["pickingRounds"][0];
  subGroup: GetOptimizationQuery["optimization"]["subGroups"][0];
  setShowPickingRound: Function;
  setShowRoute: Function;
  setShowSubGroup: Function;
  route?: GetOptimizationQuery["optimization"]["subGroups"][0]["solution"]["pickingRounds"][0]["routes"][0];
};

const downloadResultFile = (optimizationId, optimizationName) => {
  const token = localStorage.getItem("jwt-warehouse-optimization");
  try {
    axios({
      url: "/optimization/" + optimizationId,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", optimizationName + "-routes" + ".xlsx");
      document.body.appendChild(link);
      link.click();
    });
  } catch (err) {
    console.log(err);
  }
};

function RouteRow(props: RoutesTableProps) {
  const { route, pickingRound, setShowPickingRound, setShowRoute, setShowSubGroup, subGroup } = props;
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <tr
        className="table-row"
        onClick={() => {
          setExpanded(!expanded);
        }}>
        <MdKeyboardArrowDown
          style={{
            marginRight: 20,
            marginLeft: 10,
            fontSize: 30,
            transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
            color: getCssVariable("--color-black0"),
            transition: "all 0.5s",
            height: 60,
          }}
        />
        <td>{route.number}</td>
        <td>{getHoursFromSeconds(route.startTime)}</td>
        <td>{getHoursFromSeconds(route.deliveryTime)}</td>
        <td>{getHoursFromSeconds(route.finishTime)}</td>
        <td>{route.orderQuatity}</td>
        <td>{route.orderRowQuantity}</td>
        <td>{`${Math.round(route.maxWindowPeneltyCost / 60)} min (${Math.round(
          route.totalWindowPeneltyCost / 60,
        )})`}</td>
        <td>{`${Math.round(route.maxBoxWeightOverloadPeneltyCost / 60)} kg (${Math.round(
          route.totalBoxWeightOverloadPeneltyCost / 60,
        )})`}</td>
        <td>{`${Math.round(route.maxBoxVolumeOverloadPeneltyCost / 60)} m³ (${Math.round(
          route.totalBoxVolumeOverloadPeneltyCost / 60,
        )})`}</td>
        <td>{`${Math.round(route.maxBoxOverloadPeneltyCost / 60)} st (${Math.round(
          route.totalBoxOverloadPeneltyCost / 60,
        )})`}</td>
        <td className="action-col" style={{ width: 50 }}>
          <AiOutlineBorderOuter
            onClick={(e) => {
              e.stopPropagation();
              setShowRoute({ id: route.id, number: route.number + "" });
              setShowPickingRound(pickingRound ? { id: pickingRound.id, number: pickingRound.number + "" } : null);
              setShowSubGroup({ id: subGroup.id, startWindow: subGroup.startWindow });
            }}
            title="Visualization of route"
            className={`table-action`}
          />
        </td>
      </tr>

      {expanded && (
        <tr style={{ backgroundColor: getCssVariable("--color-white1") }}>
          <td colSpan={14} style={{ padding: 15, position: "relative" }}>
            <div
              className="arrow-down"
              style={{
                position: "absolute",
                left: "calc(50vw - 165px)",
                top: -8,
              }}></div>
            <div className="table-container" style={{ maxHeight: "none" }}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <th scope="col" style={{ width: 20 }}></th>
                  <th scope="col" style={{ width: "14%" }}>
                    Route stop #
                  </th>
                  <th scope="col" style={{ width: "14%" }}>
                    Picking time
                  </th>
                  <th scope="col" style={{ width: "14%" }}>
                    Order
                  </th>
                  <th scope="col" style={{ width: "14%" }}>
                    Order row
                  </th>
                  <th scope="col" style={{ width: "14%" }}>
                    Item
                  </th>
                  <th scope="col" style={{ width: "14%" }}>
                    Shelf
                  </th>
                  <th scope="col" style={{ width: "14%" }}>
                    Window Penalty
                  </th>
                </thead>
                <tbody style={{ backgroundColor: "white" }}>
                  {route.routeStops.map((rs, index) => {
                    return (
                      <tr>
                        <td></td>
                        <td>{index + 1}</td>
                        <td>{getHoursFromSeconds(rs.pickingTime)}</td>
                        <td>{rs.order.orgOrderId}</td>
                        <td>{rs.orderRow?.orgOrderRowId}</td>
                        <td>{rs.item.orgItemId}</td>
                        <td>{rs.shelf.orgShelfId}</td>
                        <td>{`${Math.round(rs.orderRowWindowPeneltyCost / 60)} min`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

function PickingRoundRow(props: RoutesTableProps) {
  const { pickingRound } = props;
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <tr
        className="table-row"
        onClick={() => {
          setExpanded(!expanded);
        }}>
        <MdKeyboardArrowDown
          style={{
            marginRight: 20,
            marginLeft: 10,
            fontSize: 30,
            transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
            color: getCssVariable("--color-black0"),
            transition: "all 0.5s",
            height: 60,
          }}
        />
        <td>{pickingRound.number}</td>
        <td>{getHoursFromSeconds(pickingRound.totalTime)}</td>
        <td>{pickingRound.routes.length}</td>
      </tr>
      {expanded && (
        <tr style={{ backgroundColor: getCssVariable("--color-white1") }}>
          <td colSpan={10} style={{ padding: 15, position: "relative" }}>
            <div
              className="arrow-down"
              style={{
                position: "absolute",
                left: "calc(50vw - 130px)",
                top: -8,
              }}></div>
            <div className="flex-center">
              <>
                <div className="table-container" style={{ width: "100%", zIndex: 0, maxHeight: "none" }}>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <th scope="col" style={{ width: 20 }}></th>
                      <th scope="col">Route #</th>
                      <th scope="col">Start time</th>
                      <th scope="col">Delivery time</th>
                      <th scope="col">Finish time</th>
                      <th scope="col">Orders</th>
                      <th scope="col">Order rows</th>

                      <th scope="col">
                        <div className="flex items-center" title={"Max per order row (total)"}>
                          Window Penalty <AiOutlineInfoCircle fontSize={18} className="ml-2" />
                        </div>
                      </th>
                      <th scope="col">
                        <div className="flex items-center" title={"Max per box (total)"}>
                          Weight Penalty <AiOutlineInfoCircle fontSize={18} className="ml-2" />
                        </div>
                      </th>
                      <th scope="col">
                        <div className="flex items-center" title={"Max per box (total)"}>
                          Volume Penalty <AiOutlineInfoCircle fontSize={18} className="ml-2" />
                        </div>
                      </th>
                      <th scope="col">
                        <div className="flex items-center" title={"Max per box (total)"}>
                          Item Penalty <AiOutlineInfoCircle fontSize={18} className="ml-2" />
                        </div>
                      </th>
                      <th scope="col" style={{ width: 50 }}></th>
                    </thead>
                    <tbody style={{ backgroundColor: "white" }}>
                      {pickingRound.routes &&
                        pickingRound.routes.map((route) => {
                          return <RouteRow {...props} route={route} />;
                        })}
                    </tbody>
                  </table>
                </div>
              </>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

type Props = {};
function OptimizationDetails(props: Props) {
  const {} = props;
  const warehouse = useReactiveVar(warehouseVar);
  let history = useHistory();
  const pathName = useLocation().pathname.replace(/\/$/, "");
  let { id } = useParams<any>();

  const [showRoute, setShowRoute] = useState<{ id: string; name: string }>(null);
  const [showPickingRound, setShowPickingRound] = useState<{ id: string; name: string }>(null);
  const [showSubGroup, setShowSubGroup] = useState<{ startWindow: string; name: string }>(null);
  const [expandedSubGroups, setExpandedSubGroups] = useState<string[]>([]);
  const [polling, setPolling] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [config, setConfig] = useState(null);
  const [showResults, setShowResults] = useState(null);

  const [startOptimizationMutation] = useMutation<StartOptimizationMutation, StartOptimizationMutationVariables>(
    START_OPTIMIZATION,
  );
  const [stopOptimizationMutation] = useMutation<StopOptimizationMutation, StopOptimizationMutationVariables>(
    STOP_OPTIMIZATION,
  );

  const [deleteOptimizationMutation] = useMutation<DeleteOptimizationMutation, DeleteOptimizationMutationVariables>(
    DELETE_OPTIMIZATION,
  );

  const {
    data: optimizationData,
    loading: optimizationLoading,
    refetch: optimizationRefetch,
  } = useQuery<GetOptimizationQuery, GetOptimizationQueryVariables>(GET_OPTIMIZATION, {
    variables: { optimizationId: id },
    fetchPolicy: "network-only",
    pollInterval: polling ? 5000 : 0,
  });

  useEffect(() => {
    // Start polling if build is in progress
    if (optimizationData) {
      if (optimizationData.optimization.status === "IN_PROGRESS") {
        setPolling(true);
      } else {
        setPolling(false);
      }
      if (optimizationData.optimization.optimizationConfig) {
        setConfig(optimizationData.optimization.optimizationConfig);
      }
    }
  }, [optimizationData]);

  if (optimizationLoading) {
    return <Spinner size={100} />;
  }

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {showConfig && (
          <OptimizationConfig
            onClose={() => {
              setShowConfig(false);
            }}
            readOnly={true}
            initialConfig={config}
          />
        )}

        {showRoute && showSubGroup && (
          <VisualizationModal
            dataset={{ id: optimizationData.optimization.dataset.id, name: optimizationData.optimization.dataset.name }}
            optimization={optimizationData.optimization}
            initRoute={showRoute}
            initPickingRound={showPickingRound}
            initSubGroup={showSubGroup}
            closeModal={() => {
              setShowRoute(null);
            }}
          />
        )}

        {showResults && (
          <ResultsModal
            optimization={showResults}
            closeModal={() => {
              setShowResults(null);
            }}
          />
        )}
      </AnimatePresence>
      {optimizationData && optimizationData.optimization && (
        <>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: "flex", width: "fit-content", alignItems: "center" }}>
              <MdKeyboardArrowLeft
                style={{
                  fontSize: 30,
                  marginRight: 20,
                  marginTop: 10,
                }}
                className="hover"
                onClick={() => {
                  history.push("/optimizations");
                }}
              />
              <div className="header" style={{ fontSize: 32 }}>
                {optimizationData.optimization.name}
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginRight: 20, marginTop: 10 }}>
              {optimizationData.optimization.status === "FINISHED" && (
                <AiOutlineBarChart
                  title="Show results"
                  className={`table-action`}
                  onClick={async (e) => {
                    e.stopPropagation();
                    setShowResults(optimizationData.optimization);
                  }}
                />
              )}
              {optimizationData.optimization.status === "FINISHED" && (
                <AiOutlineDownload
                  title="Download routes"
                  className={`table-action`}
                  onClick={async (e) => {
                    downloadResultFile(optimizationData.optimization.id, optimizationData.optimization.name);
                  }}
                />
              )}
              <IoSettingsOutline
                className="table-action"
                onClick={() => {
                  setShowConfig(true);
                }}
              />
              {optimizationData.optimization.status === "NOT_STARTED" && (
                <IoMdPlayCircle
                  title="Start Optimization"
                  className={`table-action ${optimizationData.optimization.status !== "NOT_STARTED" && "inactive"}`}
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (optimizationData.optimization.status === "NOT_STARTED") {
                      await startOptimizationMutation({
                        variables: {
                          optimizationId: optimizationData.optimization.id,
                        },
                      });
                      optimizationRefetch();
                    }
                  }}
                />
              )}
              {optimizationData && optimizationData.optimization.status === "IN_PROGRESS" && (
                <CircularProgressbar
                  startedAt={optimizationData.optimization.startedAt}
                  estimatedFinishedAt={optimizationData.optimization.estimatedFinishedAt}
                />
              )}
              {optimizationData.optimization.status === "IN_PROGRESS" && (
                <FaStopCircle
                  title="Stop Optimization"
                  className={`table-action ml-2`}
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (optimizationData.optimization.status === "IN_PROGRESS") {
                      showConfirmDialogVar({
                        action: "STOP_SAVE",
                        action2: "STOP_REMOVE",
                        type: "optimization",
                        onConfirm: async () => {
                          await stopOptimizationMutation({
                            variables: {
                              optimizationId: optimizationData.optimization.id,
                              keepSolution: true,
                            },
                          });
                          optimizationRefetch();
                          showConfirmDialogVar(null);
                        },
                        onConfirm2: async () => {
                          await stopOptimizationMutation({
                            variables: {
                              optimizationId: optimizationData.optimization.id,
                              keepSolution: false,
                            },
                          });
                          optimizationRefetch();
                          showConfirmDialogVar(null);
                        },
                        onCancel: () => {
                          showConfirmDialogVar(null);
                        },
                      });
                    }
                  }}
                />
              )}
              {optimizationData.optimization.status === "ERROR" && (
                <BiError
                  title="Optimization failed"
                  className={`table-action`}
                  style={{ opacity: 1, cursor: "default", color: getCssVariable("--color-black0") }}
                />
              )}
              {optimizationData.optimization.status !== "IN_PROGRESS" && (
                <IoMdTrash
                  title="Delete"
                  className={`table-action`}
                  style={{
                    color: getCssVariable("--color-black0"),
                  }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    showConfirmDialogVar({
                      action: "DELETE",
                      type: "optimization",
                      onConfirm: async () => {
                        await deleteOptimizationMutation({
                          variables: {
                            optimizationId: optimizationData.optimization.id,
                          },
                        });
                        showConfirmDialogVar(null);
                        history.push("/optimizations");
                      },
                      onCancel: () => {
                        showConfirmDialogVar(null);
                      },
                    });
                  }}
                />
              )}
            </div>
          </div>
          {/* <div style={{ display: "flex", marginTop: 20, marginLeft: -10 }}>
            {optimizationData.optimization.types.map((type) => (
              <div className="optimization-type">
                {type === "PICKING_ROUNDS" && "Picking rounds"}
                {type === "RESOURCE_ALLOCATION" && "Resource allocation"}
              </div>
            ))}
          </div> */}
          <div className="flex-center" style={{ marginTop: 40, marginBottom: 40 }}>
            <DataInput
              label="Created at"
              disabled={true}
              initValue={
                optimizationData.optimization.createdAt
                  ? formatDate(optimizationData.optimization.createdAt, true)
                  : "-"
              }
            />
            <DataInput
              label="Started at"
              disabled={true}
              initValue={
                optimizationData.optimization.startedAt
                  ? formatDate(optimizationData.optimization.startedAt, true)
                  : "-"
              }
            />
            <DataInput
              label="Finished at"
              disabled={true}
              initValue={
                optimizationData.optimization.finishedAt
                  ? formatDate(optimizationData.optimization.finishedAt, true)
                  : "-"
              }
            />
          </div>
          {optimizationData.optimization.subGroups &&
            optimizationData.optimization.subGroups.map((subGroup) => {
              return (
                <div
                  style={{
                    border: subGroup.solution ? "2px solid #319af3" : "none",
                    borderRadius: 4,
                    backgroundColor: getCssVariable("--color-blue0"),
                    opacity: subGroup.solution ? 1 : 0.4,
                    marginBottom: 10,
                    transition: "all 1s",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      cursor: subGroup.solution ? "pointer" : "default",
                      alignItems: "center",
                      padding: 20,
                      position: "relative",
                    }}
                    onClick={() => {
                      if (!subGroup.solution) return;
                      if (expandedSubGroups.includes(subGroup.id)) {
                        setExpandedSubGroups(expandedSubGroups.filter((sg) => sg !== subGroup.id));
                      } else {
                        setExpandedSubGroups([...expandedSubGroups, subGroup.id]);
                      }
                    }}>
                    <MdKeyboardArrowDown
                      style={{
                        marginRight: 20,
                        fontSize: 30,
                        transform: expandedSubGroups.includes(subGroup.id) ? "rotate(180deg)" : "rotate(0deg)",
                        color: "white",
                        transition: "all 0.5s",
                        opacity: subGroup.solution ? 1 : 0,
                      }}
                    />
                    <div className="header" style={{ fontSize: 16, color: "white" }}>
                      <span style={{ fontSize: 20, marginRight: 10 }}>
                        {moment(parseInt(subGroup.startWindow)).format("dddd")}
                      </span>
                      <span>{moment(parseInt(subGroup.startWindow)).format("YYYY-MM-DD")}</span>
                      <span style={{ marginLeft: 14, fontSize: 12 }}>{subGroup.orders.length + " orders"}</span>
                    </div>
                    <span style={{ color: "white", marginLeft: 20 }}>{"(subgroupid: " + subGroup.id + ")"} </span>
                    {!subGroup.solution && (
                      <div className="header" style={{ position: "absolute", right: 20, color: "white" }}>
                        Not calculated
                      </div>
                    )}
                  </div>

                  {subGroup.solution && expandedSubGroups.includes(subGroup.id) && (
                    <>
                      {/* <div className="header" style={{ fontSize: 20, marginTop: 60, marginBottom: 20 }}>
                        Picking rounds
                      </div> */}
                      <div className="table-container" style={{ maxHeight: "none" }}>
                        {subGroup.solution.pickingRounds.length ? (
                          <table style={{ width: "100%", borderCollapse: "collapse" }}>
                            <thead>
                              <th scope="col" style={{ width: 20 }}></th>
                              <th scope="col" style={{ width: "33%" }}>
                                Picking round #
                              </th>
                              <th scope="col" style={{ width: "33%" }}>
                                Total time
                              </th>
                              <th scope="col" style={{ width: "33%" }}>
                                Routes
                              </th>
                            </thead>
                            <tbody style={{ backgroundColor: "white" }}>
                              {subGroup.solution &&
                                subGroup.solution.pickingRounds.map((row) => {
                                  return (
                                    <PickingRoundRow
                                      pickingRound={row}
                                      subGroup={subGroup}
                                      setShowPickingRound={setShowPickingRound}
                                      setShowRoute={setShowRoute}
                                      setShowSubGroup={setShowSubGroup}
                                    />
                                  );
                                })}
                            </tbody>
                          </table>
                        ) : (
                          <table style={{ width: "100%", borderCollapse: "collapse" }}>
                            <thead>
                              <th scope="col" style={{ width: 20 }}></th>
                              <th scope="col">Route #</th>
                              <th scope="col">Start time</th>
                              <th scope="col">Delivery time</th>
                              <th scope="col">Route time</th>
                              <th scope="col">Orders</th>
                              <th scope="col">Order rows</th>
                              <th scope="col">
                                <div className="flex items-center" title={"Max per order row (total)"}>
                                  Window Penalty <AiOutlineInfoCircle fontSize={18} className="ml-2" />
                                </div>
                              </th>
                              <th scope="col">
                                <div className="flex items-center" title={"Max per box (total)"}>
                                  Weight Penalty <AiOutlineInfoCircle fontSize={18} className="ml-2" />
                                </div>
                              </th>
                              <th scope="col">
                                <div className="flex items-center" title={"Max per box (total)"}>
                                  Volume Penalty <AiOutlineInfoCircle fontSize={18} className="ml-2" />
                                </div>
                              </th>
                              <th scope="col">
                                <div className="flex items-center" title={"Max per box (total)"}>
                                  Item Penalty <AiOutlineInfoCircle fontSize={18} className="ml-2" />
                                </div>
                              </th>
                              <th scope="col" style={{ width: 50 }}></th>
                            </thead>
                            <tbody style={{ backgroundColor: "white" }}>
                              {subGroup.solution.routes &&
                                subGroup.solution.routes.map((route) => {
                                  return (
                                    <RouteRow
                                      route={route}
                                      setShowPickingRound={setShowPickingRound}
                                      setShowRoute={setShowRoute}
                                      setShowSubGroup={setShowSubGroup}
                                      subGroup={subGroup}
                                    />
                                  );
                                })}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </>
                  )}
                </div>
              );
            })}

          <div className="header" style={{ fontSize: 20, marginTop: 60, marginBottom: 20 }}>
            Dataset
          </div>
          <div className="table-container" style={{ maxHeight: "none" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <th scope="col" style={{ width: "100%" }}>
                  {optimizationData.optimization.dataset.name}
                </th>
                <th scope="col" style={{ width: 50 }}></th>
              </thead>
              <tbody style={{ backgroundColor: "white" }}>
                <tr
                  className="table-row"
                  onClick={() => {
                    history.push("/layouts/" + optimizationData.optimization.dataset.id);
                  }}>
                  <td>{"Layout"}</td>
                  <td className="action-col" style={{ width: 50 }}>
                    <IoIosArrowRoundForward title="Watch dataset" className={`table-action`} />
                  </td>
                </tr>
                <tr
                  className="table-row"
                  onClick={() => {
                    history.push("/resources/" + optimizationData.optimization.dataset.id);
                  }}>
                  <td>{"Resource"}</td>
                  <td className="action-col" style={{ width: 50 }}>
                    <IoIosArrowRoundForward title="Watch dataset" className={`table-action`} />
                  </td>
                </tr>
                <tr
                  className="table-row"
                  onClick={() => {
                    history.push("/jobs/" + optimizationData.optimization.dataset.id);
                  }}>
                  <td>{"Job"}</td>
                  <td className="action-col" style={{ width: 50 }}>
                    <IoIosArrowRoundForward title="Watch dataset" className={`table-action`} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}

export default OptimizationDetails;
