import React, { useEffect } from "react";
import CircularProgress, { CircularProgressProps } from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { getCssVariable } from "../../utils";
import moment from "moment";

type Props = {
  estimatedFinishedAt: string;
  startedAt: string;
};

function CircularProgressbar(props: Props) {
  const { estimatedFinishedAt, startedAt } = props;
  const [progress, setProgress] = React.useState(0);

  // Format timestamp dates
  const start = moment(new Date(parseInt(startedAt)));
  const end = moment(new Date(parseInt(estimatedFinishedAt)));

  const initialDiff = Math.abs(start.diff(end, "s"));

  useEffect(() => {
    if (end > moment(new Date())) {
      const timer = setInterval(() => {
        const currentDiff = Math.abs(moment(new Date()).diff(end, "s"));
        const newValue = 100 - 100 * (currentDiff / initialDiff);
        if (Math.round(newValue) < 100) {
          setProgress(newValue);
        } else {
          clearInterval(timer);
        }
      }, 2000);
      return () => {
        clearInterval(timer);
      };
    } else {
      setProgress(99);
    }
  }, []);

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        style={{ height: 40, width: 40, marginRight: 10, color: getCssVariable("--color-blue0") }}
        variant="determinate"
        value={progress}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        height={40}
        width={40}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Typography style={{ fontSize: 10 }} variant="caption" component="div" color="textSecondary">{`${Math.round(
          progress,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressbar;
