import { Checkbox, FormControlLabel, MenuItem, TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { IoMdClose, IoMdCheckmark } from "react-icons/io";
import "./TimeWindowSettings.css";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_TIME_WINDOW } from "../../graphql/mutations";
import {
  GetWarehouseTimeWindowQuery,
  GetWarehouseTimeWindowQueryVariables,
  UpdateTimeWindowMutation,
  UpdateTimeWindowMutationVariables,
} from "../../graphql/graphqlTypes";
import { GET_WAREHOUSE_TIME_WINDOW } from "../../graphql/queries";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginRight: 20,
      width: 100,
    },
  }),
);

type Props = {
  warehouseId: string;
};

function TimeWindowSettings(props: Props) {
  const { warehouseId } = props;

  const [onlyWeekdays, setOnlyWeekdays] = useState(false);
  const [excludedDays, setExcludedDays] = useState([]);
  const [startWindow, setStartWindow] = useState("");
  const [endWindow, setEndWindow] = useState("");
  const [editing, setEditing] = useState(false);

  const [updateTimeWindow] = useMutation<UpdateTimeWindowMutation, UpdateTimeWindowMutationVariables>(
    UPDATE_TIME_WINDOW,
  );
  const { data: warehouseTimeWindow } = useQuery<GetWarehouseTimeWindowQuery, GetWarehouseTimeWindowQueryVariables>(
    GET_WAREHOUSE_TIME_WINDOW,
    {
      variables: { warehouseId: warehouseId },
    },
  );

  useEffect(() => {
    if (warehouseTimeWindow) {
      setOnlyWeekdays(warehouseTimeWindow.warehouse.onlyWeekdays);
      setExcludedDays(warehouseTimeWindow.warehouse.excludedDays);
      setStartWindow(warehouseTimeWindow.warehouse.startWindow);
      setEndWindow(warehouseTimeWindow.warehouse.endWindow);
    }
  }, [warehouseTimeWindow]);

  const classes = useStyles();

  console.log(startWindow);

  let validInput = false;

  if (startWindow && endWindow) {
    if (parseInt(endWindow.split(":")[0]) > parseInt(startWindow.split(":")[0])) {
      validInput = true;
    } else if (parseInt(endWindow.split(":")[0]) === parseInt(startWindow.split(":")[0])) {
      if (parseInt(endWindow.split(":")[1]) > parseInt(startWindow.split(":")[1])) {
        validInput = true;
      }
    }
  }

  return (
    <>
      {
        <div style={{ padding: "0px 14px 14px 14px" }}>
          <div style={{ display: "flex" }}>
            <form className={classes.container} noValidate>
              <TextField
                id="start-window-time"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(newValue) => {
                  setEditing(true);
                  setStartWindow(newValue.target.value);
                }}
                label="Start window"
                type="time"
                value={startWindow}
                className={classes.textField}
              />
            </form>
            <form className={classes.container} noValidate>
              <TextField
                id="end-window-time"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(newValue) => {
                  setEditing(true);
                  setEndWindow(newValue.target.value);
                }}
                label="End window"
                type="time"
                value={endWindow}
                className={classes.textField}
              />
            </form>
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlyWeekdays}
                  onChange={(event) => {
                    setEditing(true);
                    setOnlyWeekdays(event.target.checked);
                  }}
                  name="weekDays"
                  color="primary"
                />
              }
              label="Only weekdays"
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", position: "relative", height: 40 }}>
            <div className="mr-2">Exclude dates</div>
            <div id="date-picker" className="cursor-pointer">
              <TextField
                type="date"
                className={classes.textField}
                onChange={(e) => {
                  if (!excludedDays.includes(e.target.value)) {
                    setEditing(true);
                    setExcludedDays([...excludedDays, e.target.value]);
                  }
                }}
              />
            </div>
          </div>
          {!!excludedDays.length && (
            <Autocomplete
              multiple
              noOptionsText={"No exluded dates"}
              size="small"
              style={{ width: 420 }}
              options={excludedDays}
              onChange={(e, value) => {
                setEditing(true);
                setExcludedDays(value);
              }}
              value={excludedDays}
              renderInput={(params) => <TextField {...params} variant="standard" />}
            />
          )}
          <div
            className={`wide-button ${(!editing || !validInput) && "disabled"}`}
            style={{ width: 80, marginTop: 20 }}
            onClick={async () => {
              if (editing && validInput) {
                await updateTimeWindow({
                  variables: {
                    warehouseId: warehouseId,
                    startWindow: startWindow,
                    endWindow: endWindow,
                    excludedDates: excludedDays,
                    onlyWeekdays: onlyWeekdays,
                  },
                  refetchQueries: [
                    {
                      query: GET_WAREHOUSE_TIME_WINDOW,
                      variables: {
                        warehouseId: warehouseId,
                      },
                    },
                  ],
                });
                setEditing(false);
              }
            }}>
            Save
          </div>
        </div>
      }
    </>
  );
}

export default TimeWindowSettings;
