import { MenuItem, TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import "./Spinner.css";

type Props = {
  size: string | number;
};

function Spinner(props: Props) {
  const { size } = props;

  return (
    <div className="flex-center">
      <div className="loader-container" style={{ width: size, height: size }}>
        <div className="loader"></div>
      </div>
    </div>
  );
}

export default Spinner;
