import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../components/Navbar/Navbar";
import SortableTree from "../../components/SortableTree/SortableTree";
import "./Settings.css";
import DataTable from "../../components/DataTable/DataTable";
import DataInput from "../../components/DataInput/DataInput";
import DataCombo from "../../components/DataCombo/DataCombo";
import TimeWindowSettings from "../../components/TimeWindowSettings/TimeWindowSettings";
import { CgListTree } from "react-icons/cg";
import { IoMdAdd, IoMdClose, IoMdTrash } from "react-icons/io";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ACCOUNT, GET_ACCOUNTS, GET_DIVISION, GET_DIVISIONS, GET_USER } from "../../graphql/queries";
import {
  CREATE_ACCOUNT,
  CREATE_DIVISION,
  DELETE_ACCOUNT,
  DELETE_DIVISION,
  UPDATE_ACCOUNT,
  UPDATE_DIVISION,
} from "../../graphql/mutations";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MdEdit } from "react-icons/md";
import { BiArrowToLeft } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { getCssVariable } from "../../utils";
import {
  CreateAccountMutation,
  CreateAccountMutationVariables,
  DeleteAccountMutation,
  DeleteAccountMutationVariables,
  GetAccountQuery,
  GetAccountQueryVariables,
  GetAccountsQuery,
  GetDivisionQuery,
  GetDivisionQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
} from "../../graphql/graphqlTypes";
import { motion } from "framer-motion";
import WMSSettings from "../../components/WMSSettings/WMSSettings";
import DistanceMatrixSettings from "../../components/DistanceMatrixSettings/DistanceMatrixSettings";

function Settings() {
  const history = useHistory();
  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: "#FFFFFF",
      },
    },
  });

  const [showOrganization, setShowOrganization] = useState(false);
  const [selected, setSelected] = useState<{
    type: string;
    id: string;
    parent?: string;
  } | null>(null);
  const [comboAccount, setComboAccount] = useState<GetUserQuery["user"]["account"]>(null);
  const [tab, setTab] = useState(0);

  const settingsEditContainerRef = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);

  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
  });

  const [updateAccount] = useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UPDATE_ACCOUNT);
  const [createAccount] = useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CREATE_ACCOUNT);
  const [deleteAccount] = useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DELETE_ACCOUNT);
  const [updateDivision] = useMutation(UPDATE_DIVISION);
  const [deleteDivision] = useMutation(DELETE_DIVISION);
  const [createDivision] = useMutation(CREATE_DIVISION);

  useEffect(() => {
    if (userData) {
      if (!userData.user.permissions.includes("ViewDivisions")) {
        history.push({
          pathname: "/",
        });
      }
      setShowOrganization(true);
      // Set initial combo value
      setComboAccount(userData.user.account);
    }
  }, [userData]);

  let eventListener = null;
  useEffect(() => {
    if (eventListener) {
      window.removeEventListener("resize", eventListener);
    }
    const handleResize = () => {
      if (showOrganization) {
        setTableWidth(window.innerWidth - 568);
      } else {
        setTableWidth(window.innerWidth - 205);
      }
    };
    eventListener = window.addEventListener("resize", handleResize);
    handleResize();
  }, [showOrganization]);

  const { data: accountsData } = useQuery<GetAccountsQuery>(GET_ACCOUNTS, {
    fetchPolicy: "network-only",
  });

  const {
    data: divisionData,
    loading: divisionDataLoading,
    refetch: divisionRefetch,
    error: divisionError,
  } = useQuery<GetDivisionQuery, GetDivisionQueryVariables>(GET_DIVISION, {
    variables: {
      divisionId: selected && selected.type === "division" ? selected.id : "abc",
      // divisionId: selected && selected.type === "division" ? selected.id : "",
    },
    skip:
      !selected || selected.id === "new-division" || selected.id === "new-warehouse" || selected.type !== "division",
    fetchPolicy: "network-only",
  });

  const {
    data: accountData,
    loading: accountDataLoading,
    refetch: accountRefetch,
  } = useQuery<GetAccountQuery, GetAccountQueryVariables>(GET_ACCOUNT, {
    variables: {
      accountId: selected && selected.type === "account" ? selected.id : null,
    },
    skip: !selected || selected.id === "new-account" || selected.type !== "account",
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    // Set tab to 0 when selecting new division
    if (selected) {
      setTab(0);
    }
  }, [selected]);

  return (
    <>
      <div className="App" id="settings">
        <div className="settings-container-wrapper">
          {userData && userData.user.permissions.includes("ViewDivisions") && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="organization-container">
              <div className="organization-container-inner">
                {userData && userData.user.permissions.includes("ViewAccounts") && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 20,
                    }}>
                    <Autocomplete
                      id="account-combo"
                      options={accountsData ? accountsData.accounts : []}
                      getOptionLabel={(option: any) => option.name}
                      getOptionSelected={(option, value) => {
                        if (option && value) return option.id === value.id;
                      }}
                      value={comboAccount}
                      onChange={(event: any, newValue: any) => {
                        if (newValue) {
                          setComboAccount(newValue);
                          setSelected(null);
                        }
                      }}
                      style={{ padding: 5, width: "80%" }}
                      renderInput={(params) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <TextField label="Account" {...params} />
                        </div>
                      )}
                    />
                    <div className="flex-center">
                      <MdEdit
                        title="Edit account"
                        className="account-edit"
                        style={{
                          opacity: !comboAccount ? 0.5 : 1,
                          cursor: !comboAccount ? "default" : "pointer",
                        }}
                        onClick={(e) => {
                          if (!comboAccount) return;
                          setSelected({ type: "account", id: comboAccount.id });
                        }}
                      />

                      {userData && userData.user.permissions.includes("CreateAccounts") && (
                        <div
                          className="add-button-circle"
                          style={{
                            position: "static",
                            marginLeft: 10,
                            marginTop: 14,
                            opacity: !comboAccount ? 0.5 : 1,
                            cursor: !comboAccount ? "default" : "pointer",
                          }}
                          onClick={() => {
                            if (!comboAccount) return;
                            setComboAccount(null);
                            setSelected({ type: "account", id: "new-account" });
                          }}>
                          <IoMdAdd title="Add account" />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <SortableTree
                  setSelectedCallback={setSelected}
                  accountId={comboAccount && comboAccount.id}
                  selectedId={selected && selected.id}
                  selectedType={divisionData && divisionData.division.type}
                />
              </div>
            </motion.div>
          )}
          <div
            ref={settingsEditContainerRef}
            className="edit-container"
            style={{
              backgroundColor: selected ? getCssVariable("--color-white0") : getCssVariable("--color-white1"),
              transition: "all 1s",
              // width: selected ? '100%' : '50%',
              // maxWidth: 'calc(100vw - 500px)'
            }}>
            {selected && (
              <div style={{ padding: "0px 40px", width: "100%" }}>
                {/* DIVISION & DIVISION OVERVIEW EDIT AREA */}
                {selected && (selected.type === "division" || selected.type === "overview") && (
                  <>
                    {selected.type === "division" && (
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          minHeight: 60,
                        }}>
                        {!divisionDataLoading && (
                          <>
                            {divisionError && <div className="info-text">Not authorized to view this item</div>}
                            {!divisionError && (
                              <DataInput
                                label={
                                  selected.id === "new-warehouse" ||
                                  (divisionData && divisionData.division.type === "WAREHOUSE")
                                    ? "Warehouse name"
                                    : "Location name"
                                }
                                disabled={
                                  userData && userData.user.permissions.includes("EditDivisions") ? false : true
                                }
                                autoFocus={true}
                                initValue={
                                  selected.id === "new-division" || selected.id === "new-warehouse"
                                    ? null
                                    : divisionData && divisionData.division.name
                                }
                                onConfirm={(name: String) => {
                                  if (selected.id === "new-division" || selected.id === "new-warehouse") {
                                    createDivision({
                                      variables: {
                                        name: name,
                                        parentDivisionId: selected.parent,
                                        isWarehouse: selected.id === "new-warehouse",
                                      },
                                      refetchQueries: [
                                        {
                                          query: GET_DIVISIONS,
                                          variables: {
                                            accountId: comboAccount.id,
                                          },
                                        },
                                      ],
                                    }).then((res) => {
                                      divisionRefetch({
                                        divisionId: res.data.createDivision.id,
                                      }).then(() => {
                                        setSelected({
                                          id: res.data.createDivision.id,
                                          type: "division",
                                        });
                                      });
                                    });
                                  } else {
                                    updateDivision({
                                      variables: {
                                        divisionId: selected.id,
                                        name: name,
                                      },
                                      refetchQueries: [
                                        {
                                          query: GET_DIVISIONS,
                                          variables: {
                                            accountId: comboAccount.id,
                                          },
                                        },
                                      ],
                                    });
                                    setSelected({
                                      id: selected.id,
                                      type: "division",
                                    });
                                  }
                                }}
                              />
                            )}
                          </>
                        )}
                        {userData &&
                          userData.user.permissions.includes("DeleteDivisions") &&
                          selected.id !== "new-division" &&
                          selected.id !== "new-warehouse" && (
                            <div
                              style={{
                                width: 60,
                                cursor: divisionData && divisionData.division.deleteable ? "pointer" : "default",
                                opacity: divisionData && divisionData.division.deleteable ? 1 : 0.5,
                              }}
                              onClick={() => {
                                if (!divisionData || !divisionData.division.deleteable) {
                                  return;
                                }
                                deleteDivision({
                                  variables: { divisionId: selected.id },
                                  refetchQueries: [
                                    {
                                      query: GET_DIVISIONS,
                                      variables: {
                                        accountId: comboAccount.id,
                                      },
                                    },
                                  ],
                                });
                                // Deselect
                                setSelected(null);
                              }}>
                              <IoMdTrash title="Delete" className="trash" />
                            </div>
                          )}
                      </div>
                    )}

                    {selected.type === "overview" && (
                      <div style={{ fontSize: 24, color: getCssVariable("--color-black0") }}>Overview</div>
                    )}

                    {!divisionDataLoading &&
                      !divisionError &&
                      selected.id !== "new-division" &&
                      selected.id !== "new-warehouse" && (
                        <>
                          <MuiThemeProvider theme={theme}>
                            <Tabs
                              style={{ marginBottom: 20, marginTop: 30 }}
                              value={tab}
                              indicatorColor="secondary"
                              textColor="secondary"
                              onChange={(event, newValue) => {
                                setTab(newValue);
                              }}
                              disableRipple={true}>
                              {userData && userData.user.permissions.includes("EditUsers") && (
                                <Tab style={{ minWidth: 100 }} disableRipple={true} label="Users" />
                              )}
                              {userData && userData.user.permissions.includes("EditUsers") && (
                                <Tab style={{ minWidth: 100 }} disableRipple={true} label="Roles" />
                              )}
                              {userData && userData.user.permissions.includes("EditUsers") && (
                                <Tab style={{ minWidth: 100 }} disableRipple={true} label="Permissions" />
                              )}
                              {divisionData &&
                                divisionData.division.type === "WAREHOUSE" &&
                                userData &&
                                userData.user.permissions.includes("EditWarehouses") && (
                                  <Tab style={{ minWidth: 100 }} disableRipple={true} label="Time window" />
                                )}
                              {divisionData &&
                                divisionData.division.type === "WAREHOUSE" &&
                                userData &&
                                userData.user.permissions.includes("EditWarehouses") && (
                                  <Tab style={{ minWidth: 100 }} disableRipple={true} label="Distance matrix" />
                                )}
                              {divisionData &&
                                divisionData.division.type === "WAREHOUSE" &&
                                userData &&
                                userData.user.permissions.includes("EditWarehouses") && (
                                  <Tab style={{ minWidth: 100 }} disableRipple={true} label="WMS" />
                                )}
                            </Tabs>
                          </MuiThemeProvider>

                          {tab === 0 && userData && userData.user.permissions.includes("EditUsers") && (
                            <DataTable
                              usersTable={true}
                              division={selected.id}
                              account={comboAccount}
                              refetchDivisionCallback={divisionRefetch}
                            />
                          )}
                          {tab === 1 && userData && userData.user.permissions.includes("EditUsers") && (
                            <DataTable
                              rolesTable={true}
                              division={selected.id}
                              account={comboAccount}
                              refetchDivisionCallback={divisionRefetch}
                            />
                          )}
                          {tab === 2 && userData && userData.user.permissions.includes("EditUsers") && (
                            <DataTable
                              permissionsTable={true}
                              division={selected.id}
                              tableWidth={tableWidth}
                              account={comboAccount}
                            />
                          )}
                          {divisionData &&
                            divisionData.division.type === "WAREHOUSE" &&
                            (tab === 3 ||
                              (tab === 0 &&
                                userData &&
                                !userData.user.permissions.includes("EditUsers") &&
                                userData.user.permissions.includes("EditWarehouses"))) && (
                              <TimeWindowSettings warehouseId={divisionData.division.id} />
                            )}
                          {divisionData &&
                            divisionData.division.type === "WAREHOUSE" &&
                            (tab === 4 ||
                              (tab === 1 &&
                                userData &&
                                !userData.user.permissions.includes("EditUsers") &&
                                userData.user.permissions.includes("EditWarehouses"))) && (
                              <DistanceMatrixSettings warehouseId={divisionData.division.id} />
                            )}
                          {divisionData &&
                            divisionData.division.type === "WAREHOUSE" &&
                            (tab === 5 ||
                              (tab === 2 &&
                                userData &&
                                !userData.user.permissions.includes("EditUsers") &&
                                userData.user.permissions.includes("EditWarehouses"))) && (
                              <WMSSettings warehouseId={divisionData.division.id} />
                            )}
                        </>
                      )}
                  </>
                )}

                {/* ACCOUNT EDIT AREA */}
                {selected && selected.type === "account" && (
                  <>
                    <div
                      className="flex-center"
                      style={{
                        position: "relative",
                        marginBottom: 40,
                        width: "100%",
                      }}>
                      {!accountDataLoading && (
                        <DataInput
                          label="Account name"
                          autoFocus={true}
                          initValue={accountData && selected.id !== "new-account" && accountData.account.name}
                          onConfirm={(name: string) => {
                            if (selected.id === "new-account") {
                              createAccount({ variables: { name: name } }).then((res) => {
                                accountRefetch({
                                  accountId: res.data.createAccount.id,
                                }).then(() => {
                                  setSelected({
                                    id: res.data.createAccount.id,
                                    type: "account",
                                  });
                                  setComboAccount({
                                    id: res.data.createAccount.id,
                                    name: res.data.createAccount.name,
                                  });
                                });
                              });
                            } else {
                              updateAccount({
                                variables: {
                                  accountId: selected.id,
                                  name: name,
                                },
                                refetchQueries: [
                                  {
                                    query: GET_ACCOUNT,
                                    variables: { accountId: selected.id },
                                  },
                                ],
                              });
                            }
                          }}
                        />
                      )}
                      {userData &&
                        userData.user.permissions.includes("DeleteAccounts") &&
                        selected.id !== "new-account" && (
                          <div
                            style={{
                              width: 60,
                              cursor: accountData && accountData.account.deleteable ? "pointer" : "default",
                              opacity: accountData && accountData.account.deleteable ? 1 : 0.5,
                            }}
                            onClick={async () => {
                              if (!accountData || !accountData.account.deleteable) {
                                return;
                              }
                              await deleteAccount({
                                variables: { accountId: selected.id },
                                refetchQueries: [{ query: GET_ACCOUNTS }],
                              });
                              setSelected(null);
                              setComboAccount(null);
                            }}>
                            <IoMdTrash title="Delete" className="trash" />
                          </div>
                        )}
                    </div>
                    {/* Cannot disable own account */}
                    {userData.user.account.id !== selected.id && selected.id !== "new-account" && (
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          marginBottom: 40,
                          width: "100%",
                        }}>
                        {!accountDataLoading && (
                          <DataCombo
                            label={"Status"}
                            disabled={false}
                            initValue={accountData && selected.id !== "new-account" && accountData.account.disabled}
                            options={[
                              { name: "Disabled", value: true },
                              { name: "Enabled", value: false },
                            ]}
                            onConfirm={(disabled: boolean) => {
                              if (selected.id !== "new-account") {
                                updateAccount({
                                  variables: {
                                    accountId: selected.id,
                                    disabled: disabled,
                                  },
                                });
                              }
                            }}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
