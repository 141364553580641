import React, { useState, useEffect } from "react";
import "./DistanceMatrixSettings.css";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_DISTANCE_MATRIX } from "../../graphql/mutations";
import {
  GetWarehouseDistanceMatrixQuery,
  GetWarehouseDistanceMatrixQueryVariables,
  UpdateDistanceMatrixMutation,
  UpdateDistanceMatrixMutationVariables,
} from "../../graphql/graphqlTypes";
import { GET_WAREHOUSE_DISTANCE_MATRIX } from "../../graphql/queries";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

type Props = {
  warehouseId: string;
};

function DistanceMatrixSettings(props: Props) {
  const { warehouseId } = props;

  const DistanceMatrixes = [
    { name: "F", id: "F" },
    { name: "LM", id: "LM" },
    { name: "O", id: "O" },
    { name: "S", id: "S" },
    { name: "SA", id: "SA" },
  ];

  const [editing, setEditing] = useState(false);
  const [comboDistanceMatrix, setComboDistanceMatrix] = useState(null);

  const [updateDistanceMatrix] = useMutation<UpdateDistanceMatrixMutation, UpdateDistanceMatrixMutationVariables>(
    UPDATE_DISTANCE_MATRIX,
  );
  const { data: warehouseDistanceMatrixdata } = useQuery<
    GetWarehouseDistanceMatrixQuery,
    GetWarehouseDistanceMatrixQueryVariables
  >(GET_WAREHOUSE_DISTANCE_MATRIX, {
    variables: { warehouseId: warehouseId },
  });

  useEffect(() => {
    if (warehouseDistanceMatrixdata) {
      const distanceMatrix = DistanceMatrixes.find((dm) => {
        if (dm.id === warehouseDistanceMatrixdata.warehouse.distanceMatrix) {
          return true;
        }
      });
      setComboDistanceMatrix(distanceMatrix);
    }
  }, [warehouseDistanceMatrixdata]);

  return (
    <>
      {
        <div style={{ padding: "0px 14px 14px 14px" }}>
          <Autocomplete
            id="wms-combo"
            options={DistanceMatrixes}
            getOptionLabel={(option: any) => option.name}
            getOptionSelected={(option, value) => {
              if (option && value) return option.id === value.id;
            }}
            value={comboDistanceMatrix}
            onChange={(event: any, newValue: any) => {
              setEditing(true);
              setComboDistanceMatrix(newValue);
            }}
            style={{ padding: 5, width: 200 }}
            renderInput={(params) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField label="Distance matrix" {...params} />
              </div>
            )}
          />
          <div
            className={`wide-button ${!editing && "disabled"}`}
            style={{ width: 80, marginTop: 20 }}
            onClick={async () => {
              await updateDistanceMatrix({
                variables: {
                  warehouseId: warehouseId,
                  distanceMatrix: comboDistanceMatrix ? comboDistanceMatrix.id : null,
                },
                refetchQueries: [
                  {
                    query: GET_WAREHOUSE_DISTANCE_MATRIX,
                    variables: {
                      warehouseId: warehouseId,
                    },
                  },
                ],
              });
              setEditing(false);
            }}>
            Save
          </div>
        </div>
      }
    </>
  );
}

export default DistanceMatrixSettings;
