import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { IoMdAdd, IoMdCheckmark, IoMdEye } from "react-icons/io";
import { useHistory, useLocation } from "react-router-dom";
import { warehouseVar } from "../../cache";
import formatDate from "../../formatDate";
import VisualizationModal from "../../components/VisualizationModal/VisualizationModal";
import {
  CalculateDistanceMatrixMutation,
  CalculateDistanceMatrixMutationVariables,
  GetDatasetsQuery,
  Query,
} from "../../graphql/graphqlTypes";
import { CALCULATE_DISTANCE_MATRIX } from "../../graphql/mutations";
import { getCssVariable } from "../../utils";
import "./DatasetTable.css";
import { AiOutlineBorderOuter } from "react-icons/ai";
import Spinner from "../../components/Spinner/Spinner";
import { AnimatePresence } from "framer-motion";

type Props = {
  data: GetDatasetsQuery["datasets"];
  refetch: Function;
};

function DatasetTable(props: Props) {
  const { data, refetch } = props;
  const [calculateDistanceMatrixMutation] = useMutation<
    CalculateDistanceMatrixMutation,
    CalculateDistanceMatrixMutationVariables
  >(CALCULATE_DISTANCE_MATRIX);
  const warehouse = useReactiveVar(warehouseVar);
  let history = useHistory();
  const pathName = useLocation().pathname.replace(/\/$/, "");
  const [showLayout, setShowLayout] = useState<{ id: string; name: string }>(null);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <AnimatePresence exitBeforeEnter={true}>
        {showLayout && (
          <VisualizationModal
            dataset={showLayout}
            closeModal={() => {
              setShowLayout(null);
            }}
          />
        )}
      </AnimatePresence>
      <div className="table-container tableFixHead">
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <th scope="col" style={{ width: "50%" }}>
              Name
            </th>
            <th scope="col" style={{ width: "50%" }}>
              Created at
            </th>
            {/* {pathName === "/layouts" && (
              <th scope="col" style={{ width: "33%" }}>
                Distance matrix
              </th>
            )} */}
            {pathName === "/layouts" && (
              <th scope="col" style={{ width: 50 }}>
                {/* Mail */}
              </th>
            )}
          </thead>
          <tbody style={{ backgroundColor: "white" }}>
            {!data && (
              <td colSpan={10}>
                <Spinner size={100} />
              </td>
            )}
            {data &&
              data.map((row) => {
                return (
                  <tr
                    className="table-row"
                    onClick={() => {
                      history.push({
                        pathname: pathName + "/" + row.id,
                      });
                    }}>
                    <td>{row.name}</td>
                    <td>{formatDate(row.createdAt)}</td>
                    {/* {pathName === "/layouts" && (
                      <td>
                        <div style={{ display: "flex" }}>
                          {row.distanceMatrixStatus === "NOT_STARTED" && (
                            <div
                              className="wide-button"
                              style={{ width: 100 }}
                              onClick={async (e) => {
                                e.stopPropagation();
                                await calculateDistanceMatrixMutation({
                                  variables: { warehouseId: warehouse.id, datasetId: row.id },
                                });
                                refetch();
                              }}>
                              Calculate
                            </div>
                          )}
                          {row.distanceMatrixStatus === "IN_PROGRESS" && (
                            <div className="wide-button" style={{ width: 100, opacity: 0.5 }}>
                              <Spinner size={30} />
                            </div>
                          )}
                          {row.distanceMatrixStatus === "FINISHED" && (
                            <div className="wide-button disabled" style={{ width: 100 }}>
                              <IoMdCheckmark />
                            </div>
                          )}
                        </div>
                      </td>
                    )} */}
                    {pathName === "/layouts" && (
                      <td className="action-col" style={{ width: 50 }}>
                        <AiOutlineBorderOuter
                          title="Show layout"
                          className={`table-action`}
                          onClick={async (e) => {
                            e.stopPropagation();
                            setShowLayout({ id: row.id, name: row.name });
                          }}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default DatasetTable;
